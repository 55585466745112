import { type SyntheticEvent, useState } from 'react';
import type { Question } from '../../../../../data/interface-question';

interface QuestionFilterProps {
	questions: Question[];
	resetQuestions: () => void;
	setFilteredQuestions: (questions: Question[]) => void;
	setShowNoResultsMSG: (show: boolean) => void;
	showFilter: boolean;
	setShowFilter: (show: boolean) => void;
}

const QuestionFilter = ({
	questions,
	resetQuestions,
	setFilteredQuestions,
	setShowNoResultsMSG,
	showFilter,
	setShowFilter
}: QuestionFilterProps) => {
	const [issuesFilter, setIssuesFilter] = useState(false);
	const [needsImage, setNeedsImage] = useState(false);
	const [needsAudio, setNeedsAudio] = useState(false);
	const [needsVideo, setNeedsVideo] = useState(false);

	const handleShowFilterClick = (e: SyntheticEvent) => {
		e.preventDefault();
		setShowFilter(false);
		// setFilteredQuestions([]);
		// setShowSubCat(false);
		setIssuesFilter(false);
		// setEnurmSelect('default');
		// setCategorySelect('default');
		// setSelectedCat('');
		// setSearchBy('question');
		resetQuestions();
	};

	const filterByIssues = (e: SyntheticEvent) => {
		// setCategorySelect('default');
		setIssuesFilter(!issuesFilter);

		if (!issuesFilter) {
			setFilteredQuestions(
				questions.filter((question) => {
					return (
						Object.prototype.hasOwnProperty.call(question, 'issues') ||
						!Object.prototype.hasOwnProperty.call(question, 'fuente') ||
						!Object.prototype.hasOwnProperty.call(question, 'question') ||
						!Object.prototype.hasOwnProperty.call(question, 'answer') ||
						!Object.prototype.hasOwnProperty.call(question, 'option1') ||
						!Object.prototype.hasOwnProperty.call(question, 'option2') ||
						!Object.prototype.hasOwnProperty.call(question, 'option3')
					);
				})
			);
		} else {
			// setFilteredQuestions([]);
			resetQuestions();
		}
	};

	const filterByNeedsImage = (e: SyntheticEvent) => {
		let result = [];
		setShowNoResultsMSG(false);

		setNeedsImage(!needsImage);
		setNeedsAudio(false);
		setNeedsVideo(false);
		setIssuesFilter(false);
		if (needsImage) {
			// setFilteredQuestions(questions);
			resetQuestions();
		} else {
			result = questions.filter((question) => {
				return Object.prototype.hasOwnProperty.call(question, 'needs_image');
			});
			setFilteredQuestions(result);
			if (result.length < 1) {
				setShowNoResultsMSG(true);
			}
		}
	};

	const filterByNeedsAudio = (e: SyntheticEvent) => {
		let result = [];
		setShowNoResultsMSG(false);

		setNeedsImage(false);
		setNeedsAudio(!needsAudio);
		setNeedsVideo(false);
		setIssuesFilter(false);
		if (needsAudio) {
			// setFilteredQuestions(questions);
			resetQuestions();
		} else {
			result = questions.filter((question) => {
				return !Object.prototype.hasOwnProperty.call(question, 'audioFileName');
			});
			setFilteredQuestions(result);
			if (result.length < 1) {
				setShowNoResultsMSG(true);
			}
		}
	};

	const filterByNeedsVideo = (e: SyntheticEvent) => {
		let result = [];
		setShowNoResultsMSG(false);

		setNeedsVideo(!needsVideo);
		setNeedsImage(false);
		setNeedsAudio(false);
		setIssuesFilter(false);
		if (needsVideo) {
			// setFilteredQuestions(questions);
			resetQuestions();
		} else {
			result = questions.filter((question) => {
				return Object.prototype.hasOwnProperty.call(question, 'needs_video');
			});
			setFilteredQuestions(result);
			if (result.length < 1) {
				setShowNoResultsMSG(true);
			}
		}
	};

	return (
		<div>
			<div className='flex justify-between items-center my-2'>
				<div className='mt-1 text-sm text-gray-600'>Filter By:</div>
				<button
					className='bg-gray-400 hover:bg-brand-400 text-xs py-1'
					onClick={handleShowFilterClick}>
					{showFilter ? 'Clear & Hide Filters' : 'Show Filter Options'}
				</button>
			</div>
			<fieldset className='text-sm'>
				{/* //~ BY ISSUES */}
				<div className='relative flex items-start'>
					<div className='flex items-center h-6'>
						<input
							// checked={issuesList?.includes('isMissingQuestion')}
							// onChange={(e) => handleChange(e.target.id, e.target.checked)}
							type='checkbox'
							className='h-4 w-4 rounded border-gray-400 text-brand-600 focus:ring-brand-600'
							checked={issuesFilter}
							onChange={filterByIssues}
							// onClick={filterByIssues}
							name='hasIssues'
							id='hasIssues'
						/>
						<label htmlFor='hasIssues' className='ml-2  text-sm text-gray-600'>
							Show Questions w/ Issues Only
						</label>
					</div>
				</div>

				{/* //~ NEEDS IMAGE */}
				<div className='relative flex items-start '>
					<div className='flex items-center h-6'>
						<input
							type='checkbox'
							className='h-4 w-4 rounded border-gray-400 text-brand-600 focus:ring-brand-600'
							checked={needsImage}
							onChange={filterByNeedsImage}
							onClick={filterByNeedsImage}
							name='needsImage'
							id='needsImage'
						/>
						<label htmlFor='needsImage' className='ml-2  text-sm text-gray-600'>
							Show Questions w/ &quot;Needs Image&quot;{' '}
						</label>
					</div>
				</div>

				{/* //~ NEEDS AUDIO */}
				<div className='relative flex items-start'>
					<div className='flex items-center h-6'>
						<input
							type='checkbox'
							className='h-4 w-4 rounded border-gray-400 text-brand-600 focus:ring-brand-600'
							checked={needsAudio}
							onChange={filterByNeedsAudio}
							// onClick={filterByNeedsAudio}
							name='needsAudio'
							id='needsAudio'
						/>
						<label htmlFor='needsAudio' className='ml-2  text-sm text-gray-600'>
							Show Questions w/ &quot;Needs Audio&quot; (explanation)
						</label>
					</div>
				</div>

				{/* //~ NEEDS VIDEO */}
				<div className='relative flex items-start'>
					<div className='flex items-center h-6'>
						<input
							type='checkbox'
							className='h-4 w-4 rounded border-gray-400 text-brand-600 focus:ring-brand-600'
							checked={needsVideo}
							onChange={filterByNeedsVideo}
							// onClick={filterByNeedsVideo}
							name='needsVideo'
							id='needsVideo'
						/>
						<label htmlFor='needsVideo' className='ml-2  text-sm text-gray-600'>
							Show Questions w/ &quot;Needs Video&quot;{' '}
						</label>
					</div>
				</div>
			</fieldset>
		</div>
	);
};
export default QuestionFilter;
