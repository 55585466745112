import { useEffect, useState } from 'react';
import type { Question } from '../../../../../data/interface-question';
import type { QuestionBankID } from '../../../../../data/interface-question-bank';
import type { OptionObjMustHaves } from '../../../../../data/interface-utils';
import { set } from 'lodash';

interface StatProps {
	questions: Question[] | undefined;
	examID: QuestionBankID;
}

interface Stat {
	name: string;
	value: string;
	unit?: string;
}

const MainStatsDisplay = ({ examID, questions }: StatProps) => {
	const [stats, setStats] = useState<Stat[]>([]);
	const background =
		examID === 'enurm'
			? 'bg-brand-700'
			: examID === 'maxilofacial'
			? 'bg-purple-700'
			: examID === 'dermatología'
			? 'bg-pink-700'
			: 'bg-red-700';

	// const stats = [
	// { name: 'Questions', value: '405' },
	// { name: 'With Image', value: '3.65', unit: 'mins' },
	// { name: 'With Issues', value: '3' },
	// { name: 'Ready', value: '98.5%' }
	// ];

	useEffect(() => {
		if (questions !== undefined && questions.length > 0) {
			const totalQuestions = questions.length;
			let questionsWithImages = 0;
			let questionsWithIssues = 0;
			let questionsReady = 0;

			questions.forEach((question) => {
				if (
					question.imageFileName !== '' &&
					question.imageFileName !== undefined
				) {
					questionsWithImages++;
				}

				if (question.issues !== undefined) {
					questionsWithIssues++;
				} else {
					questionsReady++;
				}
			});
			setStats([
				{ name: 'Questions', value: totalQuestions.toString() },
				{ name: 'With Image', value: questionsWithImages.toString() },
				{ name: 'With Issues', value: questionsWithIssues.toString() },
				{
					name: 'Issue Free',
					value: ((questionsReady / totalQuestions) * 100).toFixed(2),
					unit: '%'
				}
			]);
		}
	}, [questions]);

	return (
		<div className={`${background} rounded-2xl mb-4`}>
			<div className='mx-auto max-w-4xl'>
				<div className='grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-4'>
					{stats.map((stat) => (
						<div
							key={stat.name}
							className={`${background} px-4 py-6 sm:px-6 lg:px-8 rounded-2xl`}>
							<p className='text-sm/6 font-medium text-gray-300'>{stat.name}</p>
							<p className='mt-2 mb-0 flex items-baseline gap-x-2'>
								<span className='text-4xl font-semibold tracking-tight text-white'>
									{stat.value}
								</span>

								{stat.unit !== undefined ? (
									<span className='text-sm text-gray-300'>{stat.unit}</span>
								) : null}
							</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
export default MainStatsDisplay;
