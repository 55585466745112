import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import routes from '../routes';
import Dashboard from '../../pages/private/Dashboard';
import SideBarLayout, {
	type OtherLinkProps,
	type SideBarLinkProps
} from '../../layout/app/SideBarLayout';
import {
	AcademicCapIcon,
	CalendarIcon,
	ChartPieIcon,
	DocumentDuplicateIcon,
	FolderIcon,
	FolderPlusIcon,
	FolderArrowDownIcon,
	HomeIcon,
	RectangleStackIcon,
	UsersIcon
} from '@heroicons/react/24/outline';
import FlashcardsDashboard from '../../pages/private/flashcards/Dashboard';
import SelectTestQuestions from '../../pages/private/examenes/test-mode/SelectTestQuestions';
import StatsDashboard from '../../pages/private/stats/Dashboard';
import { useAuthContext } from '../../api/AuthContext';
import { useLoadingContext } from '../../context/LoadingContext';
import useUserContext from '../../api/UserContext';
import TestMode from '../../pages/private/examenes/test-mode/TestMode';
import Checkout from '../../pages/private/sales/Checkout';
import SelectAMembership from '../../pages/private/sales/SelectAMembership';
import TestReview from '../../pages/private/examenes/test-review/TestReview';
import CheckoutSuccess from '../../pages/private/sales/CheckoutSuccess';
import CheckoutFail from '../../pages/private/sales/CheckoutFail';
import CheckoutWithPayPal from '../../pages/private/sales/CheckoutWithPayPal';
import UserReport from '../../pages/admin/reports/UserReport';
import SelectFlashcards from '../../pages/private/flashcards/study-mode/SelectFlashcards';
import StudyMode from '../../pages/private/flashcards/study-mode/StudyMode';
import Manager from '../../pages/private/flashcards/manager/Manager';
import AddEditFlashcard from '../../pages/private/flashcards/manager/AddEditFlashcard';
import RecursosDashboard from '../../pages/private/recursos/Dashboard';
import Swal from 'sweetalert2';
import QuestionsManagerDashboard from '../../pages/admin/questions-manager/Dashboard';
import UserProfile from '../../pages/private/user-profile/UserProfile';
import EvaluacionesDiagnosticas from '../../pages/private/examenes/evaluaciones-diagnosticas/EvaluacionesDiagnosticas';
import { useBusinessContext } from '../../api/BusinessContext';
import PruebasDiagnosticasByUser from '../../pages/admin/reports/PruebasDiagnosticasByUser';
import type { User } from '../../data/interface-user';
import QuestionBuilder from '../../pages/admin/questions-manager/question-builder/QuestionBuilder';
import QuestionEditor from '../../pages/admin/questions-manager/question-editor/QuestionEditor';
import { checkPreviousMembership } from '../../utils/securities';

// How this works
// https://youtu.be/Ul3y1LXxzdU?t=1173
const PrivateRoutes = () => {
	const location = useLocation();
	const navigateTo = useNavigate();
	const { redirectToLoginIfUnauthorized, signOutCurrentUser } =
		useAuthContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { currentUser, product } = useUserContext();
	const { examTarget, getExam } = useBusinessContext();
	const [current, setCurrent] = useState<string>('Dashboard');
	useEffect(() => {
		runSecurityMeausures();

		if (currentUser !== null && examTarget === null) {
			getExam()
				.then(() => console.info('Exam loaded', examTarget))
				.catch((e) => console.error('Error loading exam', e));
		}
	}, []);

	useEffect(() => {
		checkUserAuthStatus();
		runSecurityMeausures();
	}, [location]);

	// const activeDays = currentUser?.signUpDate

	const activeTest = [
		'Examínate',
		'Pruebas Diagnósticas',
		'Flashcards',
		'Manejar Flashcards'
	];

	const [navigation, setNavigation] = useState<SideBarLinkProps[]>([
		{
			name: 'Dashboard',
			href: routes.APP.DASHBOARD,
			icon: HomeIcon,
			current: true
		},
		{
			name: 'Examínate',
			href: routes.APP.QUESTIONS.DASHBOARD,
			icon: AcademicCapIcon,
			current: false
		},
		// TODO: Aqui debe ir el buscador de preguntas previas
		// {
		// name: 'Examenes Previos',
		// href: routes.APP.QUESTIONS.TEST_PREV_RESULTS,
		// icon: FolderIcon,
		// current: false
		// },
		// {
		// name: 'Pruebas Diagnósticas',
		// href: routes.APP.ENURM.PRUEBAS_DIAGNOSTICAS,
		// icon: CalendarIcon,
		// current: false
		// },
		{
			name: 'Flashcards',
			href: routes.APP.FLASHCARDS.DASHBOARD,
			icon: RectangleStackIcon,
			current: false
		},
		{
			name: 'Manejar Flashcards',
			href: routes.APP.FLASHCARDS.MANAGER,
			icon: FolderPlusIcon,
			current: false
		},

		// { name: 'Edita', href: '#', icon: DocumentDuplicateIcon, current: false },
		// { name: 'Agrega', href: '#', icon: ChartPieIcon, current: false },
		{
			name: 'Estadísticas',
			href: routes.APP.STATS,
			icon: ChartPieIcon,
			current: false
		},
		{
			name: 'Recursos',
			href: routes.APP.PATH_TO.RECURSOS,
			icon: FolderArrowDownIcon,
			current: false
		}
	]);
	const [otherLinks, setOtherLinks] = useState<OtherLinkProps[]>([
		// { id: 1, name: 'Notificaciones', href: '#', initial: 'N', current: false },
		// { id: 2, name: 'Consulta', href: '#', initial: 'T', current: false },
		{
			id: 3,
			name: 'Contactanos',
			href: routes.PUBLIC.CONTACT_US,
			initial: 'C',
			current: false
		}
	]);

	const handleNavigation = (name: string) => {
		setCurrent(name);
		const newNavigation = navigation.map((item) => {
			if (item.name === name) {
				return { ...item, current: true };
			}
			return { ...item, current: false };
		});
		setNavigation(newNavigation);

		const newOtherLinks = otherLinks.map((item) => {
			if (item.name === name) {
				return { ...item, current: true };
			}
			return { ...item, current: false };
		});
		setOtherLinks(newOtherLinks);
	};

	const findLinkName = (path: string): string => {
		let name = '';
		const navLink = navigation.find((item) => item.href === path);
		if (navLink !== undefined) {
			name = navLink.name;
		}
		const otherLink = otherLinks.find((item) => item.href === path);
		if (otherLink !== undefined) {
			name = otherLink.name;
		}
		return name;
	};

	const checkUserAuthStatus = () => {
		setLoadingMessage('Verificando credenciales...');
		const path = location.pathname;
		if (path.includes('/checkout')) {
			setLoadingMessage('');
			setLoading(false);
		} else if (path.includes('/app')) {
			if (currentUser !== null) {
				setLoading(true);
				const allowAccess = isAccessAllowed(path);
				if (allowAccess) {
					const split = path.split('/').filter((s) => s !== '');
					const count = split.length;

					if (count === 1) {
						handleNavigation('Dashboard');
					} else if (count >= 2 && split[0] === 'app') {
						const nwPath = path.replace('/app/', '');
						const name = findLinkName(nwPath);
						handleNavigation(name);
					}
					setLoading(false);
				} else {
					navigateTo(routes.AUTHENTICATION.LOGIN);
				}
			} else {
				redirectToLoginIfUnauthorized(path);
			}
		} else {
			setLoading(false);
		}
	};

	const isAccessAllowed = (path: string): boolean => {
		if (currentUser === null) return false;
		const isAdmin = currentUser?.isAdmin ?? false;
		if (isAdmin) return true;

		handleLoginAccessCode(currentUser);
		const isActiveUser = checkIfUserIsActive(currentUser, path);
		return isActiveUser;
	};

	const checkIfUserIsActive = (user: User, path: string): boolean => {
		const currentTargetYear = product?.nextExamTargetYear ?? undefined;
		if (currentTargetYear === undefined) return false;

		const isFreeTrial: boolean = user?.freeTrial?.isActive ?? false;
		const isPaidUser: boolean =
			user[`membership_${currentTargetYear}`]?.isPaid ?? false;

		return isPaidUser || isFreeTrial || path.includes('checkout');
	};

	const handleLoginAccessCode = (user: User) => {
		const loginCode = localStorage.getItem('loginCode');
		if (loginCode !== null) {
			if (Number(loginCode) !== user.loginCode) {
				Swal.fire({
					title: 'Sesión cerrada!',
					html: 'Tu cuenta fue accesada desde otro dispositivo y <b>solo se permite un dispositivo a la vez</b>. Por favor, vuelve a iniciar sesión para continuar usando este dispositivo.',
					icon: 'warning',
					allowOutsideClick: false,
					allowEscapeKey: false,
					allowEnterKey: false,
					customClass: {
						confirmButton: 'bg-brand-500 hover:bg-brand-700'
					}
				})
					.then(async () => {
						await signOutCurrentUser(true);
					})
					.catch((error) => {
						console.error('Error signing out user: ', error);
					});
				return false;
			}
		}
	};

	const runSecurityMeausures = () => {
		// console.info('Running security measures.');
		if (currentUser === null) return;
		if (currentUser.isAdmin === true) return;

		// SECURITIES & PROTECTIONS
		//* PREVENT CONTEXT MENU FROM OPENING
		document.addEventListener(
			'contextmenu',
			function (evt: MouseEvent) {
				evt.preventDefault();
			},
			false
		);

		//* PREVENT CLIPBOARD COPYING
		document.addEventListener(
			'copy',
			function (evt: ClipboardEvent) {
				// Change the copied text if you want
				evt?.clipboardData?.setData(
					'text/plain',
					'Copiar nuestro contenido es una violación directa a los acuerdos de usuario de ©PrepMedRD y a nuestros derechos de propiedad intelectual. Esta es una acción que rastreamos y queda a nuestra decisión la severidad de penalidad contra esta falta.'
				);

				// Prevent the default copy action
				evt.preventDefault();
			},
			false
		);

		//* para mas opciones de bloqueo de copy/pase: https://code-boxx.com/disable-copy-text-javascript-css/
		//* BLOQUEO DE SCREENSHOTS
		// window.addEventListener('keyup', kPress, false);
		// function kPress(e) {
		// // if (window.location.host === 'localhost:3000') {
		// // console.log(e);
		// // }
		// var c = e.keyCode || e.charCode;
		// let key = e.key;
		// if (c === 44 || key === 'PrintScreen') {
		// e.preventDefault();
		// //need to give a warning message since nothing I can do to prevent this
		// }
		// }
		// return () => {

		// };
	};

	return (
		<Routes>
			<Route
				path={routes.APP.INDEX}
				element={
					<SideBarLayout
						currentPageName={current}
						handleNavigation={(s) => handleNavigation(s)}
						navigation={navigation.filter((nav) => {
							const examTargetYear = product?.nextExamTargetYear ?? undefined;
							const isExamResultsOut = examTarget?.testResultsAreOut ?? false;
							if (isExamResultsOut) {
								return !activeTest.includes(nav.name);
							}
							return true;
						})}
						// navigation={navigation}
						otherLinks={otherLinks}
					/>
				}>
				<Route index element={<Dashboard />} />
				<Route path={routes.APP.DASHBOARD} element={<Dashboard />} />

				{/* QUESTION ROUTES  */}
				<>
					<Route
						path={routes.APP.QUESTIONS.DASHBOARD}
						element={<SelectTestQuestions />}
					/>
					<Route
						path={routes.APP.QUESTIONS.TEST_REVIEW}
						element={<TestReview />}
					/>
					<Route
						path={routes.APP.ENURM.PRUEBAS_DIAGNOSTICAS}
						element={<EvaluacionesDiagnosticas />}
					/>
				</>

				{/* FLASHCARDS */}
				<>
					<Route
						path={routes.APP.FLASHCARDS.DASHBOARD}
						element={<SelectFlashcards />}
					/>
					<Route path={routes.APP.FLASHCARDS.MANAGER} element={<Manager />} />
					<Route
						path={routes.APP.FLASHCARDS.CREATE}
						element={<AddEditFlashcard />}
					/>
					<Route
						path={routes.APP.FLASHCARDS.FLASHCARD_DETAILS}
						element={<AddEditFlashcard />}
					/>
				</>

				{/* STATS */}
				<Route path={routes.APP.STATS} element={<StatsDashboard />} />

				<Route
					path={routes.APP.RECURSOS.DASHBOARD}
					element={<RecursosDashboard />}
				/>

				{/* ADMIN ROUTES */}
				<>
					<Route
						path={routes.ADMIN_LINKS.USER_REPORTS}
						element={<UserReport />}
					/>
					<Route
						path={routes.ADMIN_LINKS.QUESTIONS_MANAGER}
						element={<QuestionsManagerDashboard />}
					/>
					<Route
						path={routes.ADMIN_LINKS.QUESTIONS_CREATE}
						element={<QuestionBuilder />}
					/>
					<Route
						path={routes.ADMIN_LINKS.QUESTIONS_UPDATE}
						element={<QuestionEditor />}
					/>
					<Route
						path={routes.ADMIN_LINKS.REPORT_PRUEBAS_DIAGNOSTICAS}
						element={<PruebasDiagnosticasByUser />}
					/>
				</>

				<Route path={routes.APP.USER_PROFILE} element={<UserProfile />} />
			</Route>

			{/*  ↓↓ NO SIDEBAR ↓↓ */}
			<Route path={routes.APP.PATH_TO.TEST_MODE} element={<TestMode />} />
			<Route path={routes.APP.FLASHCARDS.STUDY} element={<StudyMode />} />
			{/* <Route path={routes.APP.PATH_TO.MEMBERSHIP.CHECKOUT} element={<Checkout />} /> */}
			<Route
				path={routes.APP.PATH_TO.MEMBERSHIP.CHECKOUT}
				element={<Checkout />}
			/>
			<Route
				path={routes.APP.PATH_TO.MEMBERSHIP.CHECKOUT_PAYPAL}
				element={<CheckoutWithPayPal />}
			/>
			<Route
				path={routes.CHECKOUT.CHECKOUT_SUCCESS_BASE_PATH}
				element={<CheckoutSuccess />}
			/>
			<Route path={routes.CHECKOUT.CHECKOUT_FAIL} element={<CheckoutFail />} />
			<Route
				path={routes.APP.PATH_TO.MEMBERSHIP.PLANS}
				element={<SelectAMembership />}
			/>
		</Routes>
	);
};
export default PrivateRoutes;
