import { type SyntheticEvent, useState } from 'react';
import Modal from '../../../../components/modals/Modal';
import type { Question as IQuestion } from '../../../../data/interface-question';
import EditCard from './EditCard';
import QuestionTags from '../questions-summary-list/question-item/QuestionTags';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import InputCheckbox from '../../../../components/forms/InputCheckbox';

interface QuestionProps {
	question: IQuestion;
	onUpdate: (
		questionID: string,
		key: string,
		newValue: any
	) => Promise<IQuestion | null>;
}

const ViewEditTags = ({ question, onUpdate }: QuestionProps) => {
	const { categories } = useQuestionBankContext();
	const [edit, setEdit] = useState(false);
	const [tags, setTags] = useState<string[]>([]);

	const handleUpdate = () => {
		onUpdate(question.id, 'tags', tags)
			.then(() => setEdit(false))
			.catch((error) => console.error('Error updating question:', error));
	};

	const handleTags = (e: SyntheticEvent) => {
		const target = e.target as HTMLInputElement;
		const id = target.id;
		const tagsArray: string[] = tags.length > 0 ? [...tags] : [];

		if (tags.includes(id)) {
			const index = tagsArray.indexOf(id);
			tagsArray.splice(index, 1);
			setTags([...tagsArray]);
		} else {
			tagsArray.push(id);
			setTags([...tagsArray]);
		}
	};

	return (
		<>
			<EditCard label='Image' onEdit={setEdit}>
				<QuestionTags tags={question.tags} />
			</EditCard>

			<Modal
				actionBtnText='Agregar'
				handleAction={handleUpdate}
				headline='Tags'
				isOpen={edit}
				onClose={() => setEdit(false)}
				showCloseBtn={true}>
				{/* CATEGORY SELECTIONS */}
				<div className='my-2'>
					<div className='grid grid-cols-4 gap-1'>
						{categories?.map((c) => {
							return (
								<InputCheckbox
									key={c.id}
									id={c.id}
									label={c.name}
									isChecked={tags.includes(c.id)}
									parentClass='relative inline-flex items-start my-1 2xl:w-full w-1/2'
									handleChange={handleTags}
								/>
							);
						})}
					</div>
				</div>
			</Modal>
		</>
	);
};
export default ViewEditTags;
