import { useEffect, type SyntheticEvent, useState } from 'react';
import useQuestionBankContext from '../../../api/QuestionBankContext';
import type {
	StorageFolder,
	StorageFileType
} from '../../../data/interface-utils';
import { set } from 'lodash';

interface UploadFileProps {
	onUpload: (imgPath: string) => void;
	storageFolder: StorageFolder;
	fileName: string | undefined;
	fileType: StorageFileType;
}

const Alert = () => {
	return (
		<div className='bg-green-300 shadow sm:rounded-lg mb-4'>
			<div className='px-4 py-2 text-sm'>Archivo guardado.</div>
		</div>
	);
};

const UploadQuestionImage = ({
	onUpload,
	storageFolder,
	fileName,
	fileType
}: UploadFileProps) => {
	const { uploadFile } = useQuestionBankContext();
	const [uploaderProgress, setUploaderProgress] = useState<number>(0);

	const handleUpload = (e: SyntheticEvent) => {
		const target = e.target as HTMLInputElement;
		if (target.files !== null) {
			const file: File = target.files[0];
			onUpload(file.name);
			uploadFile(file, file.name, storageFolder, setUploaderProgress)
				.then(() => {
					// TODO: File uploaded successfully
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	useEffect(() => {
		if (fileName === undefined) {
			setUploaderProgress(0);
		}

		return () => {
			setUploaderProgress(0);
		};
		// eslint-disable-next-line
	}, [fileName]);

	return (
		<>
			<div className='flex flex-col justify-center items-center mt-8'>
				<progress
					value={uploaderProgress}
					max='100'
					id='uploader'
					className='mb-4'>
					0%
				</progress>

				{uploaderProgress === 100 ? (
					<Alert />
				) : (
					<>
						<div className='flex justify-between items-center md:w-1/2 w-3/4'>
							<label
								className='font-medium text-gray-800 text-sm'
								htmlFor='fileButton'>
								{fileType === 'image' ? 'Sube una imagen ' : 'Sube un audio'}
							</label>
							<input
								id='fileButton'
								type='file'
								accept={
									fileType === 'image'
										? 'image/jpeg, image/png'
										: '.mp3,audio/*'
								}
								// className='mt-1 border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
								className='text-sm file:rounded file:mr-5 file:py-1 file:px-3 file:border-[1px] file:text-xs  hover:file:cursor-pointer hover:file:bg-brand-50 hover:file:text-brand-700'
								name='fileUpload'
								onChange={handleUpload}
							/>
						</div>
						<p className='text-sm text-orange-500 flex mt-2'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								className='h-5 mr-2'>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z'
								/>
							</svg>
							Mientras mas corto sea el nombre del archivo mejor.
						</p>
					</>
				)}
			</div>
			<hr className='w-3/4 mx-auto my-8' />
		</>
	);
};
export default UploadQuestionImage;
