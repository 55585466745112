import axios from 'axios';

export async function validateCedula(cedula: string): Promise<boolean> {
	try {
		const response = axios.get(
			`https://api.digital.gob.do/v3/cedulas/${cedula}/validate`
		);
		console.info('CEDULA', (await response).data);
		return true;
	} catch (error) {
		console.error('Su identificacion no es valida');
		return false;
	}
}
