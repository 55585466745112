import type { SyntheticEvent } from 'react';

interface SubmitChangesProps {
	cleanupState: () => void;
	disabled: boolean;
	handleSubmit?: (e: SyntheticEvent) => void;
	showButtons: boolean;
}
const SubmitChanges = ({
	cleanupState,
	disabled,
	handleSubmit,
	showButtons
}: SubmitChangesProps) => {
	if (!showButtons) {
		return (
			<div className='flex justify-end mb-5'>
				<button
					className='bg-red-500 hover:bg-red-600 rounded'
					type='reset'
					tabIndex={14}
					style={{ marginRight: '5px' }}
					onClick={cleanupState}>
					Vuelve a Empezar
				</button>
			</div>
		);
	}

	return (
		<div className='flex justify-end'>
			<button
				className='bg-gray-500 hover:bg-gray-600 rounded'
				type='reset'
				tabIndex={14}
				style={{ marginRight: '5px' }}
				onClick={cleanupState}>
				Clear
			</button>
			<button
				className='bg-brand-500 hover:bg-brand-600 rounded'
				type='submit'
				tabIndex={13}
				disabled={disabled}
				onClick={handleSubmit}>
				<i className='material-icons right'>send</i>Agregar Pregunta
			</button>
		</div>
	);
};
export default SubmitChanges;
