import useQuestionBankContext from '../../../../../api/QuestionBankContext';
import Badge from '../../../../../components/ui/Badge';

interface IQuestionTags {
	tags: string[];
}

const QuestionTags = ({ tags }: IQuestionTags) => {
	const { categories } = useQuestionBankContext();
	const getCategoryName = (id: string) => {
		const category = categories?.find((c) => c.id === id);
		if (category !== undefined) {
			return category.name;
		} else {
			return id;
		}
	};

	if (tags?.length === 0) return null;
	return (
		<ul className='flex w-full'>
			{tags?.map((tag: string) => {
				return (
					<li className='mr-2 text-xs' key={tag} id='tags'>
						<Badge
							title={getCategoryName(tag)}
							backgroundColor='bg-brand-100'
							ringColor='ring-brand-500/10'
						/>
					</li>
				);
			})}
		</ul>
	);
};
export default QuestionTags;
