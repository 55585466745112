import { useState, useRef, useEffect } from 'react';

// QUILL
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

interface DescTypes {
	label: string;
	id: string;
	infoMessage?: string;
	isFormSubmitted: boolean;
	parentClass?: string;
	placeholder?: string;
	required?: boolean;
	value?: string;
	heightInPx?: string;
	handleChange: (value: string) => void;
}

const DescriptionTextArea = ({
	label,
	id,
	infoMessage,
	isFormSubmitted,
	parentClass = 'flex justify-between items-center w-full mb-2',
	placeholder,
	required = false,
	handleChange,
	heightInPx = '125',
	value
}: DescTypes) => {
	const [hideInfoMessage, setHideInfoMessage] = useState<boolean>(true);
	const { quill, quillRef } = useQuill({
		modules: {
			toolbar: [
				['bold', 'italic', 'underline', 'strike'],
				[{ align: [] }],
				[{ list: 'ordered' }, { list: 'bullet' }],
				// [{ size: ['small', false, 'large', 'huge'] }],
				[{ color: [] }, { background: [] }],

				['clean']
			]
		}
	});

	useEffect(() => {
		if (quill !== undefined && quill !== null) {
			if (
				value !== undefined &&
				value !== null &&
				value !== '' &&
				value !== quill.root.innerHTML
			) {
				quill.clipboard.dangerouslyPasteHTML(value);
			}

			quill.on('text-change', (delta: any, oldDelta: any, source: any) => {
				// console.clear();
				// console.info(quill.getText()); // Get text only
				// console.info(quill.getContents()); // Get delta contents
				// console.info(quill.root.innerHTML); // Get innerHTML using quill
				// console.info(quillRef); // Get quillRef
				// console.info(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef

				// eslint-disable-next-line
				const text = `${quill?.root.innerHTML}`;
				handleChange(text);
				if (text === '<p><br></p>' || text === '') {
					setHideInfoMessage(false);
				} else {
					setHideInfoMessage(true);
				}
			});
			if (isFormSubmitted) {
				quill.root.innerHTML = '<p><br></p>';
			}
		}

		// return () => {
		// quill.setText('');
		// };
	}, [quill, quillRef, isFormSubmitted, value]);

	return (
		<div className={parentClass}>
			<label htmlFor={id} className='block w-full'>
				<span className='text-sm text-gray-600 font-light'>
					<div className='flex justify-between items-center'>
						{label}
						{required && (
							<sub className='text-gray-400' hidden={hideInfoMessage}>
								(requerido)
							</sub>
						)}
					</div>
				</span>
				<div
					ref={quillRef}
					style={{ height: heightInPx + 'px' }}
					// onKeyUp={() => {
					// // eslint-disable-next-line
					// const desc = `${quill?.root.innerHTML}`;
					// handleChange(desc);
					// if (desc === '<p><br></p>') {
					// setHideInfoMessage(false);
					// }
					// }}

					id='description'
				/>
				{infoMessage !== undefined && (
					<small className='text-red-400' hidden={hideInfoMessage}>
						{infoMessage}
					</small>
				)}
			</label>
		</div>
	);
};

export default DescriptionTextArea;
