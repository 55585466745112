import Swal from 'sweetalert2';
import { type QuestionBankID } from '../../../data/interface-question-bank';
import Modal from '../../../components/modals/Modal';
import { useState } from 'react';
import SelectElement from '../../../components/forms/SelectElement';
import { questionBankOptions } from '../../../data/option-objects/examOptions';
import { useNavigate } from 'react-router-dom';
import routes from '../../../config/routes';

interface CurrentBankNoticeProps {
	examID: QuestionBankID;
	handleQBankChange: (v: QuestionBankID) => void;
	showAddQuestionBtn: boolean;
}

const CurrentBankNotice = ({
	examID,
	handleQBankChange,
	showAddQuestionBtn
}: CurrentBankNoticeProps) => {
	const navigateTo = useNavigate();
	const [openModal, setOpenModal] = useState(false);
	const [questionBank, setQuestionBank] = useState<QuestionBankID>(examID);

	const background =
		examID === 'enurm'
			? 'bg-brand-200'
			: examID === 'maxilofacial'
			? 'bg-purple-200'
			: examID === 'dermatología'
			? 'bg-pink-200'
			: 'bg-red-200';

	const btnBg =
		examID === 'enurm'
			? 'brand'
			: examID === 'maxilofacial'
			? 'purple'
			: examID === 'dermatología'
			? 'pink'
			: 'red';

	const handleBankChange = (v: string) => {
		setQuestionBank(v as QuestionBankID);
	};

	const handleSubmit = () => {
		localStorage.setItem('questionBankID', questionBank);
		handleQBankChange(questionBank);
		setOpenModal(false);
	};

	return (
		<>
			<div
				className={`${background} shadow sm:rounded-lg mb-4 z-10`}
				style={{ position: 'sticky', top: '0' }}>
				{/* <div className='bg-white shadow sm:rounded-lg mb-4'> */}
				<div className='px-4 py-2 sm:p-4'>
					{/* <h3 className='text-base font-semibold text-gray-900'>
					Estas editando el banco de preguntas: <b>{examID.toUpperCase()}</b>
				</h3> */}
					<div className='sm:flex sm:items-start sm:justify-between lg:items-center'>
						<div className='max-w-xl text-sm text-gray-500'>
							<p className='mb-0'>
								Estas editando el banco de preguntas:{' '}
								<b>{examID.toUpperCase()}</b>
							</p>
						</div>
						<div className='mt-5 sm:ml-6 sm:mt-0 sm:flex sm:shrink-0 sm:items-center'>
							<button
								type='button'
								className='inline-flex items-center rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 mr-2'
								onClick={() => setOpenModal(true)}>
								Cambiar Banco
							</button>
							{showAddQuestionBtn && (
								<button
									type='button'
									className={`inline-flex items-center rounded-md bg-${btnBg}-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${btnBg}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${btnBg}-500`}
									onClick={() =>
										navigateTo(routes.ADMIN_LINKS.QUESTIONS_CREATE)
									}>
									Agregar Pregunta
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
			<Modal
				actionBtnText='Cambiar Banco'
				handleAction={handleSubmit}
				headline='Cambia el banco de preguntas'
				isOpen={openModal}
				onClose={() => setOpenModal(false)}
				showCloseBtn={true}>
				<p>Elige el banco de preguntas al que deseas cambiar:</p>

				<SelectElement
					defaultOption='- Elige un banco -'
					id='test-type'
					icon='ballot'
					iconFam='material-icons'
					label='Elige un banco'
					options={questionBankOptions}
					value={questionBank}
					handleChange={(e) => {
						handleBankChange(e);
					}}
				/>
			</Modal>
		</>
	);
};
export default CurrentBankNotice;
