import { type SyntheticEvent, useState, useEffect } from 'react';
import type { QuestionBankID } from '../../../../data/interface-question-bank';
import type {
	Answer,
	CreateQuestionData,
	ExamLabel
} from '../../../../data/interface-question';
import QuestionsManagerContainer from '../QuestionsManagerContainer';

import Warnings from './Warnings';
import CurrentBankNotice from '../CurrentBankNotice';

import { useLoadingContext } from '../../../../context/LoadingContext';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import QuestionBuilderForm from './QuestionBuilderForm';
import { useAdminContext } from '../../../../api/AdminContext';
import { scrollToTop } from '../../../../utils/helpers';

const QuestionBuilder = () => {
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { categories, getCategories } = useQuestionBankContext();
	const { createQuestion, setAdminQuestions } = useAdminContext();
	const [isFormValid, setIsFormValid] = useState(false);
	// #region QUESTION BANK ID
	const lsBankID = localStorage.getItem('questionBankID') as QuestionBankID;
	const [questionBankID, setQuestionBankID] = useState<QuestionBankID>(
		lsBankID !== null ? lsBankID : 'enurm'
	);
	const handleQBankChange = (v: QuestionBankID) => {
		setQuestionBankID(v);
		setAdminQuestions(undefined);
		// resetForm();
	};
	// #endregion

	// #region FORM STATE
	const [showWarnings, setShowWarnings] = useState(false);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [source, setSource] = useState<ExamLabel>();
	const [categoryID, setCategoryID] = useState<string>();
	const [showSubCategory, setShowSubCategory] = useState<boolean>(false);
	const [subCategory, setSubCategory] = useState<string>();
	const [question, setQuestion] = useState<string>();
	const [answer, setAnswer] = useState<string>();
	const [option1, setOption1] = useState<string>();
	const [option2, setOption2] = useState<string>();
	const [option3, setOption3] = useState<string>();
	const [explanation, setExplanation] = useState<string>();
	const [fuentes, setFuentes] = useState<string>();
	const [showImageInQuestion, setShowImageInQuestion] =
		useState<boolean>(false);
	const [tags, setTags] = useState<string[]>([]);
	const [audioPath, setAudioPath] = useState<string>();
	const [imagePath, setImagePath] = useState<string>();

	// #endregion

	const checkChanges = () => {
		// console.info('WARNINGS', {
		// question,
		// source,
		// categoryID,
		// answer,
		// option1,
		// option2,
		// option3,
		// explanation,
		// fuentes
		// });
		if (
			question !== undefined &&
			source !== undefined &&
			categoryID !== undefined &&
			answer !== undefined &&
			option1 !== undefined &&
			option2 !== undefined &&
			option3 !== undefined &&
			explanation !== undefined &&
			fuentes !== undefined
		) {
			setIsFormValid(true);
			setShowWarnings(false);
		} else {
			setIsFormValid(false);
			setShowWarnings(true);
		}
	};

	const handleCategoryChange = (v: string) => {
		setCategoryID(v);
		const subCategories = categories?.find((c) => c.id === v)?.subCategoryList;
		if (subCategories !== undefined) {
			setShowSubCategory(true);
		}
	};

	const handleTags = (e: SyntheticEvent) => {
		const target = e.target as HTMLInputElement;
		const id = target.id;
		const tagsArray: string[] = tags.length > 0 ? [...tags] : [];

		if (tags.includes(id)) {
			const index = tagsArray.indexOf(id);
			tagsArray.splice(index, 1);
			setTags([...tagsArray]);
		} else {
			tagsArray.push(id);
			setTags([...tagsArray]);
		}
	};

	const clearForm = () => {
		setShowWarnings(true);
		setIsFormValid(false);
		setIsFormSubmitted(false);
		setSource(undefined);
		setCategoryID(undefined);
		setShowSubCategory(false);
		setQuestion(undefined);
		setAnswer(undefined);
		setOption1(undefined);
		setOption2(undefined);
		setOption3(undefined);
		setExplanation(undefined);
		setFuentes(undefined);
		setShowImageInQuestion(false);
		setImagePath(undefined);
		setAudioPath(undefined);
		setTags([]);
		scrollToTop();
	};

	const handleSubmit = (e: SyntheticEvent) => {
		e.preventDefault();
		if (
			categoryID !== undefined &&
			source !== undefined &&
			question !== undefined &&
			answer !== undefined &&
			option1 !== undefined &&
			option2 !== undefined &&
			option3 !== undefined &&
			explanation !== undefined &&
			fuentes !== undefined
		) {
			setLoadingMessage('Submitting question...');
			setLoading(true);
			const q: CreateQuestionData = {
				bank: questionBankID,
				categoryID: categoryID,
				question: question,
				source: source,
				option1: option1,
				option2: option2,
				option3: option3,
				tags: [],
				subCategoryList: '',
				isPruebaDiagnostica:
					source === 'evaluacion-1' || source === 'evaluacion-2',
				answer: answer,
				explanation: explanation,
				fuente: fuentes
			};

			if (subCategory !== undefined && subCategory !== '') {
				q.subCategoryList = subCategory;
			}

			if (tags.length > 0) {
				q.tags = tags;
			}

			if (imagePath !== undefined && imagePath !== '') {
				q.imageFileName = imagePath;
				q.showImageInQuestion = showImageInQuestion;
			}

			if (audioPath !== undefined && audioPath !== '') {
				q.audioFileName = audioPath;
			}
			// console.info('Submitting question:', q);
			createQuestion(q)
				.then(() => {
					console.info('Question created');
					setLoading(false);
					clearForm();
				})
				.catch((error: any) => {
					console.error('Error creating question:', error);
					setLoading(false);
				});
		}
	};

	useEffect(() => {
		if (categories === undefined || categories.length === 0) {
			getCategories()
				.then(() => {
					console.info('Categories loaded');
				})
				.catch((error) => {
					console.error('Error loading categories:', error);
				});
		}
		scrollToTop();
	}, []);

	useEffect(() => {
		checkChanges();
	}, [
		question,
		source,
		categoryID,
		answer,
		option1,
		option2,
		option3,
		explanation,
		fuentes
	]);

	return (
		<QuestionsManagerContainer title='Add Questions'>
			<CurrentBankNotice
				examID={questionBankID}
				handleQBankChange={(v: QuestionBankID) => handleQBankChange(v)}
				showAddQuestionBtn={false}
			/>

			<QuestionBuilderForm
				isFormValid={isFormValid}
				isFormSubmitted={isFormSubmitted}
				handleSubmit={handleSubmit}
				clearForm={clearForm}
				handleCategoryChange={handleCategoryChange}
				handleTags={handleTags}
				questionBankID={questionBankID}
				question={question}
				setQuestion={setQuestion}
				source={source}
				setSource={setSource}
				categoryID={categoryID}
				showSubCategory={showSubCategory}
				setSubCategory={setSubCategory}
				setAnswer={setAnswer}
				setOption1={setOption1}
				setOption2={setOption2}
				setOption3={setOption3}
				explanation={explanation}
				setExplanation={setExplanation}
				fuentes={fuentes}
				setFuentes={setFuentes}
				showImageInQuestion={showImageInQuestion}
				setShowImageInQuestion={setShowImageInQuestion}
				imagePath={imagePath}
				setImagePath={setImagePath}
				audioPath={audioPath}
				setAudioPath={setAudioPath}
				tags={tags}
			/>

			<Warnings
				showWarning={showWarnings}
				question={question}
				source={source}
				categoryID={categoryID}
				answer={answer}
				option1={option1}
				option2={option2}
				option3={option3}
				explanation={explanation}
				fuentes={fuentes}
			/>
		</QuestionsManagerContainer>
	);
};
export default QuestionBuilder;
