import { useState, type SyntheticEvent } from 'react';
import SelectElement from '../../../components/forms/SelectElement';
import { especialidadesOptions } from '../../../data/option-objects/especialidades';
import NumberInput from '../../../components/forms/NumberInput';
import { useLoadingContext } from '../../../context/LoadingContext';
import { type PostTestConsultationRequest } from '../../../models/interfaces/especialidad.interfaces';
import { useBusinessContext } from '../../../api/BusinessContext';

interface PostTestResultConsultationProps {
	year: number;
}
const PostTestResultConsultation = ({
	year
}: PostTestResultConsultationProps) => {
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { setPostTestConsultation } = useBusinessContext();
	const [grade, setGrade] = useState<number>(0);
	const [especialidad1, setEspecialidad1] = useState<string>();
	const [especialidad2, setEspecialidad2] = useState<string>();

	const handleSubmit = (e: SyntheticEvent) => {
		e.preventDefault();
		setLoadingMessage('Guardando Datos');
		setLoading(true);

		if (
			grade !== 0 &&
			especialidad1 !== undefined &&
			especialidad2 !== undefined
		) {
			const data: PostTestConsultationRequest = {
				grade,
				date: new Date(),
				especialidad_target_1: especialidad1,
				especialidad_target_2: especialidad2
			};
			setPostTestConsultation(data)
				.then(() => {
					setLoading(false);
					setLoadingMessage('');
					// TODO: Handle update of consultation data
				})
				.catch((error) => {
					setLoading(false);
					setLoadingMessage('');
					console.error('Error saving post test consultation', error);
					// TODO: Add error message
				});
		} else {
			setLoading(false);
			// TODO: Add error message
		}
	};

	return (
		<div>
			{/* <div className='card-panel bg-alternate1-white py-1'>
				<h5 className='txt-alternate1'>
					<i className='material-icons fsize-larger mr-2'>announcement</i>
					¿Quieres saber con qué nota entraron en años anteriores a tu
					especialidad de preferencia?
				</h5>

				<p>
					Consulta nuestra base de datos para visualizar la puntuación de los
					ganadores de los últimos 5 años en 2 de tus especialidades de
					preferencia.{' '}
				</p>
				<p>
					Puedes utilizar esta información para guiar tu preparación antes del
					examen y planificar un concurso de plaza exitoso.
				</p>
			</div> */}
			<div className='mt-4'>
				<h3 className='mb-4'>
					Consulta las Estadísticas de tus Plazas Deseadas
				</h3>
				<p>
					Los resultados del ENURM {year} ya salieron, pero sabemos que tu
					proceso aún no termina y queremos acompañarte en los pasos que siguen.
				</p>
				<p>
					Por eso estamos ofreciéndote una consulta estadística gratis. Al
					consultar tendrás acceso a la nota promedio, mínima y máxima de dos de
					tus especialidades de interés.
				</p>

				{/* <p>
					¿Por que necesitamos tu información? Utilizaremos esta información
					para calcular la nota promedio de los usuarios y continuar mejorando
					la plataforma.
				</p>

				<p>
					Utilizamos la información demográfica para mejorar la plataforma en
					base a las necesidades especificas de diferentes grupos demográficos
					(Universidad, Años post graduación, etc). Utilizaremos esta
					información para crear nuestra lista de ganadores ENURM 2023
				</p> */}
				<p>Para consultar, completa los siguientes datos:</p>
			</div>
			<form onSubmit={handleSubmit}>
				<h5 className='mt-2 center txt-brand2'>
					¿Cuáles son tus 2 especialidades de interés?
				</h5>
				<SelectElement
					defaultOption='- Elige un especialidad -'
					id='especialidad1'
					icon='ballot'
					iconFam='material-icons'
					label='Especialidad de Interés 1'
					options={especialidadesOptions}
					value={especialidad1}
					handleChange={(e) => {
						setEspecialidad1(e);
					}}
				/>
				<SelectElement
					defaultOption='- Elige un especialidad -'
					id='especialidad1'
					icon='ballot'
					iconFam='material-icons'
					label='Especialidad de Interés2'
					options={especialidadesOptions}
					value={especialidad2}
					handleChange={(e) => {
						setEspecialidad2(e);
					}}
				/>
				<NumberInput
					guideMessage='100 es el máximo que puedes ingresar'
					id='testLengthInput'
					icon='dialpad'
					iconFam='material-icons'
					min={1}
					max={100}
					label='¿Cual fue tu nota en el ENURM?'
					placeholder='Ingresa un valor del 1 a 100'
					handleChange={(e) => setGrade(Number(e))}
				/>

				<button
					type='button'
					disabled={
						grade === 0 ||
						especialidad1 === undefined ||
						especialidad2 === undefined
					}
					className='rounded-md px-2 py-1.5 text-sm font-medium bg-brand-400 hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 focus:ring-offset-brand-50'
					onClick={(e) => handleSubmit(e)}>
					Guardar Datos
				</button>
			</form>
		</div>
	);
};
export default PostTestResultConsultation;
