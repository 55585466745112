import { SyntheticEvent, useState } from 'react';
import FilterByCategory from './FilterByCategory';
import QuestionFilter from './QuestionFilter';
import { type Question } from '../../../../../data/interface-question';
import QuestionSearch from './QuestionSearch';
import Button from '../../../../../components/ui/Button';

interface SearchAndFilterProps {
	questions: Question[];
	resetQuestions: () => void;
	setFilteredQuestions: (questions: Question[]) => void;
	setShowNoResultsMSG: (value: boolean) => void;
}

const SearchAndFilter = ({
	questions,
	resetQuestions,
	setFilteredQuestions,
	setShowNoResultsMSG
}: SearchAndFilterProps) => {
	const [searchBy, setSearchBy] = useState<'question' | 'explanation'>(
		'question'
	);
	const [issuesFilter, setIssuesFilter] = useState(false);
	const [showFilter, setShowFilter] = useState(false);

	const handleDirectPull = () => {
		if (window.confirm('Are you sure you want to make a fresh pull?')) {
			// questionsFromDB();
		} else {
			alert('No request was made to Firestore DB');
		}
	};

	// #region SEARCH
	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setShowNoResultsMSG(false);
		setShowFilter(false);
		setIssuesFilter(false);

		const result = questions.filter((question) => {
			const inQuestionFilter = question[searchBy]
				.toLowerCase()
				.includes(e.target.value.toLowerCase());
			const sameCatID = question.categoryID
				.toLowerCase()
				.includes(e.target.value.toLowerCase());
			const inQuestionID = question.id
				.toLowerCase()
				.includes(e.target.value.toLowerCase());
			return (
				sameCatID ||
				inQuestionFilter ||
				inQuestionID ||
				question.id.includes(e.target.value)
			);
		});

		setFilteredQuestions(result);
		if (result.length < 1) {
			setShowNoResultsMSG(true);
		}

		if (e.target.value === '') {
			resetQuestions();
			setShowFilter(false);
		}
	};
	// #endregion

	return (
		<>
			<QuestionSearch
				handleSearch={handleSearch}
				resetQuestions={resetQuestions}
				searchBy={searchBy}
				setSearchBy={setSearchBy}
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				setShowNoResultsMSG={setShowNoResultsMSG}
			/>

			{showFilter && (
				<>
					<hr className='w-3/4 mx-auto my-8' />
					{showFilter && (
						<QuestionFilter
							questions={questions}
							setFilteredQuestions={setFilteredQuestions}
							setShowNoResultsMSG={setShowNoResultsMSG}
							showFilter={showFilter}
							setShowFilter={setShowFilter}
							resetQuestions={resetQuestions}
						/>
					)}

					{/* Get Questions Directly from DB */}
					{/* {!issuesFilter && (
						<div className='row hide-on-med-and-down'>
							<div className='col s12'>
								<button className='btn bg-secondary' onClick={handleDirectPull}>
									Get Questions Directly from DB
								</button>
							</div>
						</div>
					)} */}
				</>
			)}

			<hr className='w-3/4 mx-auto my-8' />
		</>
	);
};
export default SearchAndFilter;
