import { useRef, useState, useEffect } from 'react';
import FormLabel from './FormLabel';
import type { OptionObjMustHaves } from '../../data/interface-utils';
import GuideMessage from './GuideMessage';

interface OptionsArray extends Array<OptionObjMustHaves> {}

interface SelectElementTypes {
	defaultOption?: string;
	isDefaultOptionDisabled?: boolean;
	defaultValue?: string;
	guideMessage?: string;
	icon?: string | null;
	iconFam?: string;
	iconPosition?: 'left' | 'right';
	id: string;
	infoMessage?: string;
	isInlineElem?: boolean;
	label: string;
	options: OptionsArray;
	parentClass?: string;
	isRequired?: boolean;
	selectClasses?: string;
	value?: string;
	handleChange: (value: string) => void;
}

/**
 * Required props: id, label,
	options | Optional props: defaultOption,
	defaultValue,
	guideMessage,
	icon,
	iconFam,
	iconPosition,
	infoMessage,
	isInlineElem,
	parentClass,
	isRequired,
	selectClasses,
	value,
	handleChange,
 */
const SelectElement = ({
	isDefaultOptionDisabled = true,
	defaultOption = '- Elije uno -',
	defaultValue,
	guideMessage = '',
	icon = null,
	iconPosition = 'left',
	iconFam = 'material-icons',
	id,
	infoMessage,
	isInlineElem = false,
	label,
	options,
	parentClass = 'w-full my-5',
	isRequired = false,
	selectClasses = '',
	value = 'default',
	handleChange
}: SelectElementTypes) => {
	const selectRef = useRef<HTMLSelectElement>(null);
	const [selection, setSelection] = useState<string>(value);
	const [isOnFocus, setIsOnFocus] = useState<boolean>(false);

	// useEffect(() => {
	// setSelection(value);
	// // console.info(selectRef.current && selectRef.current.value);
	// // return () => {
	// // cleanup
	// // }
	// }, [value]);

	return (
		<div className={parentClass}>
			{/* <label htmlFor={id} className='block w-full'>
				<span
					className={`text-sm text-gray-600 font-light ${
						isInlineElem ? 'hidden' : ''
					}`}>
					<div className='flex justify-between items-center'>
						{label}
						<sub className='text-gray-400' hidden={isRequired}>
							(requerido)
						</sub>
					</div>
				</span>
			</label> */}

			{!isInlineElem && icon !== null && iconFam === 'material-icons' ? (
				<FormLabel
					label={label}
					isRequired={isRequired}
					icon={icon}
					iconFam='material-icons'
					iconPosition={iconPosition}
				/>
			) : (
				!isInlineElem && <FormLabel label={label} isRequired={isRequired} />
			)}

			<select
				name={id}
				id={id}
				className={`form-select form-select-lg appearance-none block w-full mt-1 rounded-md text-gray-600 invalid:text-red-500 border-gray-300 shadow-sm
				focus:border-brand-200 focus:ring focus:ring-brand-200 focus:ring-opacity-50 placeholder:text-gray-400 placeholder:font-light placeholder:text-sm ${selectClasses}`}
				value={selection}
				ref={selectRef}
				onBlur={() => setIsOnFocus(true)}
				onChange={(e) => {
					e.preventDefault();
					setSelection(e.target.value);
					handleChange(e.target.value);
				}}
				onFocus={() => setIsOnFocus(false)}>
				<option value='default' disabled={isDefaultOptionDisabled}>
					{defaultOption}
				</option>
				{options.map((o) => {
					return (
						<option key={o.id} value={o.id} disabled={o.isDisabled}>
							{o.value}
						</option>
					);
				})}
			</select>

			{isOnFocus && (
				<>
					{/* {showInfoMessage &&
					selectRef?.current &&
					selectRef.current.value === 'default' && (
						<small className='text-red-400'>{infoMessage}</small>
					)} */}
					{guideMessage !== '' && <GuideMessage message={guideMessage} />}
				</>
			)}
		</div>
	);
};

export default SelectElement;
