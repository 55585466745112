import { lazy, Suspense, useEffect } from 'react';
import { useLoadingContext } from '../../context/LoadingContext';
import useUserContext from '../../api/UserContext';
import MainSection from '../../layout/app/dashboard/MainSection';
import { Link } from 'react-router-dom';
import routes from '../../config/routes';
import Historial from './Historial';
import PreguntasRealizadas from './PreguntasRealizadas';
import Alert from '../../components/ui/Alert';
import useQuestionBankContext from '../../api/QuestionBankContext';
import Button from '../../components/ui/Button';
import { deleteAllCookies } from '../../utils/helpers';
import UnFinishedTest from './examenes/unfinished-test/UnFinishedTest';
const TestRecords = lazy(
	async () => await import('./examenes/historial/TestRecords')
);

const BeforeTest = () => {
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { loadRequiredData } = useQuestionBankContext();
	const { announcement, getMarketing, currentUser } = useUserContext();
	const firstName: string = currentUser?.person?.firstName ?? '';
	const welcomeMessage = `¡Hola ${firstName}!`;

	useEffect(() => {
		setLoading(true);
		loadRequiredData()
			.then(() => {
				getMarketing()
					.then(() => setLoading(false))
					.catch(() => setLoading(false));
			})
			.catch(() => console.log(false));

		// scroll to top smoothly
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	const handleReloadRequired = () => {
		setLoadingMessage(
			'Un momento por favor en lo que recargamos la aplicación.'
		);
		setLoading(true);
		deleteAllCookies(false);
		setTimeout(() => {
			setLoading(false);
			window.location.reload();
		}, 3000);
	};

	if (currentUser === null) {
		return (
			<div>
				No hemos podido encontrar un usuario... Por favor recargue la página
				para continuar.
			</div>
		);
	}
	return (
		<>
			<section className='mt-2 lg:mt-8'>
				{announcement !== undefined &&
					(announcement?.showAnnouncement ?? false) && (
						<Alert
							title={announcement.title}
							type={announcement.type}
							classes='mb-8'
							// classes='md:w-2/4 mb-8 md:px-12'
						>
							{announcement.message}
							{announcement.isReloadRequired && (
								<>
									<p>Para este cambio te recomendamos recargar el app.</p>
									<div className='flex justify-end'>
										<Button
											classes='mt-4'
											bgColor='bg-alt2-500 flex items-center justify-center'
											hoverBgColor='hover:bg-alt2-700'
											icon='refresh'
											iconPosition='left'
											title='Recargar App'
											handleClick={handleReloadRequired}
										/>
									</div>
								</>
							)}
						</Alert>
					)}
				{(currentUser?.freeTrial?.isActive ?? false) && (
					<Alert
						title='Tu cuenta está en período de prueba...'
						type='info'
						classes='mb-8'>
						<p>
							<strong>Nota:</strong> La modalidad de tu cuenta es de prueba y
							está limitada a hacer simulaciones de un solo ENURM (2020). Debes
							completar tu pago para usar tener acceso al contenido premium y
							continuar usando nuestra plataforma sin interrumpciones.
						</p>
						<Link to={routes.CHECKOUT.CHECKOUT} className='btn bg-alt2-500'>
							Completar Pago
						</Link>
					</Alert>
				)}
				<UnFinishedTest />

				<div className='flex flex-col justify-between lg:flex-row '>
					<div className='lg:w-3/5 lg:pr-12'>
						{/* <Headline title='¡Empieza aquí!' type='h6' /> */}
						{/* <Headline title='¿Estas list@ para empezar?' type='h6' /> */}
						<p>
							Si es tu primera vez usando nuestra plataforma, aquí explicamos
							brevemente cuales son tus opciones.
						</p>
						<p>
							Nuestro sistema te permite hacer flashcards y tomar examenes en
							diferentes modalidades:
						</p>
						<ol className='normal-bullets'>
							<li>Pruebines de 50 preguntas</li>
							<li>Simulaciones de 100 preguntas</li>
							<li>Otra cantidad de preguntas</li>
						</ol>
						<p>
							Tienes la opción adicional de elegir límite de tiempo o &quot;modo
							tutor&quot;. El modo tutor te explica la respuesta correcta al
							momento de la selección. De no utilizarlo, podrás ver la respuesta
							correcta al terminar tu evaluación.
						</p>
					</div>
					<div className='flex flex-col justify-around lg:items-end lg:w-2/5'>
						<Link
							to={routes.APP.PATH_TO.QUESTION_SELECTIONS}
							className='btn bg-brand-500 dashboard-link w-100 lg:w-3/4 mb-2'>
							Examenes
						</Link>
						<Link
							to={routes.APP.PATH_TO.FLASHCARDS}
							className='btn bg-alt1-450 dashboard-link w-100 lg:w-3/4 mb-2'>
							Flashcards
						</Link>
						<Link
							to={routes.APP.PATH_TO.STATS}
							className='btn bg-purple-400 dashboard-link w-100 lg:w-3/4 mb-2'>
							Estadísticas
						</Link>
					</div>
				</div>
			</section>

			<hr className='w-3/4 mx-auto' />
			<Historial />
			<hr className='w-3/4 mx-auto' />
			<PreguntasRealizadas />
			<hr className='w-3/4 mx-auto' />
			<Suspense fallback={<>Cargando historial...</>}>
				<TestRecords />
			</Suspense>
		</>
	);
};
export default BeforeTest;
