import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Pagination from '../../../../components/ui/Pagination';
import type { Question } from '../../../../data/interface-question';
import QuestionCard from './question-item/QuestionContainer';
import routes from '../../../../config/routes';
import { convertFirebaseTSToDate } from '../../../../utils/helpers';

interface QuestionListProps {
	questions: Question[] | undefined;
}

const QuestionList = ({ questions }: QuestionListProps) => {
	// DATA STATE
	const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);
	const [sortBy, setSortBy] = useState<'create' | 'modified'>('create');
	const [paginationSize, setPaginationSize] = useState<number>(10);
	// PAGINATION STATE
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalQuestions, setTotalQuestions] = useState<Question[]>([]);
	const currentTableData = useMemo(() => {
		const questionList =
			filteredQuestions.length > 0 ? filteredQuestions : questions ?? [];

		// SORTING
		const data = questionList?.sort((a: Question, b: Question) => {
			if (sortBy === 'create') {
				if (b.createdOn === undefined || a.createdOn === undefined) {
					return 1;
				}
				return convertFirebaseTSToDate(b.createdOn) >
					convertFirebaseTSToDate(a.createdOn)
					? 1
					: -1;
			} else {
				if (b.lastEditDate === undefined || a.lastEditDate === undefined) {
					return 1;
				}
				return convertFirebaseTSToDate(b.lastEditDate) >
					convertFirebaseTSToDate(a.lastEditDate)
					? 1
					: -1;
			}
			// return b.createdOn > a.createdOn ? 1 : -1;
		});

		setTotalQuestions(data);
		const firstPageIndex = (currentPage - 1) * paginationSize;
		const lastPageIndex = firstPageIndex + paginationSize;
		return data.slice(firstPageIndex, lastPageIndex);
	}, [currentPage, filteredQuestions, questions, paginationSize, sortBy]);

	return (
		<div>
			{/* PAGINATION */}
			<div className='flex justify-end mb-2'>
				<Pagination
					className='pagination-bar'
					currentPage={currentPage}
					siblingCount={1}
					totalCount={totalQuestions.length}
					// pageSize={PageSize}
					pageSize={paginationSize}
					onPageChange={(page) => setCurrentPage(page)}
				/>
				<select
					className='appearance-none rounded-md shadow-sm text-gray-600 border-gray-300 focus:border-brand-200 focus:ring focus:ring-brand-200 focus:ring-opacity-50 ml-2 text-xs'
					value={sortBy}
					onChange={(e) => setSortBy(e.target.value as 'create' | 'modified')}>
					<option value='create'>Sort by date created</option>
					<option value='modified'>Sort by date modified</option>
				</select>
				<select
					className='appearance-none rounded-md shadow-sm text-gray-600 border-gray-300 focus:border-brand-200 focus:ring focus:ring-brand-200 focus:ring-opacity-50 ml-2 text-xs'
					value={paginationSize}
					onChange={(e) => setPaginationSize(Number(e.target.value))}>
					<option value={10}>10</option>
					<option value={20}>20</option>
					<option value={30}>30</option>
					<option value={40}>40</option>
					<option value={50}>50</option>
					<option value={100}>100</option>
				</select>
			</div>

			{/* DISPLAY DATA */}
			{currentTableData.map((question) => {
				const route = routes.ADMIN_LINKS.QUESTIONS_EDIT;
				const path = `${route}/${question.id}`;
				return (
					<Link to={path} key={question.id}>
						<QuestionCard question={question} sortBy={sortBy} />
					</Link>
				);
			})}
		</div>
	);
};
export default QuestionList;
