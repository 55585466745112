import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../components/Logo';
import Button from '../../components/ui/Button';
import Headline from '../../components/ui/Headline';
import MaterialIcons from '../../components/ui/icons/MaterialIcons';
import { useLoadingContext } from '../../context/LoadingContext';
import MainTag from '../../layout/Main';
import { deleteAllCookies, scrollToTop } from '../../utils/helpers';
import routes from '../../config/routes';
import { useState } from 'react';

const Contactanos = () => {
	const navigateTo = useNavigate();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const [isReloaded, setIsReloaded] = useState(false);
	const handleReload = () => {
		setLoadingMessage('Un momento por favor en lo que recarga la aplicación.');
		setLoading(true);
		deleteAllCookies(false);
		setTimeout(() => {
			setLoading(false);
			// window.location.reload();
			setIsReloaded(true);
		}, 3000);
	};

	scrollToTop();
	return (
		// https://tailwindui.com/components/marketing/sections/cta-sections
		<div className='bg-white'>
			<div className='px-6 py-24 sm:px-6 sm:py-32 lg:px-8'>
				<div className='mx-auto max-w-2xl text-center'>
					<h2 className='text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl'>
						<MaterialIcons icon='contact_support' classes='text-5xl mr-2' />
						¿Necesitas comunicarte con nosotros?
					</h2>
					{/* <Headline
						classes='text-sky-800 mb-8 '
						title='¿Necesitas comunicarte con nosotros?'
						type='h1'>
						<MaterialIcons icon='contact_support' classes='text-5xl mr-2' />
					</Headline> */}
					<p className='mx-auto mt-6 max-w-xl text-pretty text-lg/8 text-gray-600'>
						¿Tienes alguna pregunta, duda, observación o queja? ¡Por favor no
						dudes en contactarnos! Si tienes fotos o videos que enviarnos por
						favor escríbenos a nuestro{' '}
						<a
							href='mailto:ayuda@prepmed.org'
							className='hover:text-brand-600 hover:underline'>
							correo de soporte
						</a>{' '}
						al cliente: ayuda@prepmed.org.
					</p>

					<p className='mx-auto mt-6 max-w-xl text-pretty text-lg/8 text-gray-600'>
						<strong>Nota:</strong> Si estás teniendo dificultades para cargar
						alguna página, trata el botón debajo para recargar la aplicación e
						intenta de nuevo. Si todavía estás teniendo inconvenientes, por
						favor escríbenos.
					</p>
					<div className='mt-10 flex items-center justify-center gap-x-6'>
						{isReloaded ? (
							<div className='flex justify-between items-center w-1/2'>
								<Link
									to={routes.AUTHENTICATION.LOGIN}
									className='rounded-md bg-brand-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600'>
									Acceder
								</Link>
								<Link
									to={routes.HOME}
									className='text-sm/6 font-semibold text-gray-900'>
									Crea tu Cuenta <span aria-hidden='true'>→</span>
								</Link>
							</div>
						) : (
							<Button
								classes='mt-4'
								bgColor='bg-brand-500 flex items-center justify-center'
								hoverBgColor='hover:bg-brand-600'
								icon='refresh'
								iconPosition='left'
								title='Recargar App'
								handleClick={handleReload}
							/>
						)}
					</div>
				</div>
			</div>

			<hr className='w-3/4 mx-auto my-8' />
			<div className='mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8'>
				<h2 className='max-w-2xl text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl'>
					Quieres Registrarte? <br />
					Crea tu cuenta hoy mismo.
				</h2>
				<div className='mt-10 flex items-center gap-x-6 lg:mt-0 lg:shrink-0'>
					<Link
						to={routes.AUTHENTICATION.SIGNUP}
						className='rounded-md bg-brand-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600'>
						Regístrate
					</Link>
					<Link
						to={routes.HOME}
						className='text-sm/6 font-semibold text-gray-900'>
						Más Info <span aria-hidden='true'>→</span>
					</Link>
				</div>
			</div>
		</div>
	);
};
export default Contactanos;
