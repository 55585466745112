import { useState } from 'react';
import Modal from '../../../../components/modals/Modal';
import type { Question as IQuestion } from '../../../../data/interface-question';
import DescriptionTextArea from '../../../../components/forms/DescriptionTextAreaQuill';
import EditCard from './EditCard';
import { removeEmptyParagraphsbyQuill } from '../../../../utils/helpers';
import parseHTML from 'html-react-parser';

interface VEExplanationProps {
	question: IQuestion;
	onUpdate: (
		questionID: string,
		key: string,
		newValue: any
	) => Promise<IQuestion | null>;
}

const ViewEditExplanation = ({ question, onUpdate }: VEExplanationProps) => {
	const [edit, setEdit] = useState(false);
	const [explanation, setExplanation] = useState<string>(question.explanation);

	const handleUpdate = () => {
		onUpdate(question.id, 'explanation', explanation)
			.then(() => setEdit(false))
			.catch((error) => console.error('Error updating question:', error));
	};

	return (
		<>
			<EditCard label='Explicación' onEdit={setEdit}>
				{/* <AudioExplanation audioFileName={audioFileName} />
					{!showImageInQuestion && (
						<ImageDisplay imagePathName={imagePathName} />
					)} */}

				{parseHTML(removeEmptyParagraphsbyQuill(explanation))}
			</EditCard>

			<Modal
				actionBtnText='Actualizar'
				handleAction={handleUpdate}
				headline='Actualiza la Explicación'
				isOpen={edit}
				onClose={() => setEdit(false)}
				showCloseBtn={true}>
				<DescriptionTextArea
					label='Actualiza la Explicación'
					id='question'
					infoMessage='Escribe la explicación que el usuario debe ver...'
					parentClass='flex justify-between items-center w-full mb-2'
					placeholder='Escribe la explicación aquí...'
					required={true}
					handleChange={(value: string) =>
						setExplanation(value.replace(/(<p><br><\/p>)/g, ''))
					}
					value={explanation}
					isFormSubmitted={false}
				/>
			</Modal>
		</>
	);
};
export default ViewEditExplanation;
