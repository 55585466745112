import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MainTag from '../../../layout/Main';
import useHideElementOnMount from '../../../hooks/useHideElementOnMount';
import { useLoadingContext } from '../../../context/LoadingContext';
import Logo from '../../../components/Logo';
import useUserContext from '../../../api/UserContext';
import Headline from '../../../components/ui/Headline';

import routes from '../../../config/routes';
import Card from '../../../components/ui/Card';
import MaterialIcons from '../../../components/ui/icons/MaterialIcons';
import { checkPreviousMembership } from '../../../utils/securities';
import { useBusinessContext } from '../../../api/BusinessContext';
// SETUP GUIDE: https://dev.to/paypaldeveloper/how-to-add-paypal-checkout-payments-to-your-react-app-53aa#part-2-adding-paypal-to-your-react-app

const PRECIO_DOLLAR = 63.48;
const CheckoutWithPayPal = () => {
	useHideElementOnMount('header');
	useHideElementOnMount('footer');
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const {
		currentUser,
		product,
		updateToPaidViaPayPal,
		getMarketing,
		marketing
	} = useUserContext();

	// TODO: Remove all the 'any' types
	const [{ options, isPending, isResolved }, dispatch] =
		usePayPalScriptReducer();
	const [error, setError] = useState<string>();
	const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

	useEffect(() => {
		setLoadingMessage('Un momento por favor...');
		setLoading(true);
		getMarketing()
			.then(() => {
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
		if (isResolved) {
			setLoading(false);
		}
	}, [isResolved]);
	// const [currency, setCurrency] = useState(options.currency);

	// const onCurrencyChange = (e: SyntheticEvent) => {
	// const value = (e.target as HTMLSelectElement).value;
	// setCurrency(value);
	// dispatch({
	// type: 'resetOptions',
	// value: {
	// ...options,
	// currency: value
	// }
	// });
	// };

	if (currentUser === null) return null;
	if (product === null) return null;
	const isRenewing = checkPreviousMembership(currentUser, product);
	const onCreateOrder = (data: any, actions: any) => {
		const price = isRenewing ? product.renewalPrice.rd : product.price;
		return actions.order.create({
			purchase_units: [
				{
					reference_id: product.id,
					// description: product.description,
					amount: {
						// currency_code: 'DOP',
						value: (price / PRECIO_DOLLAR).toFixed(2)
					},
					custom_id: product.officialExamName,
					soft_descriptor: 'PREPMED'
				}
			],
			application_context: { shipping_preference: 'NO_SHIPPING' }
		});
	};

	const onApproveOrder = (data: any, actions: any) => {
		setLoadingMessage('Un momento por favor en lo que procesamos tu orden...');
		setLoading(true);
		return actions.order.capture().then((details: any) => {
			// console.log('details', details);
			// debugThis();
			// const name = details.payer.name.given_name as string;
			// alert(`Transaction completed by ${name}`);
			updateToPaidViaPayPal()
				.then(() => {
					// if (currentUser === null || currentUser.uid === undefined) {
					// return null;
					// }
					// const userID = currentUser.uid;
					// const url =
					// `${window.location.origin}${routes.CHECKOUT.CHECKOUT_SUCCESS}${userID}` +
					// '?pymt_source=stripe';
					// redirectTo(url);
					setLoading(false);
					setIsPaymentSuccess(true);
				})
				.catch((err) => {
					setLoading(false);
					console.error('Error updating user to paid via paypal: ', err);
				});
		});
	};

	const onError = (err: any) => {
		console.error('Error:', err);
		setError(err);
	};

	if (product === null) return null;
	if (isPaymentSuccess) {
		return (
			<MainTag>
				<div className='flex flex-col items-center mb-4 mt-10  mx-auto'>
					<div className='container'>
						<Card classes='px-8 py-20 border rounded-md shadow-xl'>
							<Logo useDarkLogo={true} />
							<Headline
								classes='text-green-600'
								title='¡Gracias por tu compra!'
								type='h3'>
								<MaterialIcons icon='check_circle' classes='text-4xl mr-2' />
							</Headline>
							<div className='mt-8 fsize-medium'>
								<p>
									Hemos recibido tu pago satisfactoriamente. Tu tarjeta
									presentará un cargo a nombre de{' '}
									<span>&quot;PREPMED&quot;</span> o{' '}
									<span>&quot;PREPMEDRD.COM&quot;</span>.
								</p>
								<p>
									Queremos aprovechar la oportunidad para darte una cálida
									bienvenida y desearte la mejor de las suertes en el examen.
									¡Gracias por confiar en nosotros!
								</p>
								<p>
									Has tomado una excelente decisión y en breve podrás utilizar
									nuestra plataforma para prepararte a tomar el examen que
									decidirá tu futuro como médico en la República Dominicana.
								</p>
								<p>Presiona el botón debajo para acceder.</p>
							</div>

							<div className='flex justify-end items-end mt-4'>
								<Link
									to={routes.APP.INDEX}
									className='btn bg-brand-500 flex justify-end items-end'>
									Acceder
									<i className='material-icons ml-2'>send</i>
								</Link>
							</div>
						</Card>
					</div>
				</div>
			</MainTag>
		);
	}
	return (
		<MainTag>
			<div className='flex flex-col items-center mb-4 mt-10  mx-auto'>
				<div className='container'>
					<Logo useDarkLogo={true} height='h-20' classes='mx-0 mb-8' />
					<div className='mt-8'>
						<div className=' py-2'>
							<Headline type='h4' title='Completa tu pago con PayPal' />
							<p>
								Accede a todas las herramientas de estudio que ofrecemos para tu
								preparación del {product.officialExamName}: simulaciones,
								flashcards, preguntas corregidas y validadas con explicaciones,
								estadísticas, entre otras cosas...
							</p>

							<p>
								El precio de acceso con la oferta actual es de RD$
								{isRenewing
									? product.renewalPrice.rd.toLocaleString()
									: product.price.toLocaleString()}{' '}
								- el monto total a pagar es de aproximadamente US$
								{isRenewing
									? (product.renewalPrice.rd / PRECIO_DOLLAR).toFixed(2)
									: (product.price / PRECIO_DOLLAR).toFixed(2)}
							</p>
						</div>
					</div>
					<div className='checkout'>
						{isPending ? (
							<p>CARGANDO...</p>
						) : (
							// <select value={currency} onChange={onCurrencyChange}>
							// <option value='USD'>💵 USD</option>
							// <option value='EUR'>💶 Euro</option>
							// </select>
							<PayPalButtons
								style={{
									layout: 'vertical',
									color: 'blue',
									shape: 'rect',
									label: 'paypal'
								}}
								createOrder={(data, actions) => onCreateOrder(data, actions)}
								onApprove={(data, actions) => onApproveOrder(data, actions)}
								onError={(err) => onError(err)}
							/>
						)}
					</div>
				</div>
			</div>
		</MainTag>
	);
};
export default CheckoutWithPayPal;
