import { type OptionObjMustHaves } from '../interface-utils';

export const especialidadesOptions: OptionObjMustHaves[] = [
	{ id: 'anatomia-patologica', value: 'Anatomía Patológica' },
	{ id: 'anestesiologia', value: 'Anestesiología' },
	{ id: 'cirugia-buco-maxilo-facial', value: 'Cirugía Buco-Maxilo-Facial' },
	{ id: 'cirugia-general', value: 'Cirugía General' },
	{ id: 'cirugia-pediatrica', value: 'Cirugía Pediátrica' },
	{
		id: 'cirugia-plastica-reconstructiva',
		value: 'Cirugía Plástica y Reconstructiva'
	},
	{ id: 'citologia', value: 'Citología' },
	{ id: 'cuidados-intensivos', value: 'Cuidados Intensivos' },
	{ id: 'dermatogia', value: 'Dermatología' },
	{ id: 'diabetologia', value: 'Diabetología' },
	{ id: 'emergenciologia', value: 'Emergenciología' },
	{ id: 'fisiatria', value: 'Fisiatría' },
	{ id: 'geriatria', value: 'Geriatría' },
	{ id: 'gineco-obstetricia', value: 'Gineco-Obstetricia' },
	{ id: 'imagenologia', value: 'Imagenología' },
	{ id: 'medicina-familiar', value: 'Medicina Familiar' },
	{ id: 'medicina-forense', value: 'Medicina Forense' },
	{ id: 'medicina-interna', value: 'Medicina Interna' },
	{ id: 'neurocirugia', value: 'Neurocirugía' },
	{ id: 'neurologia', value: 'Neurología' },
	{ id: 'nutricion-hospitalaria', value: 'Nutrición Hospitalaria' },
	{ id: 'oftalmogogia', value: 'Oftalmología' },
	{ id: 'ortopedia-traumatologia', value: 'Ortopedia y Traumatología' },
	{ id: 'otorrinolaringologia', value: 'Otorrinolaringología' },
	{ id: 'pediatria', value: 'Pediatría' },
	{ id: 'psiquiatria', value: 'Psiquiatría' },
	{ id: 'radioterapia', value: 'Radioterapia' },
	{ id: 'salud-publica-epidemiologia', value: 'Salud Publica y Epidemiología' },
	{ id: 'urologia', value: 'Urología' }
];
