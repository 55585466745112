import { useState } from 'react';
import Modal from '../../../../components/modals/Modal';
import type { Question as IQuestion } from '../../../../data/interface-question';
import EditCard from './EditCard';
import ImageDisplay from '../../../private/examenes/ImageDisplay';
import UploadFile from '../../utils/UploadFile';

interface VEImageProps {
	question: IQuestion;
	onUpdate: (
		questionID: string,
		key: string,
		newValue: any
	) => Promise<IQuestion | null>;
}

const ViewEditImage = ({ question, onUpdate }: VEImageProps) => {
	const [edit, setEdit] = useState(false);
	const [imagePath, setImagePath] = useState<string>(question.imageFileName);

	const handleUpdate = () => {
		onUpdate(question.id, 'imageFileName', imagePath)
			.then(() => setEdit(false))
			.catch((error) => console.error('Error updating question:', error));
	};

	return (
		<>
			<EditCard label='Image' onEdit={setEdit}>
				{question.imageFileName === undefined ||
				question.imageFileName.trim() === '' ? (
					'No hay imagen para mostrar'
				) : (
					<ImageDisplay imagePathName={question.imageFileName} />
				)}
			</EditCard>

			<Modal
				actionBtnText='Agregar'
				handleAction={handleUpdate}
				headline='Agrega una Imagen'
				isOpen={edit}
				onClose={() => setEdit(false)}
				showCloseBtn={true}>
				<UploadFile
					onUpload={(imgPath: string) => setImagePath(imgPath)}
					storageFolder='question-images'
					fileName={imagePath}
					fileType='image'
				/>
			</Modal>
		</>
	);
};
export default ViewEditImage;
