import { useEffect } from 'react';
import { useBusinessContext } from '../../api/BusinessContext';
import useUserContext from '../../api/UserContext';
import MainSection from '../../layout/app/dashboard/MainSection';
import BeforeTest from './BeforeTest';
import AfterTest from './AfterTest';

const Dashboard = () => {
	const { currentUser } = useUserContext();
	const { examTarget, getExam } = useBusinessContext();
	const firstName: string = currentUser?.person?.firstName ?? '';
	const welcomeMessage = `¡Hola ${firstName}!`;

	useEffect(() => {
		currentUser !== null &&
			examTarget === null &&
			getExam()
				.then(() => console.info('Exam loaded', examTarget))
				.catch((e) => console.error('Error loading exam', e));
	}, [currentUser, examTarget]);

	if (currentUser === null) {
		return (
			<MainSection title={welcomeMessage}>
				<p>
					Hubo un problema cargando tu información. Por favor comunícate con
					nosotros.
				</p>
				<p className='text-brand-800'>
					<small>ERROR CODE: USER</small>
				</p>
			</MainSection>
		);
	}

	if (examTarget === null) {
		return (
			<MainSection title={welcomeMessage}>
				<p>
					Hubo un problema cargando tu información. Por favor comunícate con
					nosotros.
				</p>
				<p className='text-brand-800'>
					<small>ERROR CODE: EXAM-TARGET</small>
				</p>
			</MainSection>
		);
	}

	return (
		<MainSection title={welcomeMessage}>
			{examTarget?.testResultsAreOut ? (
				<AfterTest
					uid={currentUser.uid}
					examTarget={currentUser.examTarget}
					examTargetYear={currentUser.examTargetYear}
				/>
			) : (
				<BeforeTest />
			)}
		</MainSection>
	);
};
export default Dashboard;
