import { createContext, useContext, useState } from 'react';
import type { ReactNode } from 'react';
import LogRocket from 'logrocket';
import { auth, handleEventLog } from '../gcp/config';
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	onAuthStateChanged,
	signOut,
	type UserCredential
} from 'firebase/auth';

import type { FirebaseError } from 'firebase/app';

import useUserContext from './UserContext';
import { useLoadingContext } from '../context/LoadingContext';
import { useNavigate } from 'react-router-dom';
import routes from '../config/routes';
import type { User } from '../data/interface-user';
import Swal from 'sweetalert2';
import { checkPreviousMembership } from '../utils/securities';

interface ILogin {
	isError: boolean;
	message: string;
	uid: string;
}
interface LoginStatus {
	isLoggedIn: boolean;
	message: string;
	isRedirectNeeded: boolean;
	redirectPath: string;
}
export type AuthContextType = {
	redirectToLoginIfUnauthorized: (location: string) => void;
	loginUser: (email: string, password: string) => Promise<LoginStatus>;
	sendPasswordReset: (email: string) => Promise<string>;
	signOutCurrentUser: (isForcedSignout?: boolean) => Promise<void>;
	signUpNewUser: (email: string, password: string) => Promise<string>;
	setIsNewSignUp: React.Dispatch<React.SetStateAction<boolean>>;
};
export const AuthContext = createContext<AuthContextType | null>(null);
export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
	const navigateTo = useNavigate();
	const { setLoading, setLoadingMessage, hideSpinner } = useLoadingContext();
	const {
		getProductByID,
		getUserFromFirestore,
		currentUser,
		setCurrentUser,
		createUserInFirestore,
		endFreeTrial,
		updateIsLoggedIn
	} = useUserContext();
	const [isNewSignUp, setIsNewSignUp] = useState(false);

	const handleAuthenticationErrors = (error: FirebaseError): string => {
		// ~ HANDLE DIFFERENT ERROR CODES HERE
		// https://firebase.google.com/docs/auth/admin/errors
		// logError(err, email);

		handleEventLog(`AUTHENTICATION_ERROR: ${error.code}`, error);
		let errorMessage = '';
		switch (error.code) {
			// #REGION ~ SIGNUP ERRORS
			case 'auth/email-already-in-use':
				errorMessage =
					'Ya existe un usuario con este correo. Si habías creado una cuenta previamente, por favor ve a nuestra página de "Iniciar Sesión" o usa otro correo electrónico para crear tu cuenta.';

				break;
			case 'auth/email-already-exists':
				errorMessage =
					'Ya existe un usuario con este correo. Si habías creado una cuenta previamente, por favor ve a nuestra página de "Iniciar Sesión" o usa otro correo electrónico para crear tu cuenta.';

				break;
			case 'auth/invalid-email':
				errorMessage = 'El formato del correo electrónico es inválido.';

				break;
			case 'auth/invalid-password':
				errorMessage = 'La contraseña debe tener un míninmo de 6 digitos.';

				break;

			case 'auth/invalid-phone-number':
				errorMessage = 'El formato del telefono es incorrecto.';

				break;
			case 'auth/phone-number-already-exists':
				errorMessage = 'El número de telefono ya existe.';

				break;
			case 'invalid date':
				errorMessage = 'La fecha de nacimiento es invalida.';

				break;
			// #endregion
			// #region ~ LOGIN ERRORS
			case 'auth/user-not-found':
				errorMessage = 'No existe un usuario con este correo.';
				break;
			case 'auth/wrong-password':
				errorMessage =
					'La contraseña es incorrecta. Por favor trata de nuevo o usa el link de "¿Olvidaste tu Contraseña?".';
				break;
			case 'auth/too-many-requests':
				errorMessage =
					'Has intentado iniciar sesión demasiadas veces. Por favor intenta de nuevo más tarde.';
				break;
			// #endregion
			case 'auth/network-request-failed':
				errorMessage =
					'Hubo un problema con la red. Por favor intenta de nuevo más tarde.';
				break;
			default:
				errorMessage = error.message;
				break;
		}
		return errorMessage;
	};

	const signUpNewUser = async (
		userEmail: string,
		userPassword: string
	): Promise<string> => {
		setLoading(true);
		setLoadingMessage('Creando tu cuenta...');

		const result = createUserWithEmailAndPassword(auth, userEmail, userPassword)
			.then(async (userCredential) => {
				setIsNewSignUp(true);
				// Signed in
				const user = {
					uid: userCredential.user.uid,
					email: userEmail,
					password: userPassword,
					signUpDate: new Date(),
					appVersionWeb: 2.0, // TODO: Update this with the actual business.appVersion.web
					isLoggedIn: false,
					loginCode: 0
				};

				const res = await createUserInFirestore(user);
				// TODO: Update sales count
				if (res === 'user-created') {
					setLoadingMessage('');
					setCurrentUser(user);
					return 'user-created';
				} else {
					return 'error';
				}
			})
			.catch((error) => {
				const message = handleAuthenticationErrors(error);
				return message;
			});

		return await result;
	};

	const firebaseLogin = async (
		userEmail: string,
		userPassword: string
	): Promise<ILogin> => {
		const result = signInWithEmailAndPassword(auth, userEmail, userPassword)
			.then(async (userCredential: UserCredential) => {
				// Signed in
				return { isError: false, message: '', uid: userCredential.user.uid };
			})
			.catch((error) => {
				// console.error('Error signing in: ', error);
				const message = handleAuthenticationErrors(error);
				return { isError: true, message, uid: '' };
			});
		const finalResult = await result;
		const loginObject: ILogin = {
			isError: finalResult.isError,
			message: finalResult.message,
			uid: finalResult.uid
		};
		return loginObject;
	};

	const loginUser = async (
		userEmail: string,
		userPassword: string
	): Promise<LoginStatus> => {
		setLoading(true);
		setLoadingMessage('Validando credenciales...');
		let result: LoginStatus = {
			isLoggedIn: false,
			message:
				'No pudimos encontrar tu información. Por favor intenta de nuevo o comunícate con nosotros.',
			isRedirectNeeded: false,
			redirectPath: ''
		};
		try {
			const res = await firebaseLogin(userEmail, userPassword);
			if (res.isError) {
				result.message = res.message;
			} else {
				// Signed in
				setLoadingMessage('Iniciando sesión...');
				result = await loadUser(res.uid);
				return result;
			}
		} catch (error) {
			console.log('LOGIN_ERROR:', error);
			handleEventLog('LOGIN_ERROR:');
		}

		return result;
	};

	const sendPasswordReset = async (userEmail: string) => {
		setLoadingMessage('Enviando correo electrónico...');
		const result = sendPasswordResetEmail(auth, userEmail)
			.then(() => {
				setLoadingMessage('Correo electrónico enviado.');
				return 'success';
			})
			.catch((error) => {
				const message = handleAuthenticationErrors(error);
				return message;
				// return message;
			});
		return await result;
	};

	const redirectToLoginIfUnauthorized = (location: string) => {
		onAuthStateChanged(auth, (user) => {
			if (user !== null) {
				if (currentUser === null) {
					setLoadingMessage('Cargando usuario...');
					loadUser(user.uid)
						.then(() => {
							// console.info('User loaded');
						})
						.catch((error) => {
							setLoading(false);
							handleEventLog('AUTH_CHECK_ERROR:', error);
						});
				}
			} else {
				if (location !== routes.AUTHENTICATION.LOGIN) {
					setLoadingMessage(
						'No se encontró un usuario. Por favor vuelve a iniciar sesión... '
					);
					setLoading(true);
					setTimeout(() => {
						hideSpinner();
						navigateTo(routes.AUTHENTICATION.LOGIN);
					}, 2000);
				}
			}
		});
	};

	const loadUser = async (uid: string): Promise<LoginStatus> => {
		let result: LoginStatus = {
			isLoggedIn: false,
			message:
				'No pudimos encontrar tu información. Por favor intenta de nuevo o comunícate con nosotros.',
			isRedirectNeeded: false,
			redirectPath: ''
		};
		setLoadingMessage('Cargando tu información...');

		// if (currentUser !== null) {
		// const targetYear: number = currentUser.examTargetYear ?? 0;
		// const membership = currentUser[`membership_${targetYear}`];
		// const hasMembership: boolean = membership?.isPaid ?? false;
		// if (hasMembership && (currentUser.freeTrial?.isActive ?? false)) {
		// await endFreeTrial();
		// }
		// }

		localStorage.setItem('userID', uid);
		const user: User | null = await getUserFromFirestore(uid);
		if (user !== null) {
			LogRocket.identify(uid, {
				name: `${user.person?.firstName ?? ''} ${user.person?.lastName ?? ''}`,
				email: user.email ?? ''

				// Add your own custom user variables here, ie:
				// subscriptionType: 'pro'
			});
		}

		// HANDLE DATA LOAD
		if (isNewSignUp) {
			// TODO: Send welcome email
			result.message = 'new-user-signed-up';
			result.isLoggedIn = true;
			result.isRedirectNeeded = false;
		} else {
			if (user !== null) {
				const loginStatus = await handleNavigationOnLogin(user);
				result = loginStatus;
			}
		}
		return result;
	};

	const handleMultipleUserConflict = () => {
		Swal.fire({
			title: 'Cuenta activa en otro dispositivo',
			html: 'Solo puedes utilizar <b>un dispositivo a la vez</b>. Cerramos todas las sesiones para que puedas acceder del dispositivo que quieras. <br><p class="mt-4">Por favor vuelve a iniciar sesión para continuar.</p>',
			icon: 'warning',
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			customClass: {
				confirmButton: 'bg-brand-500 hover:bg-brand-700'
			}
		})
			.then(async () => {
				await signOutCurrentUser(true);
			})
			.catch((error) => {
				console.error('Error signing out user: ', error);
			});
	};

	const handleNavigationOnLogin = async (
		user: User | null
	): Promise<LoginStatus> => {
		const thisUser = currentUser === null ? user : currentUser;
		if (thisUser === null || thisUser.uid === undefined) {
			return {
				isLoggedIn: false,
				message:
					'No pudimos encontrar tu información. Por favor intenta de nuevo o comunícate con nosotros.',
				isRedirectNeeded: false,
				redirectPath: ''
			};
		}

		if (thisUser.isAdmin ?? false) {
			return {
				isLoggedIn: true,
				message: '',
				isRedirectNeeded: true,
				redirectPath: routes.APP.PATH_TO.DASHBOARD // TODO: Add admin dashboard → routes.ADMIN.DASHBOARD
			};
		} else {
			const loginCode = Number(localStorage.getItem('loginCode') ?? '0');
			const hasMatchingCode =
				thisUser.loginCode === loginCode && loginCode !== 0;

			if ((thisUser.isLoggedIn ?? false) && !hasMatchingCode) {
				handleMultipleUserConflict();
				return {
					isLoggedIn: false,
					message:
						'Tu cuenta está activa en otro dispositivo y solo puedes utilizar uno a la vez. Estamos cerrando las sesiones en todos los dispositivos conectados, por favor vuelve a iniciar sesión para continuar.',
					isRedirectNeeded: false,
					redirectPath: ''
				};
			} else {
				const result = await handleUserRedirectPath(thisUser);
				return result;
			}
		}
	};

	const handleUserRedirectPath = async (user: User): Promise<LoginStatus> => {
		if (user.examTarget !== undefined && user.examTargetYear !== undefined) {
			// TODO: After launch modify below to use React Query instead
			const product = await getProductByID(user.examTarget);
			if (product !== null) {
				if (user.examTargetYear === product.nextExamTargetYear) {
					const membership = user[`membership_${user.examTargetYear}`];
					const hasMembership: boolean = membership?.isPaid ?? false;
					const isFreeTrialActive: boolean = user.freeTrial?.isActive ?? false;
					if (hasMembership || isFreeTrialActive) {
						try {
							// await getCategories();
							// await getQuestions();
							// await getTestRecords();
							// navigateTo(routes.APP.INDEX);
							return {
								isLoggedIn: true,
								message: '',
								isRedirectNeeded: true,
								redirectPath: routes.APP.PATH_TO.DASHBOARD
							};
						} catch (error) {
							console.log(error);
							return {
								isLoggedIn: false,
								message:
									'Hubo un error cargando tu información. Por favor intenta de nuevo o comunícate con nosotros.',
								isRedirectNeeded: false,
								redirectPath: ''
							};
						}
					} else {
						return {
							isLoggedIn: true,
							message: '',
							isRedirectNeeded: true,
							redirectPath: routes.APP.PATH_TO.MEMBERSHIP.CHECKOUT
						};
					}
				} else {
					const isReturningPaidUser = checkPreviousMembership(user, product);
					if (isReturningPaidUser) {
						return {
							isLoggedIn: true,
							message: '',
							isRedirectNeeded: true,
							redirectPath: routes.APP.POST_TEST.THANK_YOU
						};
					} else {
						return {
							isLoggedIn: true,
							message: '',
							isRedirectNeeded: true,
							redirectPath: routes.APP.PATH_TO.MEMBERSHIP.CHECKOUT
						};
					}
				}
			} else {
				return {
					isLoggedIn: true,
					message: '',
					isRedirectNeeded: true,
					redirectPath: routes.APP.PATH_TO.MEMBERSHIP.PLANS
				};
			}
		} else {
			return {
				isLoggedIn: true,
				message: '',
				isRedirectNeeded: true,
				redirectPath: routes.APP.PATH_TO.MEMBERSHIP.PLANS
			};
		}
	};

	const signOutCurrentUser = async (isForcedSignout = false) => {
		setLoadingMessage('Cerrando sesión...');

		const signoutUser = async () =>
			await signOut(auth)
				.then(() => {
					// Sign-out successful.
					localStorage.removeItem('userID');
					setCurrentUser(null);
					// revokeAccessTokens(uid);
					navigateTo(routes.AUTHENTICATION.LOGIN);
				})
				.catch((error) => {
					// An error happened.
					console.info('Error signing out: ', error);
				});

		const uid = currentUser?.uid;
		if (typeof uid === 'string') {
			await updateIsLoggedIn(uid, false);
		}

		if (isForcedSignout) {
			localStorage.removeItem('loginCode');
		}

		await signoutUser();
	};

	// const revokeAccessTokens = (uid: string) => {
	// // https://firebase.google.com/docs/auth/admin/manage-sessions
	// // Revoke all refresh tokens for a specified user for whatever reason.
	// // Retrieve the timestamp of the revocation, in seconds since the epoch.
	// getAuth()
	//   .revokeRefreshTokens(uid)
	//   .then(() => {
	//     // return getAuth().getUser(uid);
	//   })
	//   .then((userRecord) => {
	//     return new Date(userRecord.tokensValidAfterTime).getTime() / 1000;
	//   })
	//   .then((timestamp) => {
	//     console.log(`Tokens revoked at: ${timestamp}`);
	//   });}

	return (
		<AuthContext.Provider
			value={{
				redirectToLoginIfUnauthorized,
				setIsNewSignUp,
				loginUser,
				sendPasswordReset,
				signOutCurrentUser,
				signUpNewUser
			}}>
			{children}
		</AuthContext.Provider>
	);
};
export const useAuthContext = () => {
	const context = useContext(AuthContext);
	if (context === undefined || context === null) {
		throw new Error('useAuthContext must be used within a AuthContextProvider');
	}
	return context;
};
export default AuthContextProvider;
