import confetti from 'canvas-confetti';
import { useState } from 'react';

// TODO: Define the right types
export default function useSideCannons() {
    // const [showSideCannons, setShowSideCannons] = useState(false);
    function showSideCannons() {
        const end = Date.now() + 3000; // Duration of the celebration
        const colors = ['a786ff', '#fd8bbc', '#eca184']
        const frame = () => {
          if (Date.now() > end) {
            return;
          }
          void confetti({
            particleCount: 3,
            angle: 60,
            spread: 55,
            startVelocity: 60,
            origin: { x: 0, y: 1 },
            colors: colors
          });
          void confetti({
            particleCount: 3,
            angle: 120,
            spread: 55,
            startVelocity: 60,
            origin: { x: 1, y: 1 },
            colors: colors
          });
          requestAnimationFrame(frame);
        };
        frame();
      }
    return { showSideCannons };
}
