interface MaterialIconsProps {
	icon: string;
	classes?: string;
	title?: string;
}

/**
 * Required props: icon | Optional props: classes, title
 */
const MaterialIcons = ({
	icon,
	classes = '',
	title = ''
}: MaterialIconsProps) => {
	return (
		<i className={`material-icons ${classes}`} title={title}>
			{icon}
		</i>
	);
};
export default MaterialIcons;
