import { useState } from 'react';
import Modal from '../../../../components/modals/Modal';
import type { Question as IQuestion } from '../../../../data/interface-question';
import Fuente from '../../../private/examenes/test-review/Fuente';
import DescriptionTextArea from '../../../../components/forms/DescriptionTextAreaQuill';
import EditCard from './EditCard';

interface VEExplanationProps {
	question: IQuestion;
	onUpdate: (
		questionID: string,
		key: string,
		newValue: any
	) => Promise<IQuestion | null>;
}

const ViewEditFuente = ({ question, onUpdate }: VEExplanationProps) => {
	const [edit, setEdit] = useState(false);
	const [fuente, setFuente] = useState<string>(question.fuente);

	const handleUpdate = () => {
		onUpdate(question.id, 'fuente', fuente)
			.then(() => setEdit(false))
			.catch((error) => console.error('Error updating question:', error));
	};

	return (
		<>
			<EditCard label='Fuente' onEdit={setEdit}>
				<Fuente fuentes={fuente} />
			</EditCard>

			<Modal
				actionBtnText='Actualizar'
				handleAction={handleUpdate}
				headline='Actualiza la Fuente'
				isOpen={edit}
				onClose={() => setEdit(false)}
				showCloseBtn={true}>
				<DescriptionTextArea
					label='Actualiza la Explicación'
					id='explanation'
					infoMessage='Escribe la fuente que el usuario debe ver...'
					parentClass='flex justify-between items-center w-full mb-2'
					placeholder='Escribe la fuente aquí...'
					required={true}
					handleChange={(value: string) =>
						setFuente(value.replace(/(<p><br><\/p>)/g, ''))
					}
					value={fuente}
					isFormSubmitted={false}
				/>
			</Modal>
		</>
	);
};
export default ViewEditFuente;
