// import { v4 as uuidv4 } from 'uuid';
import { useEffect, useReducer, useState } from 'react';
import type { SyntheticEvent } from 'react';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import useUserContext from '../../../../api/UserContext';
import { useLoadingContext } from '../../../../context/LoadingContext';
import MainSection from '../../../../layout/app/dashboard/MainSection';
import QuestionBankReducer, {
	qbrTypes
} from '../../../../api/QuestionBankReducer';
import type {
	TestType,
	QuestionRecord,
	Category,
	SubCategory,
	IQuestionBankReducerState
} from '../../../../data/interface-question';
import { deleteDB } from '../../../../data/indexedDBHelper';
import Headline from '../../../../components/ui/Headline';
import SelectElement from '../../../../components/forms/SelectElement';
import NumberInput from '../../../../components/forms/NumberInput';
// import { useQuery } from '@tanstack/react-query';
// import { getQuestionsCollection } from '../../../api/QuestionsAPI';
import TestSelectionsDescription from '../TestSelectionsDescription';
import {
	questionTypeOptions,
	testLabelOptions,
	testModeOptions,
	testTypeOptions,
	timerOptions
} from '../../../../data/option-objects/examOptions';
import FormLabel from '../../../../components/forms/FormLabel';
import InputCheckbox from '../../../../components/forms/InputCheckbox';
import Alert from '../../../../components/ui/Alert';
import ToggleModal from '../../../../components/modals/ToggleModal';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../config/routes';
import { shuffle } from 'lodash';
import Swal from 'sweetalert2';
import type { UnfinishedTestRecord } from '../../../../data/interface-user';
import type { OptionObjMustHaves } from '../../../../data/interface-utils';
import { scrollToTop } from '../../../../utils/helpers';

const SelectTestQuestions = () => {
	const navigateTo = useNavigate();
	// #region CONTEXT IMPORTS
	const { currentUser } = useUserContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();

	const {
		categories,
		questions,
		testRecords,
		testSelections,
		saveTestSelections,
		setTestSelections,
		getCategories,
		getQuestions,
		getTestRecords
	} = useQuestionBankContext();
	// #endregion

	// #region REDUCER
	const initialState: IQuestionBankReducerState = {
		allQuestions: [],
		categories: [],
		especialidades: [],
		label: 'none',
		// questionBank: null, // todo: is this used?
		questionType: 'todas',
		questions: [],
		showCategorySection: true,
		showTopicSelectionBoxes: false,
		testDuration: '',
		testLabel: 'all',
		testLength: 50,
		testMode: 'aleatorio',
		testRecords: testRecords === null ? [] : [...testRecords],
		testType: 'pruebín',
		timerStartTime: 0,
		topics: [],
		topicSubcategories: [],
		useTimer: false,
		useTutorMode: false,
		userCorrectQuestions: [],
		userIncorrectQuestions: []
	};
	const [state, dispatch] = useReducer(QuestionBankReducer, initialState);
	// TODO: CLEANUP - REMOVE UNUSED TYPES
	const {
		GET_QUESTIONS,
		GET_CATEGORIES,
		SUBCATEGORY_LIST_ADD,
		SUBCATEGORY_LIST_REMOVE,
		LOAD_CORRECT_INCORRECT_QUESTIONS,
		TEST_LABEL,
		TEST_MODE,
		TEST_QUESTIONS_TYPE_CHANGE,
		TEST_TIMER_DURATION,
		TEST_TOPICS_CHANGE,
		TEST_TOPIC_SUBCATEGORIES_CHANGE,
		TEST_TYPE,
		TEST_TYPE_CUSTOM_LENGTH,
		TOPIC_ADD,
		TOPIC_REMOVE,
		USE_TIMER,
		USE_TUTOR_MODE
	} = qbrTypes;
	// #endregion

	const isFreeTrialUser = currentUser?.freeTrial?.isActive ?? false;

	// #region USE STATE
	const [showModal, setShowModal] = useState<boolean>(false);
	const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
	const [showTestLengthInput, setShowTestLengthInput] =
		useState<boolean>(false);
	const [subcategories, setSubcategories] = useState<SubCategory[]>([]);
	const [qTypeOptions, setTypeOptions] =
		useState<OptionObjMustHaves[]>(questionTypeOptions);
	const [qLabelOptions, setLabelOptions] = useState<OptionObjMustHaves[]>(
		testLabelOptions[currentUser?.examTarget ?? 'enarm']
	);
	// #endregion

	useEffect(() => {
		if (currentUser !== null) {
			loadData();
		}
	}, [currentUser]);

	useEffect(() => {
		if (questions !== undefined && questions.length > 0) {
			setLoadingMessage('Analizando preguntas...');
			setLoading(true);
			getRightWrongAnswers();
		}
	}, [questions, testRecords]);

	useEffect(() => {
		if (testSelections !== undefined) {
			setTestReady();
			// RESET STATE
			dispatch({
				type: 'default',
				payload: initialState
			});
		}
	}, [testSelections]);

	useEffect(() => {
		getSubcategoryList();
	}, [state.topics]);

	useEffect(() => {
		if (state.allQuestions.length > 0) {
			setLoading(false);
			setLoadingMessage('');
		}
		calcNuevasRepetidas();
		calcBancoDePreguntas();
	}, [state]);

	useEffect(() => {
		setLoadingMessage('Un momento por favor...');
		setLoading(true);
	}, []);

	const correctQuestions: string[] = [];
	const incorrectQuestions: string[] = [];

	const getRightWrongAnswers = () => {
		if (questions === undefined || questions.length === 0) return;
		testRecords?.forEach((record) => {
			record.questions?.forEach((q: QuestionRecord) => {
				const question = questions.find((i) => i.id === q.id);

				if (question !== undefined) {
					const isRightAnswer = question.answer === q.selectedAnswer;
					const isWrongAnswer = question.answer !== q.selectedAnswer;
					if (isRightAnswer && !correctQuestions.includes(q.id)) {
						return correctQuestions.push(q.id);
					}
					if (isWrongAnswer && !incorrectQuestions.includes(q.id)) {
						return incorrectQuestions.push(q.id);
					}
				}
			});
		});

		dispatch({
			type: LOAD_CORRECT_INCORRECT_QUESTIONS,
			payload: {
				correctas: correctQuestions,
				incorrectas: incorrectQuestions
			}
		});

		// setLoading(false);
	};

	const loadRequiredData = async () => {
		// TODO: Need to change this to React Query
		setIsDataLoaded(true);
		const c = await getCategories();
		dispatch({
			type: GET_CATEGORIES,
			payload: c
		});
		const q = await getQuestions();
		// const pD = q.filter((question) => question.isPruebaDiagnostica);
		const noPD = q.filter((question) => !question.isPruebaDiagnostica);
		dispatch({
			type: GET_QUESTIONS,
			payload: noPD
		});
		await getTestRecords();
	};

	const handleDirectPull = (e: SyntheticEvent) => {
		e.preventDefault();
		Swal.fire({
			title: 'Por favor confirma...',
			html: 'Dependiendo de tu conexión, recargar las preguntas puede ser rápido o puede tomarse unos minutos. <p class="mt-2 mb-0">¿Quieres recargar las preguntas ahora?</p>',
			icon: 'warning',
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			customClass: {
				confirmButton: 'bg-green-500 hover:bg-green-700',
				cancelButton: 'bg-red-500 hover:bg-red-600'
			}
		})
			.then(async (response) => {
				if (response.isConfirmed) {
					setLoadingMessage(
						'Espera un momento en lo que recargamos las preguntas...'
					);
					setLoading(true);
					localStorage.removeItem('prepmed-last-questions-pull-date');
					try {
						await deleteDB('prepmed-questions');
						window.location.reload();
					} catch (error) {
						setLoading(false);
						console.error('Error re-initializing choose questions', error);
					}
				}
			})
			.catch((error: any) => {
				setLoading(false);
				console.error('Sweet alert:', error);
			});
	};

	const getTotalQuestionsByCategories = (categoryID: string) => {
		if (questions === undefined || questions.length === 0) return 0;
		const count = questions.filter((q) => {
			return q.categoryID === categoryID;
		}).length;
		return ` (${count})`;
	};

	const getTotalQuestionsBySubCategories = (categoryID: string) => {
		if (questions === undefined || questions.length === 0) return 0;
		const count = questions.filter((q) => {
			return q.subCategoryList?.includes(categoryID);
		}).length;
		return ` (${count})`;
	};

	// #region FORM HANDLERS AND HELPERS
	const handleTestLengthChange = (value: string) => {
		// TODO: Use timer doesn't need to be here
		// if (state.useTimer) {
		// dispatch({
		// type: USE_TIMER,
		// payload: state.useTimer
		// });
		// }

		if (value === 'otro') {
			setShowTestLengthInput(true);
			dispatch({
				type: TEST_TYPE,
				payload: 'personalizado'
			});
		} else {
			setShowTestLengthInput(false);
			dispatch({
				type: TEST_TYPE,
				payload: value as TestType
			});
		}
	};

	const handleCategorySelection = (e: SyntheticEvent) => {
		const target = e.target as HTMLInputElement;

		if (target.checked) {
			// ADD TOPIC
			dispatch({
				type: TOPIC_ADD,
				payload: target.id
			});
		} else {
			// REMOVE TOPIC
			dispatch({
				type: TOPIC_REMOVE,
				payload: target.id
			});
		}
		// dispatch({
		// type: TEST_TOPICS_CHANGE,
		// payload: {
		// id: target.id,
		// isChecked: target.checked
		// }
		// });
	};
	const handleSubCategorySelection = (e: SyntheticEvent) => {
		const target = e.target as HTMLInputElement;

		if (target.checked) {
			dispatch({
				type: SUBCATEGORY_LIST_ADD,
				payload: target.id
			});
		} else {
			dispatch({
				type: SUBCATEGORY_LIST_REMOVE,
				payload: target.id
			});
		}
		// dispatch({
		// type: TEST_TOPICS_CHANGE,
		// payload: {
		// id: target.id,
		// isChecked: target.checked
		// }
		// });
	};

	const getSubcategoryList = (): SubCategory[] => {
		const subcategoryList: SubCategory[] = [];

		if (categories === undefined) return subcategoryList;
		state.topics.forEach((t: string) => {
			const result = categories.filter((c) => {
				return c.id === t;
			})[0];

			if (result?.subCategoryList !== undefined) {
				subcategoryList.push(...result.subCategoryList);
			}
		});

		setSubcategories(subcategoryList);
		return subcategoryList;
	};

	const handleTestLabelChange = (value: string) => {
		dispatch({
			type: TEST_LABEL,
			payload: value
		});
	};

	const handleTestModeChange = (value: string) => {
		dispatch({
			type: TEST_MODE,
			payload: value
		});
	};

	const handleTestQuestionsTypeChange = (value: string) => {
		dispatch({
			type: TEST_QUESTIONS_TYPE_CHANGE,
			payload: value
		});
	};

	const handleUseTimer = (e: SyntheticEvent) => {
		// console.info('Timer handler', e);
		dispatch({
			type: USE_TIMER,
			payload: state.useTimer
		});
	};

	const handleTimerDuration = (e: string) => {
		dispatch({
			type: TEST_TIMER_DURATION,
			payload: Number(e)
		});
	};

	const handleUseTutorMode = (e: SyntheticEvent) => {
		// console.info('Tutor mode handler', e);
		dispatch({
			type: USE_TUTOR_MODE,
			payload: state.useTutorMode
		});
	};
	// #endregion

	// #region USE QUERY
	// TODO: RE-ENABLE - THIS IS THE RIGHT WAY. hERE'S THE PLAN:
	// 1. Load the questions using useQuery
	// 2. On "isSuccess" handle all the state logic in useReducer || useContext
	// 3. Use the data from the state, not the useQuery

	// const { isLoading, isPending, isSuccess, isError, error, data } = useQuery({
	// queryKey: ['resources'],
	// queryFn: getQuestionsCollection
	// });

	// const {
	// isLoading: isQLoading,
	// isPending: isQPending,
	// isSuccess: isQSuccess,
	// isError: isQError,
	// error: qError,
	// data: qData
	// } = useQuery({
	// queryKey: ['questions'],
	// queryFn: getQuestions
	// });
	// const {
	// isLoading: istrLoading,
	// isPending: istrPending,
	// isSuccess: istrSuccess,
	// isError: istrError,
	// error: trError,
	// data: trData
	// } = useQuery({
	// queryKey: ['testRecords'],
	// queryFn: getTestRecords
	// });
	// const {
	// isLoading: isCLoading,
	// isPending: isCPending,
	// isSuccess: isCSuccess,
	// isError: isCError,
	// error: cError,
	// data: cData
	// } = useQuery({
	// queryKey: ['categories'],
	// queryFn: getCategories
	// });

	// if (isQPending || istrPending || isCPending) {
	// setLoadingMessage('Cargando preguntas...');
	// setLoading(true);
	// }
	// if (isQLoading || istrLoading || isCLoading) {
	// setLoadingMessage('Analizando tu historial...');
	// setLoading(true);
	// }
	// if (isQError || istrError || isCError) {
	// console.info('Error loading questions', qError, trError, cError);
	// setLoading(false);
	// }
	// if (isQSuccess && istrSuccess && isCSuccess) {
	// if (testRecords !== null) {
	// testRecords?.length > 0 && getRightWrongAnswers();
	// }
	// setLoading(false);
	// }

	// #endregion

	const loadData = () => {
		// TODO: THIS IS INCORRECT BUT FOR NOW... WHATEVER
		if (!isDataLoaded) {
			setLoadingMessage('Cargando preguntas...');
			setLoading(true);
			loadRequiredData()
				.then(() => {
					console.info('Data loaded');
				})
				.catch(async (err) => {
					setLoading(false);
					console.error('Error loading required data', err);
					await Swal.fire({
						title: 'Error',
						text: 'No podemos guardar las selecciones para tu examen en este momento...',
						icon: 'error'
					});
				});
		}
	};

	const handleSubmit = (e: SyntheticEvent) => {
		e.preventDefault();
		setShowModal(true);
	};

	const setTestReady = () => {
		navigateTo(routes.APP.PATH_TO.TEST_MODE);
	};

	const handleStartTest = () => {
		// TODO: NEED TO VALIDATE MINIMUM REQUIREMENTS FOR THE FORM TO BE SUBMITTED
		const shuffledQuestions = shuffle(state.questions);
		const limitedQuestions = shuffledQuestions.slice(0, state.testLength);
		const selections = { ...state, questions: limitedQuestions };

		// #region CODE FOR UNFINISHED TESTS
		const questionIDs: string[] = [];
		selections.questions.forEach((q) => {
			questionIDs.push(q.id);
		});

		const unfinishedTest: UnfinishedTestRecord = {
			...selections,
			questionIDs: questionIDs,
			currentQuestionIndex: 0,
			answersSelected: []
		};

		saveTestSelections(unfinishedTest)
			.then(() => {
				console.info('Test selections saved');
			})
			.catch(async (err: any) => {
				console.error('-- ERROR SAVING TEST SELECTIONS:');
				console.error(err);
				await Swal.fire({
					title: 'Error',
					text: 'No podemos guardar las selecciones para tu examen en este momento...',
					icon: 'error'
				});
			});
		// #endregion

		setTestSelections({ ...selections });
	};

	const calcNuevasRepetidas = () => {
		if (testRecords !== null && testRecords.length > 10) {
			let idsOfQuestionsTaken: string[] = [];
			const questionsTaken: QuestionRecord[] = [];
			state.testRecords.forEach((tr) => questionsTaken.push(...tr.questions));
			idsOfQuestionsTaken = [
				...questionsTaken
					.map((item) => item.id)
					.filter((value, index, self) => self.indexOf(value) === index)
			];
			const repetidas = state.questions.filter((q) => {
				return idsOfQuestionsTaken.includes(q.id);
			});
			const nuevas = state.questions.filter((q) => {
				return !idsOfQuestionsTaken.includes(q.id);
			});
			const result = { nuevas, repetidas };
			questionTypeOptions.find((q) => q.id === 'nuevas')?.value?.concat();
			const typeOptions = questionTypeOptions.map((q) => {
				if (q.id === 'nuevas' || q.id === 'repetidas') {
					return {
						id: q.id,
						value: q.value.concat(` (${result[q.id].length})`)
					};
				} else {
					return {
						id: q.id,
						value: q.value.concat(` (${state.questions.length})`)
					};
				}
			});

			setTypeOptions(typeOptions);
		}
	};

	const calcBancoDePreguntas = () => {
		if (isFreeTrialUser) {
			setLabelOptions(testLabelOptions.enurmOnFreeTrial);
		} else {
			const labelOptions = testLabelOptions[
				currentUser?.examTarget ?? 'enarm'
			].map((q) => {
				if (q.id === 'all') {
					return {
						id: q.id,
						value: q.value.concat(` (${state.questions.length})`)
					};
				} else if (q.id === 'correctas') {
					return {
						id: q.id,
						value: q.value.concat(
							` (${Number(state.userCorrectQuestions.length)})`
						)
					};
				} else if (q.id === 'incorrectas') {
					return {
						id: q.id,
						value: q.value.concat(
							` (${Number(state.userIncorrectQuestions.length)})`
						)
					};
				} else {
					const count = state.questions.filter(
						(question) => question.source === q.id
					).length;
					return {
						id: q.id,
						value: q.value.concat(` (${count})`)
					};
				}
			});
			setLabelOptions(labelOptions);
		}
	};

	scrollToTop();

	// TODO:  && testRecords !== null && questions !== null
	if (currentUser !== null) {
		return (
			<MainSection title='Elige tu Modo de Examen'>
				<div className='flex flex-col lg:flex-row justify-between'>
					{/* ===== DESCRIPTION ===== */}
					<TestSelectionsDescription />
					{/* ===== FORM ===== */}
					<div className='w-full lg:w-6/12 xl:w-4/12'>
						<Headline
							title='Crea tu Examen'
							type='h2'
							classes='text-base uppercase font-semibold mt-0 mb-4'
						/>
						{isFreeTrialUser && (
							<p className='bg-red-200 p-2 text-center'>
								<strong>Nota:</strong> Las cuentas de prueba solo tienen acceso
								al enurm 2020.
							</p>
						)}
						<form onSubmit={handleSubmit}>
							{/* Elige un tipo de examen */}
							<>
								<SelectElement
									defaultOption='- Elige un tipo -'
									id='test-type'
									icon='ballot'
									iconFam='material-icons'
									label='Elige un tipo de examen'
									options={testTypeOptions}
									value={state.testType}
									handleChange={(e) => {
										handleTestLengthChange(e);
									}}
								/>
								{showTestLengthInput && (
									<NumberInput
										guideMessage='100 es el máximo de preguntas que puedes elegir.'
										id='testLengthInput'
										icon='dialpad'
										iconFam='material-icons'
										min={1}
										max={100}
										label='¿Qué cantidad de preguntas quieres?'
										placeholder='Elige de 1 a 100 preguntas'
										handleChange={(e) =>
											dispatch({
												type: TEST_TYPE_CUSTOM_LENGTH,
												payload: Number(e)
											})
										}
									/>
								)}
							</>
							{/* questionType */}
							{testRecords !== null && testRecords.length > 10 && (
								<SelectElement
									id='label'
									icon='question_answer'
									iconFam='material-icons'
									label='¿Preguntas nuevas, repetidas o todas?'
									value={state.questionType}
									options={qTypeOptions}
									handleChange={(e) => {
										handleTestQuestionsTypeChange(e);
									}}
								/>
							)}

							{/* Banco de Preguntas / LABEL */}
							<>
								<SelectElement
									defaultOption='- Elige un Banco -'
									id='test-label'
									icon='label'
									iconFam='material-icons'
									label='Elige un banco de preguntas'
									value={state.testLabel}
									// options={
									// isFreeTrialUser
									// ? testLabelOptions.enurmOnFreeTrial
									// : testLabelOptions[currentUser?.examTarget ?? 'enarm']
									// }
									options={qLabelOptions}
									handleChange={(e) => {
										handleTestLabelChange(e);
									}}
								/>
							</>

							{/* Elige un modo / categorias y sub-categorias */}
							{state.showCategorySection && (
								<>
									<SelectElement
										defaultOption='- Elige un Modo -'
										id='testMode'
										icon='tune'
										iconFam='material-icons'
										label='Elige un Modo'
										value={state.testMode}
										options={testModeOptions}
										handleChange={(e) => handleTestModeChange(e)}
									/>

									{/* Elige las categorias de interes */}
									{state.showTopicSelectionBoxes && (
										<>
											<FormLabel label='Elige las categorías de interés' />
											<div className='w-full'>
												{state.categories?.length > 0 &&
													state.categories
														.filter(
															(category: Category) =>
																category.id !== 'M8rTWnWd2Wh9WLZtsMQn'
														) // Evaluacion diagnostica 1
														.filter(
															(category: Category) =>
																category.id !== 'DpVZVQbCpngLsSuCXX8J'
														) // Evaluacion diagnostica 2
														.sort((a: Category, b: Category) => {
															if (a.name < b.name) {
																return -1;
															}
															if (a.name > b.name) {
																return 1;
															}
															return 0;
														})
														.map((category: Category) => {
															return (
																<InputCheckbox
																	key={category.id}
																	id={category.id}
																	label={category.name}
																	labelAdditionlInfo={getTotalQuestionsByCategories(
																		category.id
																	).toString()}
																	isChecked={state.topics.includes(category.id)}
																	parentClass='relative inline-flex items-start my-1 2xl:w-full w-1/2'
																	handleChange={(e) =>
																		handleCategorySelection(e)
																	}
																/>
															);
														})}
											</div>
											{(state.testLabel === 'correctas' ||
												state.testLabel === 'incorrectas') &&
												state.questions !== undefined && (
													<p className='center mb-0'>
														Total de Preguntas Disponibles:{' '}
														{state.questions.length}
													</p>
												)}
										</>
									)}

									{/* Elige las sub-categorias de interes */}
									{state.topics.length > 0 && (
										<div className='mt-4'>
											<div className='mb-4'>
												<FormLabel label='Elige las sub-categorias de interés' />
												{subcategories.map((subcategory) => {
													return (
														<InputCheckbox
															key={subcategory.name}
															id={subcategory.name}
															label={subcategory.name}
															labelAdditionlInfo={getTotalQuestionsBySubCategories(
																subcategory.name
															).toString()}
															isChecked={state.topicSubcategories.includes(
																subcategory.name
															)}
															parentClass='relative flex items-start my-1'
															handleChange={(e) =>
																handleSubCategorySelection(e)
															}
														/>
													);
												})}
											</div>
											{(state.testLabel === 'correctas' ||
												state.testLabel === 'incorrectas') &&
												state.questions !== undefined && (
													<p className='center mb-0'>
														Total de Preguntas Disponibles:{' '}
														{state.questions.length}
													</p>
												)}
										</div>
									)}
								</>
							)}

							{/* Timer */}
							{!state.useTutorMode && (
								<>
									<InputCheckbox
										id='use-timer'
										label='¿Quieres tu examen con límite de tiempo?'
										handleChange={(e) => handleUseTimer(e)}
										isChecked={state.useTimer}
										tooltip='Este modo simula el examen real mostrandote tu tiempo restante en la esquina superior de la pantalla.'
									/>

									{/* TIMER DURATION */}
									{state.useTimer && (
										<SelectElement
											id='timer-duration'
											icon='timer'
											iconFam='material-icons'
											label='Elige tu tiempo límite'
											// value={state.testDuration}
											defaultOption='- Elige un tiempo límite -'
											options={timerOptions}
											handleChange={(e) => {
												handleTimerDuration(e);
											}}
										/>
									)}
								</>
							)}

							{/* Tutor Mode */}
							{!state.useTimer && (
								<InputCheckbox
									id='use-tutor-mode'
									label='¿Quieres examinarte en modo tutor?'
									tooltip='El modo tutor te permite ver la respuesta correcta después de cada pregunta.'
									handleChange={(e) => handleUseTutorMode(e)}
									isChecked={state.useTutorMode}
								/>
							)}

							{/* Buttons */}
							<div className='w-full'>
								{state.useTimer && state.testDuration === '' && (
									<p className='alert center'>
										Debes elegir un tiempo límite para continuar.
									</p>
								)}

								{(state.allQuestions.length === 0 ||
									state.allQuestions.length < state.testLength) && (
									<Alert title='Atencion...' type='error' classes='px-8 mt-4'>
										<p>
											No hay suficientes preguntas para examinarte con tu
											selección actual.
										</p>

										<p>
											Esto puede pasar por múltiples razones incluyendo una
											conexión de internet inestable.
										</p>

										<p>
											Si crees que esto es un error, por favor, recarga las
											preguntas disponibles haciendo clic en el botón de abajo:
										</p>

										<div className='mt-4'>
											{/* <div className='-mx-2 -my-1.5 flex'> */}
											<div>
												<button
													type='button'
													className='rounded-md px-2 py-1.5 text-sm font-medium bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50'
													onClick={(e) => handleDirectPull(e)}>
													Recargar Preguntas
												</button>
											</div>
										</div>
									</Alert>
								)}

								{state.questions?.length < state.testLength && (
									<Alert title='Atencion...' type='warning' classes='px-8 mt-4'>
										<p>
											No hay suficientes preguntas para examinarte con tu
											selección actual.
										</p>

										<p>
											Por favor verfica la cantidad de preguntas que estás
											eligiendo y los filtros que estás usando.
										</p>
									</Alert>
								)}

								{state.questions?.length >= state.testLength && (
									<ToggleModal
										actionBtnText='Empezar Examen'
										handleAction={handleStartTest}
										buttonClass='bg-brand-500'
										buttonText='Empezar'
										headline='Confirma...'
										isOpen={showModal}
										isDisabled={
											(state.useTimer && state.testDuration === '') ||
											state.testLength <= 0 ||
											isNaN(state.testLength) ||
											state.questions?.length < state.testLength ||
											(state.showTopicSelectionBoxes &&
												state.topics.length === 0)
										}
										handleVisibility={() => setShowModal(false)}>
										<div className='flex justify-center'>
											{state.testType === 'personalizado' ? (
												<p className='fsize-medium'>
													¿Estás list@ para tu examen personalizado?
												</p>
											) : (
												<p className='fsize-medium'>
													¿Estas listo para tu{' '}
													<strong>{state.testType ?? 'examen'}</strong> (
													{state.testLength} preguntas)
													{state.useTimer && <span> con tiempo límite</span>}
													{state.useTutorMode && <span> en modo tutor</span>}?
												</p>
											)}
										</div>
									</ToggleModal>
								)}
							</div>
						</form>
					</div>
				</div>
			</MainSection>
		);
	}

	return (
		<MainSection title='Examínate'>
			<div>
				Hubo un error de carga, por favor recarga la pagina o contacta soporte
				al cliente.
			</div>
		</MainSection>
	);
};
export default SelectTestQuestions;

/* SUGERENCIAS */
/* <div className='overflow-hidden rounded-xl border border-gray-200'>
							<div className='p-6'>
								<Headline
									title='SUGERENCIAS'
									classes='text-base font-semibold leading-6 text-gray-500'
								/>
								<p className='text-sm text-gray-500'>
									Para un examen con límite de tiempo, te recomendamos:
								</p>
								<ul className='normal-bullets text-sm text-gray-500'>
									<li>1 hora para pruebines</li>
									<li>2 horas para simulaciones</li>
									<li>2 minutos por pregunta si es personalizado</li>
								</ul>
							</div>
						</div> */
