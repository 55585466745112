import { useEffect, useState } from 'react';
import MainSection from '../../../layout/app/dashboard/MainSection';
import useUserContext from '../../../api/UserContext';
import useQuestionBankContext from '../../../api/QuestionBankContext';
import { convertFirebaseTSToDateString } from '../../../utils/helpers';
import type { Category, TestRecord } from '../../../data/interface-question';
import { buildCategoryObject } from '../../../api/xBuildObjectUtils';
import { useLoadingContext } from '../../../context/LoadingContext';
import Headline from '../../../components/ui/Headline';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	Line,
	LineChart,
	Rectangle,
	ResponsiveContainer,
	XAxis,
	YAxis
} from 'recharts';
import Tooltip from '../../../components/ui/Tooltip';

interface CategoryObj {
	id: string;
	category: Category;
}

interface ChartData {
	name: string;
	nota: number;
	preguntas: number;
}

const StatsDashboard = () => {
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { questions, categories, testRecords } = useQuestionBankContext();
	const [showAnalisis, setShowRunAnalisys] = useState(false);
	const [categoryObjs, setCategoryWithStats] = useState<Category[]>();
	const [testRecordData, setTestRecordData] = useState<ChartData[]>([]);
	useEffect(() => {
		// smooth scroll to top of the page
		window.scrollTo(0, 0);

		getTotals();
		getChartData();
	}, [categories, questions, testRecords]);

	const createObject = async () => {
		return await new Promise((resolve, reject) => {
			if (categories === undefined) return;
			// const categoryArray = [];
			// categories.forEach((category) => {
			// categoryArray.push([category.id, 0]);
			// });
			// const obj = Object.fromEntries(categoryArray);
			// M8rTWnWd2Wh9WLZtsMQn == Evaluacion Diagnostica 1
			// DpVZVQbCpngLsSuCXX8J == Evaluacion Diagnostica 2
			const obj = Object.fromEntries(
				categories
					.filter(
						(category: Category) =>
							category.id !== 'M8rTWnWd2Wh9WLZtsMQn' &&
							category.id !== 'DpVZVQbCpngLsSuCXX8J'
					)
					.map((category) => [
						category.id,
						{
							totalQuestions: 0,
							name: category.name,
							id: category.id
							// TODO: Should I add sub categories here?
						}
					])
			);
			resolve(obj);
		});
	};

	const getTotalQuestionsByCategories = (categoryID: string): number => {
		if (questions === undefined || questions.length === 0) return 0;
		const count = questions.filter((q) => {
			return q.categoryID === categoryID;
		}).length;
		return count;
	};

	const getTotals = () => {
		if (
			questions === undefined ||
			questions.length === 0 ||
			categories === undefined
		) {
			return;
		}
		setLoadingMessage('Analizando tus estadísticas...');
		setLoading(true);

		const newCategories: Category[] = [...categories];
		newCategories.forEach((category) => {
			category.totalQuestions = getTotalQuestionsByCategories(category.id);
			countCorrectQuestionsByCategory(category.id)
				.then((result: number[]) => {
					category.totalAnsweredCorrectly = result[0];
					category.totalAnsweredInQuestionRecords = result[1];
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
					console.error('Error: ', error);
				});
		});

		setCategoryWithStats(newCategories);

		// GET TOTAL TESTS
		// GET TOTAL QUESTIONS TAKEN
		// GET TOTAL SUBCATEGORIES
		// GET TOTAL TEST RECORDS
		// GET TOTAL TEST RECORDS BY TEST TYPE
		// GET TOTAL TEST RECORDS BY TEST
		// GET TOTAL TEST RECORDS BY CATEGORY
		// GET TOTAL TEST RECORDS BY SUBCATEGORY
	};

	const countCorrectQuestionsByCategory = async (
		categoryID: string
	): Promise<number[]> => {
		return await new Promise((resolve, reject) => {
			try {
				let correctAnswersInCategory = 0;
				let totalQuestionsInCategory = 0;
				testRecords?.forEach((record) => {
					const tempSum = record.questions?.reduce((sum, qRecord) => {
						const question = questions?.find((q) => q.id === qRecord.id);

						if (question?.categoryID === categoryID) {
							totalQuestionsInCategory = totalQuestionsInCategory + 1;
							if (question.answer === qRecord.selectedAnswer) {
								return sum + 1;
							}
						}

						return sum;
					}, 0);
					correctAnswersInCategory = tempSum + correctAnswersInCategory;
				});
				resolve([correctAnswersInCategory, totalQuestionsInCategory]);
			} catch (error) {
				console.error('Error: ', error);
				reject(error);
			}
		});
	};

	const getCategoryPercentage = (
		correctQuestions: number,
		totalQuestions: number
	) => {
		const result = correctQuestions / totalQuestions;
		if (result > 0) return `${Math.round((result + Number.EPSILON) * 100)}%`;
		return '-';
	};

	const getChartData = () => {
		const records =
			testRecords !== null &&
			testRecords !== undefined &&
			testRecords?.length > 0
				? [...testRecords]
				: [];
		records.slice(0, 10);
		const data: ChartData[] = [];
		records.forEach((record: TestRecord) => {
			const testLength = record.questions?.length;
			const grade = record.questions?.reduce((sum, qRecord) => {
				const question = questions?.find((q) => q.id === qRecord.id);
				if (question?.answer === qRecord.selectedAnswer) {
					return sum + 1;
				}
				return sum;
			}, 0);
			data.push({
				name: convertFirebaseTSToDateString(record.date),
				nota: grade,
				preguntas: testLength
			});
		});
		setTestRecordData(data);
	};
	return (
		<MainSection
			title='Estadísticas'
			subTitle='Explora un análisis detallado de tus exámenes anteriores. Consulta tu rendimiento en cada categoría, visualiza tus notas promedio, identifica áreas de mejora y sigue tu progreso a lo largo del tiempo para optimizar tu preparación.'>
			<div className='my-8 w-full px-4 sm:px-6 lg:px-8'>
				<section className='mb-4'>
					<Headline title='Tus Últimos 10 Exámenes' type='h3' />
					<p className='mb-10'>
						Visualiza un gráfico detallado que muestra el rendimiento de tus
						últimos 10 exámenes, incluyendo la nota promedio y la cantidad de
						preguntas por examen. Esta herramienta te ayuda a monitorear tu
						progreso y detectar tendencias en tu desempeño.
					</p>
					{/* <ResponsiveContainer width='100%' height={400}>
						<BarChart
						width={500}
height={300}
data={testRecordData}
margin={{
top: 5,
right: 30,
left: 20,
bottom: 5
}}>
<CartesianGrid strokeDasharray='3 3' />
<XAxis dataKey='name' />
<YAxis />
<Tooltip />
<Legend />
<Bar
dataKey='grade'
fill='#8884d8'
activeBar={<Rectangle fill='pink' stroke='blue' />}
/>
<Bar
dataKey='testLength'
fill='#82ca9d'
activeBar={<Rectangle fill='gold' stroke='purple' />}
/>
					</BarChart>
					</ResponsiveContainer> */}

					{/* TO DO: Fix for mobile view */}

					<ResponsiveContainer width={'100%'} height={400}>
						<LineChart
							width={500}
							height={300}
							data={testRecordData}
							accessibilityLayer>
							<XAxis dataKey='name' />
							<YAxis />
							<CartesianGrid stroke='#4b5563' strokeDasharray='5 5' />
							<Line type='monotone' dataKey='nota' stroke='#5eb6e7' />
							<Line type='monotone' dataKey='preguntas' stroke='#64cd86' />
							{/* <Line type='monotone' dataKey='preguntas' stroke='#82ca9d' /> */}
							{/* <Tooltip />  // TODO: Fix tooltip */}
							<Legend />
						</LineChart>
					</ResponsiveContainer>
				</section>
				<section id='promedios-por-categoria' className='mb-4'>
					<Headline title='Nota Promedio por Categoría' type='h3' />
					<p>
						Esta estadística se calcula dividiendo el número de preguntas
						correctas por el total de preguntas vistas en cada categoría.
						Proporciona una visión detallada de tu rendimiento en áreas
						específicas, ayudándote a identificar fortalezas y áreas de mejora.
					</p>
					<div className='stat-cards flex justify-evenly items-baseline flex-wrap mt-8 space-y-8'>
						{categoryObjs !== undefined &&
							categoryObjs.length > 0 &&
							categoryObjs
								.filter((category) => category.id !== 'M8rTWnWd2Wh9WLZtsMQn')
								.filter((category) => category.id !== 'DpVZVQbCpngLsSuCXX8J')
								.map((category) => {
									return (
										<div
											key={category.id}
											className='card-panel mx-1 flex flex-col justify-center items-center shadow-md bg-white p-4 rounded-lg text-center my-4'
											style={{
												width: '160px',
												borderRadius: '20px',
												height: '185px'
											}}>
											<div className='circle2 mx-auto bg-alt1-200'>
												<span className='circle data bg-alt1-150 rounded-full border border-alt1-400/75 outline-alt1-400/75 outline outline-offset-4'>
													{getCategoryPercentage(
														category.totalAnsweredCorrectly,
														category.totalAnsweredInQuestionRecords
													)}
												</span>
											</div>
											<p className='mb-0 text-sm'>
												<strong>{category.name}</strong>
											</p>
											<small>
												{category.totalAnsweredCorrectly} /
												{category.totalAnsweredInQuestionRecords}
												{/* {category.totalQuestions} */}
											</small>
										</div>
									);
								})}
					</div>
				</section>
				<section id='More-soon'>
					<Headline title='Pronto tendrás más...' type='h3' />
					<p>
						Pronto tendremos más estadísticas disponibles para ti. Estamos en un
						constante proceso de mejora para ofrecerte una experiencia de
						aprendizaje más completa y personalizada.
					</p>
				</section>
			</div>
		</MainSection>
	);

	// return (
	// <MainSection title=''>
	// <main className='grid min-h-full place-items-center bg-gray-100 px-6 py-24 sm:py-32 lg:px-8'>
	// <div className='text-center'>
	// <p className='text-base font-semibold text-blue-600'>Estadísticas</p>
	// <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
	// Temporalmente Fuera de Servicio
	// </h1>
	// <p className='mt-6 text-base leading-7 text-gray-700'>
	// ¡Por favor disculpa el inconveniente! Estamos trabajando para
	// mejorar la funcionalidad de esta página y así poder ofrecerte una
	// mejor experiencia. Vuelve a revisar en unos días.
	// </p>
	// {/* <div className='mt-10 flex items-center justify-center gap-x-6'>
	// <a
	// href='#'
	// className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
	// Go back home
	// </a>
	// <a href='#' className='text-sm font-semibold text-gray-900'>
	// Contact support <span aria-hidden='true'>&rarr;</span>
	// </a>
	// </div> */}
	// </div>
	// </main>
	// </MainSection>
	// );
};
export default StatsDashboard;
