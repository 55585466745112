import { useState } from 'react';
import Modal from '../../../../components/modals/Modal';
import type {
	Question as IQuestion,
	IQuestionIssues
} from '../../../../data/interface-question';
import EditCard from './EditCard';
import QuestionIssues from '../questions-summary-list/question-item/QuestionIssues';

interface QuestionProps {
	question: IQuestion;
	onUpdate: (
		questionID: string,
		key: string,
		newValue: any
	) => Promise<IQuestion | null>;
}

const ViewEditIssues = ({ question, onUpdate }: QuestionProps) => {
	const [edit, setEdit] = useState(false);
	const [issues, setIssues] = useState<IQuestionIssues | undefined>(
		question.issues
	);

	const handleUpdate = () => {
		onUpdate(question.id, 'issues', issues)
			.then(() => setEdit(false))
			.catch((error) => console.error('Error updating question:', error));
	};

	if (question.issues === undefined) return null;
	return (
		<>
			<EditCard label='Issues' onEdit={setEdit}>
				<QuestionIssues issues={question.issues} />
			</EditCard>

			<Modal
				actionBtnText='Agregar'
				handleAction={handleUpdate}
				headline='Agrega una Imagen'
				isOpen={edit}
				onClose={() => setEdit(false)}
				showCloseBtn={true}>
				<button
					className='mb-4 text-sm text-white bg-red-500'
					onClick={() => setIssues(undefined)}>
					Clear Issues
				</button>
			</Modal>
		</>
	);
};
export default ViewEditIssues;
