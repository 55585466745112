import parseHTML from 'html-react-parser';
import { removeEmptyParagraphsbyQuill } from '../../../../utils/helpers';
import ImageDisplay from '../ImageDisplay';

interface QuestionProps {
	question: string;
	imagePathName: string;
	showImageInQuestion: boolean;
}

const Question = ({
	question,
	imagePathName,
	showImageInQuestion
}: QuestionProps) => {
	return (
		<div style={{ marginBottom: '0 !important' }}>
			{parseHTML(removeEmptyParagraphsbyQuill(question))}

			{showImageInQuestion && <ImageDisplay imagePathName={imagePathName} />}
		</div>
	);
};
export default Question;
