import { Link, useNavigate } from 'react-router-dom';
import type {
	IQuestionBankReducerState,
	QuestionRecord,
	TestRecord
} from '../../../../data/interface-question';
import routes from '../../../../config/routes';
import MaterialIcons from '../../../../components/ui/icons/MaterialIcons';
import Card from '../../../../components/ui/Card';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import parseHTML from 'html-react-parser';
import {
	calcTestGrade,
	secondsToHoursMinutesSeconds
} from '../../../../utils/helpers';
import Headline from '../../../../components/ui/Headline';
import { useEffect, useState } from 'react';

interface TestResultProps {
	score: number;
	testSelections: IQuestionBankReducerState;
	timeElapsedInSeconds: number;
	qnALog: QuestionRecord[];
	testRecord: TestRecord;
}

// TODO: Add a celebration animation overlay
const TestResults = ({
	score,
	testSelections,
	timeElapsedInSeconds,
	qnALog,
	testRecord
}: TestResultProps) => {
	const navigateTo = useNavigate();
	const { setTestSelections } = useQuestionBankContext();

	const handleClick = () => {
		setTestSelections(undefined);
		navigateTo(routes.APP.INDEX);
	};

	const handleNavigate = () => {
		setTestSelections(undefined);
		navigateTo(routes.APP.PATH_TO.TEST_REVIEW, {
			state: {
				testRecord
			}
		});
	};

	return (
		<>
			<Card>
				<div className='flex flex-col items-center justify-center space-y-8 bg-white'>
					<Headline title='Resumen de tu Examen' isCentered={true} type='h4' />
					<p>
						{parseHTML(
							`Tuviste <strong>${score}</strong> respuestas correctas en tu ${
								testSelections.testType === 'personalizado'
									? 'examen personalizado'
									: testSelections.testType
							} de <strong>${
								testSelections.testLength
							}</strong> preguntas para una nota final de:`
						)}
					</p>
					<div className='flex justify-around items-center w-3/4 lg:w-6/12'>
						<div className=''>
							<strong style={{ fontSize: 'xx-large' }}>{score}</strong>
							<span className='mx-1'>/</span>
							{testSelections.testLength}
						</div>
						<div>
							<strong
								className={`${
									testRecord.grade < 70 ? 'text-red-500' : 'text-green-500'
								}`}
								style={{ fontSize: 'xx-large' }}>
								{testRecord.grade}%
							</strong>
						</div>
					</div>
					{/* // TODO: uncomment and fix below */}
					{/* {testRecord.timerStoppedTest && (
						<p>
							Se acabó el tiempo antes de que pudieras terminar el examen.
							Considera practicar para aumentar tu eficiencia al responder.
						</p>
					)} */}
					{testRecord.useTimer && (
						<p className='mt-8'>
							Terminaste el{' '}
							{testSelections.testType === 'personalizado'
								? 'examen personalizado'
								: testSelections.testType}{' '}
							en un tiempo total de:{' '}
							{secondsToHoursMinutesSeconds(timeElapsedInSeconds)}.
						</p>
					)}
					<div className='flex flex-col sm:flex-row space-y-4 sm:space-y-0 items-center justify-between w-3/4 lg:w-6/12'>
						<button
							className='btn bg-brand-500 flex items-center justify-center'
							onClick={handleNavigate}>
							Más Detalles
							<MaterialIcons icon='send' classes='ml-4' />
						</button>

						{/* <Link
							to={routes.APP.PATH_TO.QUESTION_SELECTIONS}
							className='btn btn-flat'>
							<span className='material-icons'>refresh</span>{' '}
							<span>Examínate De Nuevo</span>
						</Link> */}

						<button
							className='btn-large bg-gray-400 shadow-lg flex items-center justify-center'
							// onClick={() => navigateTo(routes.APP.FULLPATH.QUESTION_SELECTIONS)}
							onClick={handleClick}>
							{/* Continuar
							<MaterialIcons icon='send' classes='ml-4' /> */}
							<MaterialIcons icon='dashboard' classes='mr-4' />
							<span>Panel Principal</span>
						</button>
					</div>
				</div>
			</Card>
		</>
	);
};
export default TestResults;
