import { useState } from 'react';
import { useBusinessContext } from '../../api/BusinessContext';
import type { QuestionBankID } from '../../data/interface-question-bank';
import type { EspecialidadesDePreferencia } from '../../models/interfaces/especialidad.interfaces';
import PostTestResultConsultation from './post-test/PostTestResultConsultation';
import MinMaxByYear from './post-test/MinMaxByYear';

interface AfterTestProps {
	uid: string | undefined;
	examTarget: QuestionBankID | undefined;
	examTargetYear: number | undefined;
}

const AfterTest = ({ uid, examTarget, examTargetYear }: AfterTestProps) => {
	const { getPostTestConsultation } = useBusinessContext();
	const [consultation, setConsultation] =
		useState<EspecialidadesDePreferencia>();
	const handleConsultation = () => {
		if (
			uid === undefined ||
			examTargetYear === undefined ||
			typeof examTargetYear !== 'number'
		) {
			return;
		}

		getPostTestConsultation(uid, examTargetYear)
			.then((data: EspecialidadesDePreferencia | null) => {
				if (data !== null) {
					setConsultation(data);
				}
			})
			.catch((e) => console.error('Error loading post test consultation', e));
	};

	if (
		uid === undefined ||
		examTarget === undefined ||
		examTargetYear === undefined
	) {
		return (
			<>
				<p>
					Problema cargando &quot;PostTest&quot; - No se encontro un usuario
				</p>
				<p className='text-brand-800'>
					<small>ERROR CODE: USER NOT FOUND</small>
				</p>
			</>
		);
	}

	if (examTarget === 'enurm') {
		handleConsultation();
		if (consultation === undefined) {
			return <PostTestResultConsultation year={examTargetYear} />;
		} else {
			return (
				<section className='mt-2 lg:mt-8'>
					<div className=''>
						<p>
							¡Gracias por formar parte del grupo PrepMedRD {examTargetYear}!
						</p>
						<p>
							Ya el examen pasó y esperamos que todo tu esfuerzo se haya
							reflejado al 100% en como te sientes después del examen.
						</p>
						{/* <p className='txt-black my-1'>
										Nuestra herramienta de consulta te permite ver las notas de 2
										especialidades de tu elección y con qué notas entraron en los
										últimos 5 años. Tener esta información ahora te servirá para
										motivarte a estudiar y mejorar aún más antes del examen.
									</p> */}
						{/* <p>
							Una vez las notas se hagan públicas estaremos abriendo una función
							que tenemos para que puedas ver con qué nota entraron en años
							anteriores a tu especialidad de preferencia. No olvides estar
							pendiente a tu correo!
						</p> */}
						<p>
							Esperamos que tu experiencia con PrepMed haya excedido las
							expectativas y tus resultados lo demuestren. Gracias por confiar
							en nosotros.
						</p>

						<p>
							Estas son las notas con las que entraron en años anteriores a tus
							especialidades de interés elegidas:
						</p>
					</div>
					<MinMaxByYear especialidadID={consultation.especialidad_target_1} />
					<MinMaxByYear especialidadID={consultation.especialidad_target_2} />
				</section>
			);
		}
	}

	return (
		<section className='mt-2 lg:mt-8'>
			<div className='text-center'>
				<p>¡Gracias por formar parte del grupo PrepMedRD {examTargetYear}!</p>
				<p>
					Ya el examen pasó y esperamos que todo tu esfuerzo se haya reflejado
					al 100% en como te sientes despues del examen.
				</p>
				<p>
					Una vez las notas se hagan públicas estaremos abriendo una función que
					tenemos para que puedas ver con qué nota entraron en años anteriores a
					tu especialidad de preferencia. No olvides estar pendiente a tu
					correo!
				</p>
				<p>
					Esperamos que tu experiencia con nosotros haya excedido las
					expectativas y tus resultados lo demuestren. Gracias por confiar en
					nosotros.
				</p>
			</div>
		</section>
	);
};
export default AfterTest;
