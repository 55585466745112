// ROUTING
import { Routes, Route } from 'react-router-dom';

// ROUTES
import routes from './config/routes';

// COMPONENTS
import Footer from './layout/footer/Footer';
import Header from './layout/Header';
import Home from './pages/public/home/Home';
import Login from './pages/public/Login';
import ComingSoon from './pages/ComingSoon';
import Signup from './pages/public/signup/Signup';
import LoadingFull from './components/loaders/LoadingFull';
import About from './pages/public/about/About';
import DashboardContainer from './pages/private/Dashboard';
import Contactanos from './pages/public/Contactanos';
import NuevoPassword from './pages/public/NuevoPassword';
import useUserContext from './api/UserContext';
import Checkout from './pages/private/sales/Checkout';
import PrivateRoutes from './config/routes/PrivateRoutes';
import { useEffect, useState } from 'react';
import SeasonIsClosed from './pages/public/signup/SeasonIsClosed';
import Enurm from './layout/Products/Enurm';
import CMF from './layout/Products/CMF';
import PrepDerm from './layout/Products/PrepDerm';
import ThankYou from './pages/private/post-test/ThankYou';
import TermsOfService from './pages/public/about/TermsOfService';
import PrivacyPolicy from './pages/public/about/PrivacyPolicy';

function PrepMed() {
	const [isPrivateRoute, setIsPrivateRoute] = useState(false);
	useEffect(() => {
		setIsPrivateRoute(window.location.pathname.includes('/app'));
	}, [isPrivateRoute]);

	return (
		<div className='bg-brand-25 min-h-screen'>
			<LoadingFull />
			{!isPrivateRoute && <Header />}

			<Routes>
				{/* PUBLIC PAGES */}
				<Route path={routes.PUBLIC.COMING_SOON} element={<ComingSoon />} />
				<Route path={routes.HOME} element={<Home />} />
				<Route path={routes.AUTHENTICATION.LOGIN} element={<Login />} />
				<Route
					path={routes.AUTHENTICATION.NEW_PASSWORD}
					element={<NuevoPassword />}
				/>
				{/* // TODO: Re-enable once you can re-factor signup */}
				<Route path={routes.AUTHENTICATION.SIGNUP} element={<Signup />} />
				{/* <Route
					path={routes.AUTHENTICATION.SIGNUP}
					element={<SeasonIsClosed />}
				/> */}
				<Route path={routes.PUBLIC.ABOUT_US} element={<About />} />
				<Route path={routes.PUBLIC.TOS_PAGE} element={<TermsOfService />} />
				<Route
					path={routes.PUBLIC.PRIVACY_POLICY}
					element={<PrivacyPolicy />}
				/>

				<Route path={routes.PUBLIC.CONTACT_US} element={<Contactanos />} />
				{/* PRIVATE PAGES */}
				<Route path={routes.APP.POST_TEST.THANK_YOU} element={<ThankYou />} />
				{/* // https://youtu.be/Ul3y1LXxzdU?t=1008 */}
				<Route path={routes.CHECKOUT.CHECKOUT} element={<Checkout />} />

				<Route path={routes.PRODUCT.ENURM} element={<Enurm />} />
				<Route path={routes.PRODUCT.CMF} element={<CMF />} />
				<Route path={routes.PRODUCT.PREPDERM} element={<PrepDerm />} />

				{/* 404 Page
				<Route path="*" element={404Page} /> */}
			</Routes>

			<PrivateRoutes />
			{!isPrivateRoute && <Footer />}
		</div>
	);
}

export default PrepMed;
