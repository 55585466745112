import { useState } from 'react';
import Modal from '../../../../components/modals/Modal';
import type { Question as IQuestion } from '../../../../data/interface-question';
import EditCard from './EditCard';
import InputCheckbox from '../../../../components/forms/InputCheckbox';

interface VEImageProps {
	question: IQuestion;
	onUpdate: (
		questionID: string,
		key: string,
		newValue: any
	) => Promise<IQuestion | null>;
}

const ViewEditImageDisplay = ({ question, onUpdate }: VEImageProps) => {
	const [edit, setEdit] = useState(false);
	const [showImageInQuestion, setShowImageInQuestion] = useState<boolean>(
		question.showImageInQuestion ?? false
	);

	const handleUpdate = () => {
		onUpdate(question.id, 'showImageInQuestion', showImageInQuestion)
			.then(() => setEdit(false))
			.catch((error) => console.error('Error updating question:', error));
	};

	if (
		question.imageFileName === undefined ||
		question.imageFileName.trim() === ''
	) {
		return null;
	}
	return (
		<>
			<EditCard label='Image Location' onEdit={setEdit}>
				La imagen es mostrada en la{' '}
				<b>
					{question.showImageInQuestion === true ? 'Pregunta' : 'Explicación'}
				</b>
			</EditCard>

			<Modal
				actionBtnText='Actualizar'
				handleAction={handleUpdate}
				headline='Image Location'
				isOpen={edit}
				onClose={() => setEdit(false)}
				showCloseBtn={true}>
				<InputCheckbox
					id='showImageInQuestion'
					label='Mostrar imagen en la pregunta'
					labelAdditionlInfo='(vs la explicación)'
					isChecked={showImageInQuestion}
					tooltip='Si la pregunta tiene una imagen, selecciona esta opción para mostrarla en la pregunta. De lo contrario la imagen sera mostrada en la explicación.'
					handleChange={(e) => setShowImageInQuestion(e.target.checked)}
				/>
			</Modal>
		</>
	);
};
export default ViewEditImageDisplay;
