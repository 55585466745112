interface BadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
	backgroundColor?: string;
	ringColor?: string;
	textColor?: string;
	title: string;
}

/**
 * Required prop: title | Optional props: backgroundColor, ringColor, textColor
 */
const Badge = ({
	backgroundColor = 'bg-gray-50',
	ringColor = 'ring-gray-500/10',
	textColor = 'text-gray-500',
	title
}: BadgeProps) => {
	return (
		<span
			className={`inline-flex items-center rounded-md ${backgroundColor} px-2 py-1 ${textColor} text-xs font-medium ring-1 ring-inset ${ringColor}`}>
			{title}
		</span>
	);
};
export default Badge;
