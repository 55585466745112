import { Link } from 'react-router-dom';
import useUserContext from '../../../api/UserContext';
import routes from '../../../config/routes';
import useHideElementOnMount from '../../../hooks/useHideElementOnMount';

const ThankYou = () => {
	const { currentUser } = useUserContext();
	useHideElementOnMount('header');
	useHideElementOnMount('footer');
	return (
		<div className='bg-brand-25'>
			<div className='px-6 py-24 sm:px-6 sm:py-32 lg:px-8'>
				<div className='mx-auto max-w-2xl text-center'>
					<h2 className='text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl'>
						¡Gracias!
					</h2>
					<div className='text-pretty text-lg/8 text-gray-600'>
						<p className='mx-auto mt-6 max-w-xl '>
							Ya la temporada{' '}
							<b>
								{currentUser?.examTarget?.toUpperCase()}{' '}
								{currentUser?.examTargetYear}
							</b>{' '}
							terminó, y con esto, tu acceso a la plataforma ha sido
							desactivado.
						</p>
						<p>
							Queremos aprovechar la oportunidad para agradecerte por formar
							parte de nuestro grupo y confiar en nosotros para tu preparación.
						</p>
						<p>
							Te deseamos la mejor de las suertes el resto del camino y recuerda
							que estamos aquí para cualquier duda o pregunta que tengas.
						</p>

						<hr className='w-3/4 mx-auto my-8' />
						<div className='px-2 py-4 bg-brand-100 rounded-lg'>
							<p>
								En caso de que estés considerando tomar el{' '}
								<b>
									{currentUser?.examTarget?.toUpperCase()}{' '}
									{(currentUser?.examTargetYear ?? 0) + 1}
								</b>{' '}
								e intentar conseguir mejor nota para concursar, tenemos una
								oferta exclusiva para ti. <br />
								Visita el enlace debajo para más información:
							</p>
							<div className='mt-6 flex items-center justify-center gap-x-6'>
								<Link
									to={routes.CHECKOUT.CHECKOUT}
									className='rounded-md bg-brand-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600'>
									Oferta Exclusiva
								</Link>
							</div>
						</div>

						<hr className='w-3/4 mx-auto my-8' />
						<p>
							Si tienes alguna sugerencia o comentario de mejora, por favor no
							dudes en hacérnoslo saber. Solo escríbenos a nuestro{' '}
							<a
								href='mailto:ayuda@prepmed.org?subject=Sugerencias y comentarios'
								className='hover:text-brand-600 hover:underline'>
								correo de soporte
							</a>
							.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ThankYou;
