import { useEffect, useState } from 'react';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import QuestionsManagerContainer from '../QuestionsManagerContainer';
import type { ExamLabel, Question } from '../../../../data/interface-question';
import ViewEditQuestion from './ViewEditQuestion';
import { useParams } from 'react-router-dom';
import ViewEditAnswer from './ViewEditAnswer';
import ViewEditExplanation from './ViewEditExplanation';
import ViewEditFuente from './ViewEditFuente';
import ViewEditImage from './ViewEditImage';
import ViewEditImageDisplay from './ViewEditImageDisplay';
import ViewEditIssues from './ViewEditIssues';
import ViewEditTags from './ViewEditTags';

const QuestionEditor = () => {
	const { questions, updateQuestionbyKey } = useQuestionBankContext();
	const [questionObj, setQuestionObj] = useState<Question>();
	const [source, setSource] = useState<ExamLabel>();
	const [categoryID, setCategoryID] = useState<string>();
	const [showSubCategory, setShowSubCategory] = useState<boolean>(false);
	const [subCategory, setSubCategory] = useState<string>();
	const [question, setQuestion] = useState<string>();
	const [answer, setAnswer] = useState<string>();
	const [option1, setOption1] = useState<string>();
	const [option2, setOption2] = useState<string>();
	const [option3, setOption3] = useState<string>();
	const [explanation, setExplanation] = useState<string>();
	const [editExplanation, setEditExplanation] = useState<boolean>(false);
	const [fuentes, setFuentes] = useState<string>();
	const [showImageInQuestion, setShowImageInQuestion] =
		useState<boolean>(false);
	const [tags, setTags] = useState<string[]>([]);
	const [audioPath, setAudioPath] = useState<string>();
	const [imagePath, setImagePath] = useState<string>();
	// Get question ID from URL (if any) and set the question state
	const params = useParams();
	const questionId = params.id;

	useEffect(() => {
		if (questionId !== undefined) {
			const questionFound = questions?.find((q) => q.id === questionId);
			if (questionFound !== undefined) {
				setQuestionObj(questionFound);
			}
		}
	}, [questions]);

	// DEVELOPMENT STEPS
	// 2. Select label
	// 3. Select category
	// 4. Select subcategory
	// 5. Select specialization
	// 6. Add question
	// 7. Add answer/options
	// 8. Add explanation
	// 9. Add fuentes
	// 10. Add tags
	// 11. Add issues

	if (questionObj === undefined) return null;
	return (
		<QuestionsManagerContainer title='Question Editor' bgColor='bg-brand-50'>
			{/* <div className='bg-red-400 text-white rounded py-4 px-2'>
				THIS IS NOT FINISHED AND MAY CAUSE ISSUES. PLEASE DO NOT USE IT.
			</div> */}
			<div className='mt-4'>
				<ViewEditQuestion
					question={questionObj}
					onUpdate={updateQuestionbyKey}
				/>
				<ViewEditAnswer
					question={questionObj}
					option='answer'
					onUpdate={updateQuestionbyKey}
				/>
				<ViewEditAnswer
					question={questionObj}
					option='option1'
					onUpdate={updateQuestionbyKey}
				/>
				<ViewEditAnswer
					question={questionObj}
					option='option2'
					onUpdate={updateQuestionbyKey}
				/>
				<ViewEditAnswer
					question={questionObj}
					option='option3'
					onUpdate={updateQuestionbyKey}
				/>
				<ViewEditExplanation
					question={questionObj}
					onUpdate={updateQuestionbyKey}
				/>
				<ViewEditFuente question={questionObj} onUpdate={updateQuestionbyKey} />
				<ViewEditImage question={questionObj} onUpdate={updateQuestionbyKey} />
				<ViewEditImageDisplay
					question={questionObj}
					onUpdate={updateQuestionbyKey}
				/>
				<ViewEditTags question={questionObj} onUpdate={updateQuestionbyKey} />
				<ViewEditIssues question={questionObj} onUpdate={updateQuestionbyKey} />
			</div>
		</QuestionsManagerContainer>
	);
};
export default QuestionEditor;
