import './editor.scss';
interface EditCardProps {
	label: string;
	children?: React.ReactNode; // best, accepts everything React can render
	onEdit: (value: boolean) => void; // function to handle edit action
}

const EditCard = ({ children, label, onEdit }: EditCardProps) => {
	return (
		<div className='overflow-hidden rounded-lg bg-white shadow mb-2'>
			<div className='px-4 py-5 sm:p-4'>
				<div className='flex justify-between items-start'>
					<label className='text-xs font-medium text-gray-400 uppercase'>
						{label}
					</label>
					<button
						className='rounded bg-brand-500 p-1 text-white shadow-sm hover:bg-brand-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500 ml-5 px-4'
						type='submit'
						tabIndex={1}
						onClick={() => onEdit(true)}>
						Edit
					</button>
				</div>
				<div className='txt-sm no-mb'>{children}</div>
			</div>
		</div>
	);
};
export default EditCard;
