interface QuestionSearchProps {
	handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
	resetQuestions: () => void;
	searchBy: 'question' | 'explanation';
	setSearchBy: (value: 'question' | 'explanation') => void;
	showFilter: boolean;
	setShowFilter: (value: boolean) => void;
	setShowNoResultsMSG: (value: boolean) => void;
}

const QuestionSearch = ({
	handleSearch,
	resetQuestions,
	searchBy,
	setSearchBy,
	showFilter,
	setShowFilter,
	setShowNoResultsMSG
}: QuestionSearchProps) => {
	return (
		<>
			<div className='flex justify-end w-100 mb-2'>
				<input
					id='search'
					name='search'
					type='text'
					onChange={(e) => handleSearch(e)}
					placeholder='Search by ID, category or question content...'
					className='col-start-1 row-start-1 block w-full rounded-md bg-white py-1.5 pl-3 pr-10 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-1 focus:-outline-offset-2 focus:outline-brand-400 sm:pr-9 sm:text-sm/6'
				/>
				<button
					type='button'
					title='Show/Hide Filter'
					onClick={() => {
						setShowFilter(!showFilter);
						setShowNoResultsMSG(false);
					}}
					className='rounded bg-gray-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-brand-400 ml-2'>
					<i className='material-icons'>filter_alt</i>
				</button>
				<button
					type='button'
					title='Reset Search'
					onClick={() => {
						resetQuestions();
						setShowNoResultsMSG(false);
					}}
					className='rounded bg-gray-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-brand-400 ml-2'>
					<i className='material-icons'>restart_alt</i>
				</button>
			</div>
			<div className=''>
				<label>
					<input
						type='checkbox'
						className='h-4 w-4 rounded border-gray-400 text-brand-600 focus:ring-brand-600'
						checked={searchBy !== 'question'}
						onChange={() =>
							searchBy === 'question'
								? setSearchBy('explanation')
								: setSearchBy('question')
						}
					/>
					<label className='ml-2 text-sm text-gray-600'>
						Search by &quot;explanation&quot; instead of &quot;question&quot;
					</label>
				</label>
			</div>
		</>
	);
};
export default QuestionSearch;
