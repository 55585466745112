const TODAY = Date.now();
const ONE_DAY_IN_SECONDS = 86400;
const THIRTY_DAYS = ONE_DAY_IN_SECONDS * 30;

const getSecondsSinceLastPull = (lastPullDate: number) => {
	return (TODAY - lastPullDate) / 1000;
};
export const isPullExpired = (isAdmin: boolean): boolean => {
	const localDate: string | null = localStorage.getItem(
		'prepmed-last-questions-pull-date'
	);
	const lastPullDate: number | null =
		localDate !== null ? Number(JSON.parse(localDate)) : null;

	if (lastPullDate !== null) {
		const secondsSinceLastPull = getSecondsSinceLastPull(lastPullDate);
		if (isAdmin) {
			// Admins can pull data every day
			return secondsSinceLastPull > ONE_DAY_IN_SECONDS;
		} else {
			return secondsSinceLastPull > THIRTY_DAYS;
		}
	} else {
		return false;
	}
};
