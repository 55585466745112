import { useState } from 'react';
import Modal from '../../../../components/modals/Modal';
import type { Question as IQuestion } from '../../../../data/interface-question';
import Question from '../../../private/examenes/test-review/Question';
import DescriptionTextArea from '../../../../components/forms/DescriptionTextAreaQuill';
import EditCard from './EditCard';

interface QuestionProps {
	question: IQuestion;
	onUpdate: (
		questionID: string,
		key: string,
		newValue: any
	) => Promise<IQuestion | null>;
}

const ViewEditQuestion = ({ question, onUpdate }: QuestionProps) => {
	const [edit, setEdit] = useState(false);
	const [newQuestion, setNewQuestion] = useState<string>(question.question);

	const handleUpdate = () => {
		onUpdate(question.id, 'question', newQuestion)
			.then(() => setEdit(false))
			.catch((error) => console.error('Error updating question:', error));
	};

	return (
		<>
			<EditCard label='Pregunta' onEdit={setEdit}>
				<Question
					question={newQuestion}
					imagePathName={question.imageFileName}
					showImageInQuestion={question.showImageInQuestion ?? false}
				/>
			</EditCard>

			<Modal
				actionBtnText='Actualizar'
				handleAction={handleUpdate}
				headline='Actualiza la Pregunta'
				isOpen={edit}
				onClose={() => setEdit(false)}
				showCloseBtn={true}>
				<DescriptionTextArea
					label='Actualiza la Pregunta'
					id='question'
					infoMessage='Escribe la pregunta que el usuario debe ver...'
					parentClass='flex justify-between items-center w-full mb-2'
					placeholder='Escribe la pregunta aquí...'
					required={true}
					handleChange={(value: string) =>
						setNewQuestion(value.replace(/(<p><br><\/p>)/g, ''))
					}
					value={newQuestion}
					isFormSubmitted={false}
				/>
			</Modal>
		</>
	);
};
export default ViewEditQuestion;
