import Modal from '../../../../components/modals/Modal';

interface EditModalProps {
	children?: React.ReactNode;
	onClose: () => void;
	onUpdate: () => void;
	showModal: boolean;
	title: string;
}
const EditModal = ({
	children,
	onClose,
	onUpdate,
	showModal,
	title
}: EditModalProps) => {
	return (
		<Modal
			actionBtnText='Actualizar'
			handleAction={onUpdate}
			headline={title}
			isOpen={showModal}
			onClose={onClose}
			showCloseBtn={true}>
			{children}
		</Modal>
	);
};
export default EditModal;
