import { createContext, useContext, useState } from 'react';
import type { ReactNode } from 'react';

import { db, handleEventLog } from '../gcp/config';

import type { FirebaseError } from 'firebase/app';
import type { QuestionBankID } from '../data/interface-question-bank';

import useUserContext from './UserContext';
import { useLoadingContext } from '../context/LoadingContext';
import { useNavigate } from 'react-router-dom';
import type { User } from '../data/interface-user';

import {
	collection,
	// or,
	doc,
	getDoc,
	getDocs,
	addDoc,
	setDoc,
	updateDoc,
	query,
	where
} from 'firebase/firestore';
import {
	buildQuestionObject,
	buildTestRecordObject,
	buildUserObject
} from './xBuildObjectUtils';
import type {
	CreateQuestionData,
	Question,
	TestRecord
} from '../data/interface-question';
import { isPullExpired } from '../data/utils';

import type { IDBPDatabase } from 'idb';
import {
	createObjectStore,
	deleteDB,
	deleteValue,
	getAllValues,
	putBulkValues,
	putValue
} from '../data/indexedDBHelper';
import useQuestionBankContext from './QuestionBankContext';

export type AdminContextType = {
	getUserList: (
		productID: QuestionBankID,
		targetYear: number
	) => Promise<User[]>;
	users: User[] | undefined;
	setUsers: (users: User[]) => void;
	// GET QUESTIONS
	createQuestion: (
		question: CreateQuestionData
	) => Promise<Question[] | FirebaseError | null>;
	adminQuestions: Question[] | undefined;
	setAdminQuestions: (v: Question[] | undefined) => void;
	getQuestionsForAdmin: (id: QuestionBankID) => Promise<Question[]>;
	pruebasDiagnosticas: TestRecord[];
	getRecordsPruebasDiagnosticas: () => Promise<TestRecord[]>;
};
export const AdminContext = createContext<AdminContextType | null>(null);
export const AdminContextProvider = ({ children }: { children: ReactNode }) => {
	const navigateTo = useNavigate();
	const { getQuestion } = useQuestionBankContext();
	const { getProductByID, currentUser, setCurrentUser, createUserInFirestore } =
		useUserContext();
	const [adminQuestions, setAdminQuestions] = useState<
		Question[] | undefined
	>();
	const [pruebasDiagnosticas, setPruebasDiagnosticas] = useState<TestRecord[]>(
		[]
	);

	const validateAdmin = () => {
		if (currentUser === null) return false;
		// TODO: Redirect to login page / HANDLE THIS CASE HERE
		// navigateTo('/login');
		return currentUser.isAdmin ?? false;
	};

	// #region IDBP HELPERS
	/**
	 * Opens an IndexedDB connection
	 */

	const openDBConnection = async (
		dbName = 'prepmed-questions',
		tableNamesArray = ['questions']
	) => {
		const getDB: IDBPDatabase<unknown> | undefined = await createObjectStore(
			dbName,
			200,
			tableNamesArray
		);

		return getDB;
	};
	// #endregion

	// #region USERS
	const [users, setUsers] = useState<User[]>();
	const getUserList = async (productID: QuestionBankID, targetYear: number) => {
		const isAdmin = validateAdmin();
		if (!isAdmin) return [];
		// setLoading(true);
		// setLoadingMessage('Fetching user list...');
		if (users !== undefined && users?.length > 0) return users;
		const userList = await getUsersFromFirestore(productID, targetYear);
		// setLoading(false);
		return userList;
	};

	const getUsersFromFirestore = async (
		productID: QuestionBankID,
		targetYear: number
	): Promise<User[]> => {
		const userList: User[] = [];
		const usersCollection = 'users';

		const recordsRef = collection(db, usersCollection);
		const q = query(
			recordsRef,
			where('examTarget', '==', productID),
			where('examTargetYear', '==', targetYear)
		);

		const querySnapshot = await getDocs(q);
		querySnapshot?.forEach((record) => {
			const r = buildUserObject(record.id, record.data());
			if (r !== null) userList.push(r);
		});

		setUsers(userList);
		return userList;
	};
	// #endregion

	// #region QUESTIONS
	const getQuestionsForAdmin = async (bankID: QuestionBankID) => {
		let data: Question[] = [];
		if (currentUser === null) return data;
		const isAdmin = validateAdmin();
		if (!isAdmin) return [];
		const clearLocalStorage = async () => {
			if (isPullExpired(isAdmin)) {
				localStorage.removeItem('prepmed-last-questions-pull-date');
				await deleteDB('prepmed-questions');
			}
		};
		await clearLocalStorage();

		// GET QUESTIONS FROM IDB
		const thisDB = await openDBConnection();
		if (thisDB !== undefined) {
			data = await getAllValues(thisDB, 'questions');
		}

		const hasLocalData = data.length > 0;

		if (!hasLocalData) {
			if (adminQuestions !== undefined && adminQuestions.length > 0) {
				// GET QUESTIONS FROM USESTATE
				setAdminQuestions(adminQuestions);
				data = adminQuestions;
			} else {
				// GET QUESTIONS FROM FIRESTORE
				data = await getQuestionsFromFirestore(bankID);
			}
		}

		if (
			hasLocalData ||
			(adminQuestions !== undefined && adminQuestions.length > 0)
		) {
			const r = data.find((q) => q.bank !== bankID);
			const isFromBank = r === undefined;
			if (!isFromBank) {
				data = await getQuestionsFromFirestore(bankID);
			}
		}

		setAdminQuestions(data);
		if (thisDB !== undefined && data.length > 0) {
			await putBulkValues(thisDB, 'questions', data);
		} else {
			// TODO: Handle no DB found
			console.error('No local data found');
		}
		return data;
	};

	const getQuestionsFromFirestore = async (
		id: QuestionBankID
	): Promise<Question[]> => {
		const questionList: Question[] = [];
		const questionsCollection = 'questions';

		const recordsRef = collection(db, questionsCollection);
		const q = query(recordsRef, where('bank', '==', id));

		const querySnapshot = await getDocs(q);
		querySnapshot?.forEach((record) => {
			const r = buildQuestionObject(record.id, record.data());
			questionList.push(r as Question);
		});

		setAdminQuestions(questionList);
		return questionList;
	};

	const createQuestion = async (
		question: CreateQuestionData
	): Promise<Question[] | FirebaseError | null> => {
		const isAdmin = validateAdmin();
		if (!isAdmin) return null;
		const questionsCollection = 'questions';
		const recordsRef = collection(db, questionsCollection);

		const fullQuestion = {
			...question,
			createdOn: new Date(),
			authorID: currentUser?.id,
			authorFirstName: currentUser?.firstName,
			authorLastName: currentUser?.lastName
		};

		const result = addDoc(recordsRef, question)
			.then(async (docRef) => {
				console.info('Question created with ID:', docRef.id);
				const questions: Question[] = [];
				const q = await getQuestion(docRef.id);
				if (q !== null) {
					questions.push(q);
				}

				if (adminQuestions !== undefined) {
					questions.push(...adminQuestions);
				}
				setAdminQuestions(questions);
				return questions;
			})
			.catch((error: FirebaseError) => {
				// TODO: Handle error
				console.error('Error creating question:', error);
				return error;
			});
		return await result;
	};

	// #endregion

	// #region REPORTS
	const getRecordsPruebasDiagnosticas = async (): Promise<TestRecord[]> => {
		const records: TestRecord[] = [];
		const recordsCollection = 'testRecords';

		const recordsRef = collection(db, recordsCollection);
		const q = query(recordsRef, where('testLabel', '==', 'evaluacion-1'));
		const q2 = query(recordsRef, where('testLabel', '==', 'evaluacion-2'));

		getDocs(q)
			.then((querySnapshot) => {
				querySnapshot?.forEach((record) => {
					const data = buildTestRecordObject(record.id, record.data());
					if (data !== null) {
						setPruebasDiagnosticas((prev) => [...prev, data]);
						records.push();
					}
				});

				getDocs(q2)
					.then((querySnapshot2) => {
						querySnapshot2?.forEach((record) => {
							const data = buildTestRecordObject(record.id, record.data());
							if (data !== null) {
								setPruebasDiagnosticas((prev) => [...prev, data]);
								records.push();
							}
						});

						// setPruebasDiagnosticas(records);
					})
					.catch((error: FirebaseError) => {
						console.info(error);
						// handleEventLog({
						// error: error,
						// message: 'Error fetching records for Pruebas Diagnosticas'
						// });
					});
			})
			.catch((error: FirebaseError) => {
				console.info(error);
				// handleEventLog({
				// error: error,
				// message: 'Error fetching records for Pruebas Diagnosticas'
				// });
			});

		return records;
	};
	// #endregion

	return (
		<AdminContext.Provider
			value={{
				getUserList,
				users,
				setUsers,
				createQuestion,
				adminQuestions,
				setAdminQuestions,
				getQuestionsForAdmin,
				pruebasDiagnosticas,
				getRecordsPruebasDiagnosticas
			}}>
			{children}
		</AdminContext.Provider>
	);
};
export const useAdminContext = () => {
	const context = useContext(AdminContext);
	if (context === undefined || context === null) {
		throw new Error(
			'useAdminContext must be used within a AdminContextProvider'
		);
	}
	return context;
};
export default AdminContextProvider;
