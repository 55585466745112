import { useState } from 'react';
import type { Question as IQuestion } from '../../../../data/interface-question';
import DescriptionTextArea from '../../../../components/forms/DescriptionTextAreaQuill';
import EditCard from './EditCard';
import EditModal from './EditModal';
import TextInputForm from '../../../../components/forms/TextInputForm';

interface AnswerProps {
	question: IQuestion;
	option: 'answer' | 'option1' | 'option2' | 'option3';
	onUpdate: (
		questionID: string,
		key: string,
		newValue: any
	) => Promise<IQuestion | null>;
}

const ViewEditAnswer = ({ question, option, onUpdate }: AnswerProps) => {
	const [edit, setEdit] = useState(false);
	const [value, setNewValue] = useState<string>(question[option]);
	const handleUpdate = () => {
		onUpdate(question.id, option, value)
			.then(() => setEdit(false))
			.catch((error) => console.error('Error updating question:', error));
	};

	return (
		<>
			<EditCard
				label={`${
					option === 'answer'
						? 'Respuesta correcta'
						: `Opción ${option.slice(-1)}`
				}`}
				onEdit={setEdit}>
				{value}
			</EditCard>
			<EditModal
				onClose={() => setEdit(false)}
				onUpdate={handleUpdate}
				showModal={edit}
				title={`Actualiza la ${option === 'answer' ? 'Respuesta' : 'Opción'}`}>
				<TextInputForm
					id={option}
					label={`Actualiza la ${
						option === 'answer'
							? 'respuesta correcta'
							: `opción ${option.slice(-1)}`
					}`}
					placeholder='¿Cuál es la respuesta correcta?'
					handleChange={(e: string) => setNewValue(e)}
					value={value}
				/>
			</EditModal>
		</>
	);
};
export default ViewEditAnswer;
