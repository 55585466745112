import { useState } from 'react';
import EmailInput from '../../components/forms/EmailInput';
import FormContainer from '../../components/forms/FormContainer';
import FormLabel from '../../components/forms/FormLabel';
import MainTag from '../../layout/Main';
import Button from '../../components/ui/Button';
import { useLoadingContext } from '../../context/LoadingContext';
import Headline from '../../components/ui/Headline';
import ErrorMessage from '../../components/forms/ErrorMessage';
import Card from '../../components/ui/Card';
// import { Link } from 'react-router-dom';
import routes from '../../config/routes';
import { redirectTo } from '../../utils/helpers';
import { useAuthContext } from '../../api/AuthContext';

const NuevoPassword = () => {
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { sendPasswordReset } = useAuthContext();
	// EMAIL
	const [email, setEmail] = useState<string>('');
	const [isValidEmail, setEmailValidity] = useState<boolean>(false);
	// ERROR
	const [error, setError] = useState<string>('');

	const handleSubmit = (
		e:
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
			| React.FormEvent<HTMLFormElement>
	) => {
		e.preventDefault();
		setLoading(true);
		setLoadingMessage('Verificando el correo electrónico ingresado...');
		handleSignup()
			.then((result) => {
				setLoading(false);
			})
			.catch((err) => console.error('Error sending email:', err));
	};

	const handleSignup = async () => {
		const result = await sendPasswordReset(email);
		if (result === 'success') {
			setError('success');
		} else {
			setError(result);
		}
		return result;
	};

	if (error === 'success') {
		return (
			<MainTag>
				<div className='my-12'>
					<Card>
						<Headline isCentered={true} title='¡Correo Enviado!' type='h6' />
						<p>
							Te enviamos un correo con un enlace para que puedas reestablecer
							tu contraseña y acceder a tu cuenta.
						</p>

						<div className='flex justify-center mt-6'>
							{/* <Link to={routes.AUTHENTICATION.LOGIN}>
							Volver al Inicio de Sesión
						</Link> */}
							<Button
								bgColor='bg-brand-500'
								icon='send'
								iconPosition='right'
								hoverBgColor='hover:bg-brand-550'
								title='Reenviar Correo'
								handleClick={() => redirectTo(routes.AUTHENTICATION.LOGIN)}
							/>
						</div>
					</Card>
				</div>
			</MainTag>
		);
	}

	return (
		<MainTag>
			<div className='my-14'>
				<FormContainer handleSubmit={(e) => handleSubmit(e)}>
					<Headline
						isCentered={true}
						title='¿Olvidaste tu contraseña?'
						type='h6'
					/>
					<p>
						Ingresa tu correo electrónico y te enviaremos un enlace para
						restablecer tu contraseña.
					</p>
					<div className='mb-4'>
						<FormLabel label='Correo Electrónico' />
						<EmailInput
							id='email'
							placeholder='¿Cuál es el correo asociado a tu cuenta?'
							handleChange={(e: string) => setEmail(e)}
							handleValidity={(v: boolean) => setEmailValidity(v)}
						/>
						<ErrorMessage
							classes='mt-4'
							error={error}
							showError={error !== ''}
						/>
					</div>
					{isValidEmail && (
						<div className='flex items-center justify-end'>
							<Button
								bgColor='bg-brand-500'
								icon='send'
								iconPosition='right'
								hoverBgColor='hover:bg-brand-550'
								title='Continuar'
								handleClick={(e) => handleSubmit(e)}
							/>
						</div>
					)}
				</FormContainer>
			</div>
		</MainTag>
	);
};
export default NuevoPassword;
