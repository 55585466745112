import { useEffect, useState } from 'react';

// COMPONENTS
import Logo from '../../../components/Logo';
import SignupSteps from './SignupSteps';

// HOOKS
import useHideElementOnMount from '../../../hooks/useHideElementOnMount';
import StepsWithDots from '../../../components/steps/StepsWithDots';

// INTERFACES
import type {
	SignupStep,
	SignupStepsIDs
} from '../../../data/interface-signup-steps';
import debug from '../../../utils/useDebugger';
import MainTag from '../../../layout/Main';
import SeasonIsClosed from './SeasonIsClosed';

const Signup = () => {
	useHideElementOnMount('header');
	useHideElementOnMount('footer');
	const [currentStep, setNextStep] = useState<SignupStepsIDs>();
	const [stepsCompleted, setStepsCompleted] = useState<SignupStepsIDs[]>([]);
	const [steps, setSteps] = useState<SignupStep[]>([
		{
			id: 'crea-tu-cuenta',
			name: 'Crea tu Cuenta',
			description: 'Configura tus credenciales',
			status: 'upcoming'
		},
		{
			id: 'cuenta-creada',
			name: 'Cuenta Creada',
			description: '',
			status: 'upcoming'
		},
		{
			id: 'detalles-personales',
			name: 'Detalles Personales',
			description: '',
			status: 'upcoming'
		},
		{
			id: 'elige-tu-examen',
			name: 'Elige tu Examen',
			description: '',
			status: 'upcoming'
		},
		{
			id: 'informacion-adicional',
			name: 'Información Adicional',
			description: '',
			status: 'upcoming'
		},
		{
			id: 'completa-tu-pago',
			name: 'Completa tu Pago',
			description: '',
			status: 'upcoming'
		}
	]);

	useEffect(() => {
		if (currentStep === null || currentStep === undefined) {
			handleNextStep('crea-tu-cuenta');
		}
	}, [currentStep]);

	const handleNextStep = (nextStep: SignupStepsIDs) => {
		if (nextStep === null || nextStep === undefined) {
			setNextStep(nextStep);
		} else {
			steps.forEach((step) => {
				const currentPassed =
					(step?.id === currentStep && nextStep !== currentStep) ||
					stepsCompleted.includes(step?.id);

				if (currentPassed) {
					step.status = 'complete';
					setStepsCompleted([...stepsCompleted, step.id]);
				} else if (step.id === nextStep) {
					step.status = 'current';
					setNextStep(nextStep);
				} else if (step.status !== 'complete') {
					step.status = 'upcoming';
				} else {
					step.status = 'upcoming';
				}
			});

			setSteps([...steps]);
		}
	};

	return (
		<div className='bg-brand-25 min-h-full'>
			<div className='px-8 pt-10 bg-brand-25 w-full '>
				<Logo useDarkLogo={true} classes='h-10' />
			</div>

			<MainTag>
				<div className='flex flex-col items-center mb-4 mt-10'>
					<StepsWithDots steps={steps} />
					<SignupSteps
						currentStep={currentStep}
						nextStep={(x: SignupStepsIDs) => handleNextStep(x)}
					/>
				</div>
			</MainTag>
		</div>
	);
};
export default Signup;

// EMAIL VERIFICATION:
// https://stackoverflow.com/questions/37431128/firebase-confirmation-email-not-being-sent
