import useQuestionBankContext from '../../../../api/QuestionBankContext';
import DescriptionTextArea from '../../../../components/forms/DescriptionTextAreaQuill';
import TextInputForm from '../../../../components/forms/TextInputForm';
import InputCheckbox from '../../../../components/forms/InputCheckbox';
import UploadFile from '../../utils/UploadFile';
import Headline from '../../../../components/ui/Headline';
import SelectElement from '../../../../components/forms/SelectElement';
import { testLabelOptions } from '../../../../data/option-objects/examOptions';
import SubmitChanges from './SubmitChanges';
import type {
	ExamLabel,
	SubCategory
} from '../../../../data/interface-question';
import type { SyntheticEvent } from 'react';
import type { QuestionBankID } from '../../../../data/interface-question-bank';
import type { OptionObjMustHaves } from '../../../../data/interface-utils';

interface QuestionBuilderFormProps {
	isFormValid: boolean;
	isFormSubmitted: boolean;
	handleSubmit: (e: SyntheticEvent) => void;
	clearForm: () => void;
	handleCategoryChange: (v: string) => void;
	handleTags: (e: React.ChangeEvent<HTMLInputElement>) => void;
	questionBankID: QuestionBankID;
	question: string | undefined;
	setQuestion: (v: string) => void;
	source: ExamLabel | undefined;
	setSource: (v: ExamLabel) => void;
	categoryID: string | undefined;
	showSubCategory: boolean;
	setSubCategory: (v: string) => void;
	setAnswer: (v: string) => void;
	setOption1: (v: string) => void;
	setOption2: (v: string) => void;
	setOption3: (v: string) => void;
	explanation: string | undefined;
	setExplanation: (v: string) => void;
	fuentes: string | undefined;
	setFuentes: (v: string) => void;
	showImageInQuestion: boolean;
	setShowImageInQuestion: (v: boolean) => void;
	imagePath: string | undefined;
	setImagePath: (v: string) => void;
	audioPath: string | undefined;
	setAudioPath: (v: string) => void;
	tags: string[];
}

const QuestionBuilderForm = ({
	isFormValid,
	isFormSubmitted,
	handleSubmit,
	clearForm,
	handleCategoryChange,
	handleTags,
	questionBankID,
	question,
	setQuestion,
	source,
	setSource,
	categoryID,
	showSubCategory,
	setSubCategory,
	setAnswer,
	setOption1,
	setOption2,
	setOption3,
	explanation,
	setExplanation,
	fuentes,
	setFuentes,
	showImageInQuestion,
	setShowImageInQuestion,
	imagePath,
	setImagePath,
	audioPath,
	setAudioPath,
	tags
}: QuestionBuilderFormProps) => {
	const { categories, getCategories } = useQuestionBankContext();
	// #region CATEGORIES
	const loadCategories = (): OptionObjMustHaves[] => {
		const cats: OptionObjMustHaves[] = [];
		categories
			?.filter((d) => d.questionBank === questionBankID)
			.map((cat) => {
				return cats.push({ id: cat.id, value: cat.name });
			});
		return cats;
	};

	const loadSubCategories = (): OptionObjMustHaves[] => {
		const sCO: OptionObjMustHaves[] = [];
		const subCategories = categories?.find(
			(c) => c.id === categoryID
		)?.subCategoryList;
		if (subCategories !== undefined) {
			subCategories.map((sc: SubCategory) => {
				return sCO.push({
					id: sc.name.toLowerCase(),
					value: sc.name
				});
			});
		}
		return sCO;
	};
	// #endregion
	return (
		<form onSubmit={handleSubmit}>
			<SelectElement
				defaultOption={`- Elige Uno: ${questionBankID} o prepmed -`}
				id='source'
				icon='ballot'
				iconFam='material-icons'
				label='Elige un tipo de examen'
				options={
					questionBankID === 'enurm'
						? testLabelOptions.enurmAdmin
						: testLabelOptions.maxilofacialAdmin
				}
				value={source}
				handleChange={(v) => {
					setSource(v as ExamLabel);
				}}
			/>
			<SelectElement
				defaultOption='Elige una categoría'
				id='categoryID'
				icon='ballot'
				iconFam='material-icons'
				label='Elige una categoría'
				options={loadCategories()}
				value={categoryID}
				handleChange={(v) => {
					handleCategoryChange(v);
				}}
			/>
			{showSubCategory && (
				<SelectElement
					isDefaultOptionDisabled={false}
					defaultOption='Elige una subcategoría'
					id='subCategoryList'
					icon='ballot'
					iconFam='material-icons'
					label='Elige una subcategoría'
					options={loadSubCategories()}
					value={''}
					handleChange={(v) => {
						setSubCategory(v);
					}}
				/>
			)}
			{/* // TODO: Add 'SELECT A SPECIALIZATION' */}
			<DescriptionTextArea
				label='Agrega la Pregunta'
				id='question'
				infoMessage='Escribe la pregunta que el usuario debe ver...'
				parentClass='flex justify-between items-center w-full mb-2'
				placeholder='Escribe la pregunta aquí...'
				required={true}
				handleChange={(value: string) =>
					setQuestion(value.replace(/(<p><br><\/p>)/g, ''))
				}
				value={question}
				isFormSubmitted={isFormSubmitted}
			/>
			<TextInputForm
				id='answer'
				label='Respuesta correcta'
				placeholder='¿Cuál es la respuesta correcta?'
				handleChange={(e: string) => setAnswer(e)}
			/>
			<TextInputForm
				id='option1'
				label='Opción 1'
				placeholder='¿Cuál es la opción 1?'
				handleChange={(e: string) => setOption1(e)}
			/>
			<TextInputForm
				id='option2'
				label='Opción 2'
				placeholder='¿Cuál es la opción 2?'
				handleChange={(e: string) => setOption2(e)}
			/>
			<TextInputForm
				id='option3'
				label='Opción 3'
				placeholder='¿Cuál es la opción 3?'
				handleChange={(e: string) => setOption3(e)}
			/>
			<DescriptionTextArea
				label='Agrega la explicación'
				id='explanation'
				infoMessage='Escribe la explicación de la pregunta...'
				parentClass='flex justify-between items-center w-full mb-2'
				placeholder='Escribe la explicación aquí...'
				required={true}
				handleChange={(value: string) =>
					setExplanation(value.replace(/(<p><br><\/p>)/g, ''))
				}
				heightInPx='250'
				value={explanation}
				isFormSubmitted={isFormSubmitted}
			/>
			<DescriptionTextArea
				label='Agrega la(s) fuente(s)'
				id='fuente'
				infoMessage='Agrega la(s) fuente(s)la explicación de la pregunta...'
				parentClass='flex justify-between items-center w-full mb-2'
				placeholder='Escribe la(s) fuente(s) aquí...'
				required={true}
				handleChange={(value: string) =>
					setFuentes(value.replace(/(<p><br><\/p>)/g, ''))
				}
				heightInPx='80'
				value={fuentes}
				isFormSubmitted={isFormSubmitted}
			/>
			<InputCheckbox
				id='showImageInQuestion'
				label='Mostrar imagen en la pregunta'
				labelAdditionlInfo='(vs la explicación)'
				isChecked={showImageInQuestion}
				tooltip='Si la pregunta tiene una imagen, selecciona esta opción para mostrarla en la pregunta. De lo contrario la imagen sera mostrada en la explicación.'
				handleChange={(e) => setShowImageInQuestion(e.target.checked)}
			/>
			<UploadFile
				onUpload={(imgPath: string) => setImagePath(imgPath)}
				storageFolder='question-images'
				fileName={imagePath}
				fileType='image'
			/>
			<UploadFile
				onUpload={(path: string) => setAudioPath(path)}
				storageFolder='question-audio-files'
				fileName={audioPath}
				fileType='audio'
			/>
			{/* CATEGORY SELECTIONS */}
			<div className='my-2'>
				<Headline title='Tags' type='h6' />
				<div className='grid grid-cols-4 gap-1'>
					{categories?.map((c) => {
						return (
							<InputCheckbox
								key={c.id}
								id={c.id}
								label={c.name}
								isChecked={tags.includes(c.id)}
								parentClass='relative inline-flex items-start my-1 2xl:w-full w-1/2'
								handleChange={handleTags}
							/>
						);
					})}
				</div>
			</div>

			<SubmitChanges
				showButtons={isFormValid}
				cleanupState={clearForm}
				handleSubmit={(e: SyntheticEvent) => handleSubmit(e)}
				disabled={false}
			/>
		</form>
	);
};
export default QuestionBuilderForm;
