import { useEffect, useMemo, useState } from 'react';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import TestModeContainer from './TestModeContainer';
import TestTimerAndProgressContainer from './TestTimerAndProgressContainer';
import Modal from '../../../../components/modals/Modal';
import Question from './Question';
import Answers from './Answers';
import Explanation from './Explanation';
import type {
	Question as IQuestion,
	QuestionRecord,
	TestRecord
} from '../../../../data/interface-question';
import { useLoadingContext } from '../../../../context/LoadingContext';
import { buildTestRecordObject } from '../../../../api/xBuildObjectUtils';
import TestResults from './TestResults';
import { calcTestGrade } from '../../../../utils/helpers';
import { shuffle } from 'lodash';
import useUserContext from '../../../../api/UserContext';
import Swal from 'sweetalert2';
import useSideCannons from '../../../../hooks/useSideCannons';

const TestMode = () => {
	const { currentUser } = useUserContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const {
		testSelections,
		saveTestRecord,
		categories,
		deleteUnfinishedTest,
		updateUnfinishedTestOnNextQuestion
	} = useQuestionBankContext();
	const { showSideCannons } = useSideCannons();
	const isUnfinishedTest = testSelections?.isUnfinishedTest === true;
	const shuffledQuestions: IQuestion[] = useMemo(() => {
		return testSelections?.questions ?? [];
	}, [testSelections?.questions, testSelections?.testLength]);
	// const shuffledQuestions: IQuestion[] = useMemo(() => {
	// if (isUnfinishedTest) {
	// return testSelections?.questions ?? [];
	// } else {
	// return shuffle(
	// testSelections?.questions?.slice(0, testSelections.testLength)
	// );
	// }
	// }, [testSelections?.questions, testSelections?.testLength]);

	const TEST_START_TIME = new Date();
	// #region TIMER RELATED STATE
	const [isTimerStopped, setIsTimerStopped] = useState(false);
	const [timerStartTime, setTimerStartTime] = useState<number | null>(null);
	const [timeElapsedInSeconds, setTimeElapsedInSeconds] = useState(0);
	// #endregion

	// #region TEST RELATED STATE
	const [finalRecord, setFinalRecord] = useState<TestRecord>();
	const [correctAnswersSum, setCorrectAnswersSum] = useState<number>(0);
	const [QnALog, setQnALog] = useState<QuestionRecord[]>([]);
	const [testFinished, setTestFinished] = useState(false);
	// #endregion

	// #region QUESTION RELATED STATE
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(
		isUnfinishedTest
			? currentUser?.unfinishedTest?.currentQuestionIndex ?? 0
			: 0
	);
	const [selectedAnswer, setSelectedAnswer] = useState('');
	const [showExplanation, setShowExplanation] = useState(false);
	const [isAnswerSelected, setIsAnswerSelected] = useState(false);
	// #endregion

	// TODO: Remove this useEffect, it's just for testing
	// useEffect(() => {
	// // console.info(currentQuestionIndex);
	// const question = shuffledQuestions[currentQuestionIndex];
	// const categoryName = categories?.find(
	// (cat) => cat.id === question.categoryID
	// )?.name;
	// // console.info(categoryName);
	// }, [currentQuestionIndex]);

	useEffect(() => {
		if (finalRecord !== undefined) {
			setTestFinished(true);
		}
	}, [finalRecord]);

	if (testSelections === undefined || currentUser === null) return null;

	// #region TIMER RELATED FUNCTIONS
	const updateTimeElapsed = (passed: number) => {
		// dispatch({ type: UPDATE_TIMER, payload: passed });
		if (timerStartTime === null) return 0;
		const timePassed = Math.floor((passed - timerStartTime) / 1000);
		setTimeElapsedInSeconds(timePassed);
	};

	const stopTest = () => {
		updateTimeElapsed(Number(testSelections.testDuration));
		// alert('El tiempo límite para completar el examen ha terminado.');
		setIsAnswerSelected(false);
		setIsTimerStopped(true);
	};
	// #endregion

	/**
	 * This happens 1st, right as a user selects their answer
	 */
	const evaluateAnswerChoice = (userSelection: string) => {
		setLoadingMessage('Evaluando tu respuesta...');
		setLoading(true);
		setIsAnswerSelected(true);
		setSelectedAnswer(userSelection);

		addToQnALog(userSelection);

		// submitButton.current.focus();
	};

	const addToQnALog = (userSelection: string) => {
		const question = shuffledQuestions[currentQuestionIndex];
		const questionInLog = QnALog.find((log) => log.id === question.id);

		if (questionInLog !== undefined) {
			questionInLog.selectedAnswer = userSelection;
			questionInLog.selectTS = new Date();
			if (questionInLog.selectedAnswer === question.answer) {
				setCorrectAnswersSum((prevScore) => prevScore - 1);
			}
		} else {
			setQnALog((prevList) => [
				...prevList,
				{
					id: question.id,
					correctAnswer: question.answer,
					selectedAnswer: userSelection,
					selectTS: new Date()
				}
			]);
		}

		if (userSelection === question.answer) {
			setCorrectAnswersSum((prevScore) => prevScore + 1);
		}

		setLoading(false);
	};

	/**
	 * Show the next question
	 */
	const nextQuestion = () => {
		setShowExplanation(false);
		setIsAnswerSelected(false);

		if (testSelections.useTimer ?? false) {
			updateTimeElapsed(Date.now());
		}

		// if (!testSelections.useTutorMode) {
		// addToQnALog(selectedAnswer);
		// }

		const count = currentQuestionIndex + 1;

		const answer = `${shuffledQuestions[currentQuestionIndex].id}~|~${selectedAnswer}`;
		updateUnfinishedTestOnNextQuestion(count, answer)
			.then(() => {
				console.info('Unfinished test selections updated');
			})
			.catch((error) => {
				console.error('Error updating unfinished test selections', error);
			});

		setCurrentQuestionIndex(count);
		// window.removeEventListener('keyup', onEnterNextQuestion, false);
		// scrollToTop();
	};

	const handleEndOfTest = () => {
		if (testSelections.useTimer) {
			setLoadingMessage(
				'El límite de tiempo para completar el examen terminó. Estamos analizando tus resultados.'
			);
		} else {
			setLoadingMessage(
				'Tu examen ha terminado. Estamos analizando tus resultados.'
			);
		}
		setLoading(true);

		storeTestData()
			.then((record) => {
				if (record !== null) {
					setFinalRecord(record);
				} else {
					// todo: handle this error better
				}

				deleteUnfinishedTest()
					.then(() => {
						console.info('Unfinished test deleted');
					})
					.catch((error) => {
						console.error('Error deleting unfinished test', error);
					});

				setTimeout(() => {
					setLoading(false);
					if (record!.grade >= 70) {
						showSideCannons();
					}
				}, 5000);
			})
			.catch(async (error) => {
				setLoading(false);
				console.error('Error saving test record', error);
				await Swal.fire({
					icon: 'error',
					title: '¡Ups! Algo salió mal',
					text: 'Hubo un problema al guardar tus resultados. Por favor, intenta de nuevo más tarde. Si el problema persiste, por favor, ponte en contacto con soporte.'
				});
			});
	};

	const storeTestData = async (): Promise<TestRecord | null> => {
		if (isUnfinishedTest) {
			let numberOfCorrectAnswers = 0;
			const qLog: QuestionRecord[] = [];
			const buildQR = (q: IQuestion, selAnswer: string): void => {
				if (q.answer === selAnswer) numberOfCorrectAnswers++;
				qLog.push({
					id: q.id,
					correctAnswer: q.answer,
					selectedAnswer: selAnswer,
					selectTS: new Date()
				});
			};

			currentUser.unfinishedTest?.answersSelected.forEach((a) => {
				const split = a.split('~|~');
				const answer = split[1];
				const qID = split[0];
				const question = shuffledQuestions.find((q) => q.id === qID);
				if (question === undefined) return;
				buildQR(question, answer);
			});

			const finalGrade = calcTestGrade(
				numberOfCorrectAnswers,
				testSelections.testLength
			);

			const testRecord = buildTestRecordObject(undefined, {
				questions: qLog,
				grade: finalGrade,
				correctAnswers: numberOfCorrectAnswers,
				testDuration: timeElapsedInSeconds,
				testLabel: testSelections.testLabel,
				testLength: testSelections.testLength,
				testMode: testSelections.testMode,
				testTopics: [...testSelections.topics],
				testType: testSelections.testType,
				useTimer: testSelections.useTimer,
				useTutorMode: testSelections.useTutorMode,
				timeElapsedInSeconds: testSelections.useTimer
					? timeElapsedInSeconds
					: 0,
				timerStoppedTest: testSelections.useTimer ? isTimerStopped : false,
				date: new Date(),
				timeStarted: new Date(),
				timeEnded: new Date(),
				userID: currentUser.uid
			});

			if (testRecord === null) return null; // TODO: Handle this error better
			await saveTestRecord(testRecord);
			return testRecord;
		} else {
			const finalGrade = calcTestGrade(
				correctAnswersSum,
				testSelections.testLength
			);
			const testRecord = buildTestRecordObject(undefined, {
				questions: [...QnALog],
				grade: finalGrade,
				correctAnswers: correctAnswersSum,
				testDuration: timeElapsedInSeconds,
				testLabel: testSelections.testLabel,
				testLength: testSelections.testLength,
				testMode: testSelections.testMode,
				testTopics: [...testSelections.topics],
				testType: testSelections.testType,
				useTimer: testSelections.useTimer,
				useTutorMode: testSelections.useTutorMode,
				timeElapsedInSeconds: testSelections.useTimer
					? timeElapsedInSeconds
					: 0,
				timerStoppedTest: testSelections.useTimer ? isTimerStopped : false,
				date: new Date(),
				timeStarted: TEST_START_TIME,
				timeEnded: new Date(),
				userID: currentUser.uid
			});

			if (testRecord === null) return null; // TODO: Handle this error better
			await saveTestRecord(testRecord);
			return testRecord;
		}
	};

	if (testFinished && finalRecord !== undefined) {
		return (
			<TestModeContainer>
				<TestResults
					score={correctAnswersSum}
					testSelections={testSelections}
					timeElapsedInSeconds={timeElapsedInSeconds}
					qnALog={QnALog}
					testRecord={finalRecord}
				/>
			</TestModeContainer>
		);
	}

	return (
		<TestModeContainer>
			<TestTimerAndProgressContainer
				currentQuestion={currentQuestionIndex}
				total={testSelections.testLength}
				testDuration={Number(testSelections.testDuration)}
				useTimer={testSelections.useTimer}
				updateTimeElapsed={updateTimeElapsed}
				setStartTime={(num: number) => setTimerStartTime(num)}
				stopTest={stopTest}
				timeElapsed={timeElapsedInSeconds}
			/>
			{currentQuestionIndex + 1 <= Number(testSelections.testLength) && (
				<>
					<Question question={shuffledQuestions[currentQuestionIndex]} />
					<Answers
						isSelected={isAnswerSelected}
						isTutorMode={testSelections.useTutorMode}
						question={shuffledQuestions[currentQuestionIndex]}
						selectedAnswer={selectedAnswer}
						evaluateSelection={evaluateAnswerChoice}
					/>
					<Explanation
						showExplanation={showExplanation && testSelections.useTutorMode}
						question={shuffledQuestions[currentQuestionIndex]}
					/>

					{/* // TODO: enhandement - there was a ref use to focus on this in v1, add it back */}
					<div
						className={
							isAnswerSelected
								? 'mt-5 flex flex-col md:flex-row md:items-baseline justify-end space-y-3'
								: 'hidden'
						}>
						<button
							hidden={!testSelections.useTutorMode}
							className='btn-large bg-gray-400 md:mr-8 h-[5rem] md:px-20'
							onClick={() => setShowExplanation(!showExplanation)}>
							{!showExplanation ? 'Ver Explicación' : 'Esconder Explicación'}
						</button>

						{currentQuestionIndex + 1 === testSelections?.testLength ? (
							<button
								className='btn-large bg-brand-500 shadow-lg md:mr-1 h-[5rem] md:px-20'
								onClick={handleEndOfTest}>
								Ver Resultados
							</button>
						) : (
							<button
								className='btn-large bg-brand-500 shadow-lg md:mr-1 h-[5rem] md:px-20'
								onClick={nextQuestion}>
								Siguiente Pregunta
							</button>
						)}
					</div>
				</>
			)}

			<Modal
				actionBtnText='Ver Resultados'
				handleAction={handleEndOfTest}
				// headline='Psss, ¡ey!'
				headline='Psss... ¡Se acabó el tiempo!'
				isOpen={isTimerStopped}
				// onClose={() => setIsTimerStopped(false)}
				showCloseBtn={false}>
				<p>El tiempo límite para completar el examen ha terminado.</p>
				<p>¿Cómo crees que te fue?</p>
			</Modal>
		</TestModeContainer>
	);
};
export default TestMode;
