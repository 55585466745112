import parseHTML from 'html-react-parser';
import type { Question } from '../../../../../data/interface-question';
import QuestionIssues from './QuestionIssues';
import QuestionTags from './QuestionTags';
import { customDateFormatInSpanish } from '../../../../../utils/dates';
import { removeEmptyParagraphsbyQuill } from '../../../../../utils/helpers';
import QuestionContainerHeader from './QuestionContainerHeader';
import Badge from '../../../../../components/ui/Badge';
import './questions.css';

interface IQuestionContainer {
	question: Question;
	sortBy: 'create' | 'modified';
}

const QuestionCard = ({ question, sortBy }: IQuestionContainer) => {
	return (
		<div className='divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-md mb-4 text-sky-800'>
			<QuestionContainerHeader question={question} />
			<div className='px-4 sm:px-6 py-2'>
				<Badge
					title={question.id}
					textColor='text-orange-500'
					ringColor='ring-orange-500/30'
				/>
				{/* {sortBy === 'create' &&
					Object.prototype.hasOwnProperty.call(question, 'createdOn') && (
						<span className='text-gray-400 text-xs ml-2 mb-0'>
							| Created: &nbsp;
							{customDateFormatInSpanish(
								convertFirebaseTSToDate(question.createdOn)
							)}
						</span>
					)}
				{sortBy === 'modified' &&
					Object.prototype.hasOwnProperty.call(question, 'lastEditDate') && (
						<span className='text-gray-400 text-xs ml-2 mb-0'>
							| Modified: &nbsp;
							{customDateFormatInSpanish(
								convertFirebaseTSToDate(question.lastEditDate)
							)}
						</span>
					)} */}
				<article className='text-gray-700 text-sm'>
					{parseHTML(removeEmptyParagraphsbyQuill(question.question))}
				</article>
			</div>
			<div className='px-4 sm:px-6 py-2 w-full'>
				<QuestionIssues issues={question.issues} />
				<QuestionTags tags={question.tags} />
			</div>
		</div>
	);
};
export default QuestionCard;
