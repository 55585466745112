import type { ExamLabel } from '../../../../data/interface-question';

interface WarningTextProps {
	bgColor?: 'red' | 'yellow' | 'green' | 'blue';
	text: string;
}
const red = () => {
	return (
		<>
			<div className='bg-red-300'></div>
			<div className='bg-yellow-300'></div>
		</>
	);
};
const WarningText = ({ bgColor = 'yellow', text }: WarningTextProps) => {
	return (
		<div className={`bg-${bgColor}-300 shadow sm:rounded-lg mb-4`}>
			<div className='px-4 py-2 sm:p-4 text-sm'>
				<p className='mb-0'>
					Aún no hay una <strong>{text.toUpperCase()}</strong> agregada...
				</p>
			</div>
		</div>
	);
};

interface WarningsProps {
	showWarning: boolean;
	question: string | undefined;
	source: ExamLabel | undefined;
	categoryID: string | undefined;
	answer: string | undefined;
	option1: string | undefined;
	option2: string | undefined;
	option3: string | undefined;
	explanation: string | undefined;
	fuentes: string | undefined;
}

const Warnings = ({
	showWarning,
	question,
	source,
	categoryID,
	answer,
	option1,
	option2,
	option3,
	explanation,
	fuentes
}: WarningsProps) => {
	if (!showWarning) return null;
	return (
		<div>
			{(question === undefined ||
				question === '' ||
				question === '<p><br></p>') && <WarningText text='PREGUNTA' />}

			{answer === undefined && <WarningText text='RESPUESTA' />}

			{option1 === undefined && <WarningText text='OPTION1' />}

			{option2 === undefined && <WarningText text='OPTION2' />}

			{option3 === undefined && <WarningText text='OPTION3' />}

			{explanation === undefined && <WarningText text='EXPLICACIÓN' />}

			{fuentes === undefined && <WarningText text='FUENTE' />}

			{categoryID === undefined && <WarningText text='CATEGORÍA' />}

			{source === undefined && <WarningText text='LABEL' />}
		</div>
	);
};
export default Warnings;
