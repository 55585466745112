import { useEffect, useState } from 'react';
import useUserContext from '../../../api/UserContext';
import Headline from '../../../components/ui/Headline';
import MaterialIcons from '../../../components/ui/icons/MaterialIcons';
import ProgressBar from '../../../components/utils/ProgressBar';
import { useLoadingContext } from '../../../context/LoadingContext';
import useHideElementOnMount from '../../../hooks/useHideElementOnMount';
import MainTag from '../../../layout/Main';
import PaymentButtons from './PaymentButtons';
import FreeTrialRequest from './FreeTrialRequest';
import { redirectTo } from '../../../utils/helpers';
import { useAuthContext } from '../../../api/AuthContext';
import { checkPreviousMembership } from '../../../utils/securities';

// https://www.youtube.com/watch?v=xi3F2Zv91UE&t=1795s
const Checkout = () => {
	useHideElementOnMount('header');
	useHideElementOnMount('footer');
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { currentUser, product, getProductByID, getStripeChekoutURL } =
		useUserContext();
	const { setIsNewSignUp } = useAuthContext();
	const [isRenewing, setIsRenewing] = useState(false);
	const freeTrialExpired = false;

	useEffect(() => {
		// if (
		// currentUser === null ||
		// currentUser.examTarget === undefined ||
		// currentUser.examTargetYear === undefined
		// ) {
		// setLoadingMessage(
		// 'Por favor, vuelve a iniciar sesión para continuar. Te redireccionaremos en unos segundos...'
		// );
		// setLoading(true);
		// setTimeout(() => {
		// redirectTo('/acceder');
		// }, 5000);
		// return;
		// }

		if (currentUser !== null) {
			setIsNewSignUp(false);
			setLoading(true);
			if (product === null) {
				getProductByID(currentUser?.examTarget ?? 'enurm')
					.then(() => {
						setLoading(false);
					})
					.catch(() => {
						setLoading(false);
					});
			} else {
				const hadPaidMembership = checkPreviousMembership(currentUser, product);
				if (hadPaidMembership) {
					setIsRenewing(true);
					setLoading(false);
				}
			}
		}
		setLoading(false);
	}, [currentUser, product]);

	const handleStripePayment = () => {
		setLoadingMessage('Un momento por favor...');
		setLoading(true);
		if (product === null) {
			setLoading(false);
			throw new Error(
				'Hubo un problema para procesar tu pago. Por favor recarda la pagina e intenta de nuevo.'
			);
		} else {
			const priceID = isRenewing
				? product.stripePriceIDForRenewal
				: product.stripePriceID;
			getStripeChekoutURL(priceID)
				.then((url) => {
					setLoading(false);
					// navigateTo(url);
					redirectTo(url);
				})
				.catch((error) => {
					setLoading(false);
					console.error('Error getting Stripe URL: ', error);
					throw new Error(
						'Hubo un problema para procesar tu pago. Por favor recarga la pagina e intenta de nuevo.'
					);
				});
		}
	};

	if (product === null) return null;

	if (freeTrialExpired) {
		return (
			<MainTag>
				<div className='flex flex-col items-center mb-4 mt-10  mx-auto'>
					<Headline
						classes='txt-brand2'
						title='Tu
						acceso gratuito ha terminado...'
						type='h3'>
						<i className='material-icons text-4xl mr-2'>check_circle</i>
					</Headline>
					<div className='mt-4 fsize-medium  '>
						<p>
							Llegaste al límite de tiempo concedido o llegaste al límite de
							exámenes permitidos. Esperamos te haya gustado la experiencia de
							usar nuestra plataforma y que hayas podido ver los beneficios de
							todo lo que tenemos para ofrecer. Para continuar usando nuestros
							servicios solo tienes que completar tu pago y una vez sea
							procesado tendrás acceso a nuestra plataforma completa que
							incluye:
						</p>
					</div>
				</div>
			</MainTag>
		);
	}

	return (
		<MainTag>
			<div className='flex flex-col items-center mb-4 mt-4 mx-auto'>
				<div className='container'>
					<Headline
						classes='text-brand-600'
						title={isRenewing ? '¡Reinscribete Hoy!' : '¡Ya casi estás dentro!'}
						type='h2'>
						<MaterialIcons icon='check_circle' classes='text-4xl mr-2' />
					</Headline>
					<ProgressBar width={100} percent={90} />
					<div className='mt-8'>
						{isRenewing ? (
							<p>
								Si quieres prepararte con nosotros para el{' '}
								{product.officialExamName} {product.nextExamTargetYear}, como
								oferta de reinscripción, estamos ofreciendo casi un{' '}
								<b>40% de descuento</b> sobre el precio regular. Una vez tu pago
								sea procesado tendrás acceso a nuestra plataforma completa que
								incluye:{' '}
							</p>
						) : (
							<p>
								¡Felicidades {currentUser?.person?.firstName}! Has tomado una
								excelente decisión para tu futuro profesional. Una vez tu pago
								sea procesado tendrás acceso a nuestra plataforma completa que
								incluye:
							</p>
						)}
						<ul className='normal-bullets ml-2 px-2 md:px-8'>
							<li>
								Una base de datos con{' '}
								<strong>
									más de {product.totalQuestions?.toLocaleString()} preguntas
								</strong>{' '}
								de material actualizado y enfocado 100% en el{' '}
								{product?.officialExamName}
							</li>
							<li>
								La facilidad de hacer <strong>simulaciones a tu ritmo</strong> y
								desde la comodidad de tu hogar
							</li>
							<li>
								Flashcards para ayudarte a{' '}
								<strong>memorizar conceptos claves</strong> que te ayudarán a
								responder preguntas más rápido
							</li>
							<li>
								Estadísticas para determinar tus{' '}
								<strong>puntos fuertes y débiles</strong>, y así puedas mejorar
								tu desempeño
							</li>
						</ul>
						<p>
							Para tener acceso a nuestra plataforma, los recursos{' '}
							<strong>{product?.officialExamName}</strong> y todo lo que
							ofrecemos el precio regular es de RD$
							{product.fullPrice?.toLocaleString()}.
						</p>
						{isRenewing ? (
							<>
								<p>
									Aprovecha nuestra oferta exclusiva de RD$
									{product.renewalPrice.rd?.toLocaleString()} casi un{' '}
									<b>40% de descuento</b> solo por motivo de reinscripción..
								</p>
								{/* <p>
									Recuerda que es un solo pago que te cubre el acceso a nuestra
									plataforma y todas las herramientas que ofrecemos por 365 días
									(un año).{' '}
								</p> */}
								<p>
									Esta es una oferta temporal que puede eliminarse en cualquier
									momento.
								</p>
								<p>¡Aprovecha el descuento para inscribirte hoy!</p>
							</>
						) : (
							<>
								<p>
									Actualmente, estamos ofreciendo una promoción exclusiva de
									acceso <i>temprano</i> por <strong>un solo pago </strong> de
									RD${product.price?.toLocaleString()} que te cubre el acceso a
									nuestra plataforma y las herramientas que ofrecemos{' '}
									<strong>
										hasta la fecha en que se otorgue el{' '}
										{product?.officialExamName} en el{' '}
										{product?.nextExamTargetYear}
									</strong>
									.
								</p>
								<p>
									Este precio especial aumentará a medida que se acerque la
									fecha del examen. Aproveche esta oportunidad única para
									asegurar su preparación al mejor precio posible.
								</p>
							</>
						)}
					</div>
					<PaymentButtons
						handleStripeCheckout={handleStripePayment}
						product={product}
					/>
					<p className='mt-8 text-xs'>
						* El monto en pesos será calculado al valor de cambio vigente en la
						fecha de cobro
					</p>
					<p className='text-xs'>
						** Al completar su pago, usted acepta nuestra política de devolución
						que establece su derecho a recibir hasta un 75% de sus fondos si
						éste es solicitado antes de concluir un periodo de 72 horas de haber
						completado su pago.
					</p>
					<p className='text-xs'>
						*** En caso de que su banco origine una disputa, su cuenta será
						bloqueada y no podrá utilizar la plataforma hasta que la disputa sea
						resuelta.
					</p>
					<div className='border border-gray-300 mt-10 mb-8 w-full'></div>
					{product.id === 'enurm' && <FreeTrialRequest />}
				</div>
			</div>
		</MainTag>
	);
};
export default Checkout;
