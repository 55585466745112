import type { IQuestionIssues } from '../../../../../data/interface-question';

interface IQuestionIssuesProps {
	issues?: IQuestionIssues;
}

const QuestionIssues = ({ issues }: IQuestionIssuesProps) => {
	if (typeof issues === 'undefined') return null;
	const isHighPriorityIssue =
		Object.prototype.hasOwnProperty.call(issues, 'list') &&
		typeof issues.list !== 'undefined' &&
		Object.prototype.hasOwnProperty.call(issues, 'message') &&
		typeof issues.message === 'string';
	return (
		<div
			className={`rounded-md p-4  ${
				isHighPriorityIssue
					? 'bg-red-500 text-gray-200'
					: 'bg-orange-200 text-gray-800'
			}`}>
			<b>Issues to Fix:</b>
			{Object.prototype.hasOwnProperty.call(issues, 'list') &&
				issues.list?.map((item: string) => {
					return <li key={item}>{item}</li>;
				})}

			{Object.prototype.hasOwnProperty.call(issues, 'message') && (
				<div>
					<div className='mt-4 flex items-center'>
						<span className='material-icons mr-2'>comment</span>
						<strong>Note:</strong>
					</div>
					<p>{issues.message}</p>
				</div>
			)}
		</div>
	);
};
export default QuestionIssues;
