import type { ProductData } from '../data/interface-question-bank';
import type { User } from '../data/interface-user';

export const checkPreviousMembership = (
	currentUser: User,
	product: ProductData
): boolean => {
	const targetYear = product.nextExamTargetYear;
	const membership = currentUser[`membership_${targetYear - 1}`];
	const hadPaidMembership: boolean = membership?.isPaid ?? false;
	return hadPaidMembership;
};
