import { useEffect, useState } from 'react';
import { type QuestionBankID } from '../../../data/interface-question-bank';
import type { Question } from '../../../data/interface-question';
import CurrentBankNotice from './CurrentBankNotice';
import QuestionsManagerContainer from './QuestionsManagerContainer';
import QuestionList from './questions-summary-list/QuestionList';
import MainStatsDisplay from './questions-summary-list/stats/MainStatsDisplay';
import { useAdminContext } from '../../../api/AdminContext';
import SearchAndFilter from './questions-summary-list/search-and-filter/SearchAndFilter';
import { useLoadingContext } from '../../../context/LoadingContext';

const QuestionsManagerDashboard = () => {
	const { adminQuestions, setAdminQuestions, getQuestionsForAdmin } =
		useAdminContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const [bankQuestions, setBankQuestions] = useState<Question[]>([]);
	const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);
	const [showNoResultsMSG, setShowNoResultsMSG] = useState(false);

	const lsBankID = localStorage.getItem('questionBankID') as QuestionBankID;
	const [questionBankID, setQuestionBankID] = useState<QuestionBankID>(
		lsBankID !== null ? lsBankID : 'enurm'
	);

	// DEVELOPMENT STEPS
	// 1. Check localStorage for exam-id, if found, load the exam else load 'enurm' exam
	// 2. Load questions for the exam
	// 3. Load counters

	const handleQBankChange = (v: QuestionBankID) => {
		setQuestionBankID(v);
		setAdminQuestions(undefined);
	};

	const loadQuestions = () => {
		setLoading(true);
		setLoadingMessage('Cargando preguntas...');
		getQuestionsForAdmin(questionBankID)
			.then((questions) => {
				setBankQuestions(questions);
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error fetching questions: ', error);
				setLoading(false);
			});
	};

	useEffect(() => {
		if (adminQuestions === undefined || adminQuestions.length === 0) {
			loadQuestions();
		} else {
			setBankQuestions(adminQuestions);
		}
	}, [adminQuestions]);

	const resetQuestions = () => {
		if (adminQuestions !== undefined) {
			setBankQuestions(adminQuestions);
			setShowNoResultsMSG(false);
		}
	};

	if (adminQuestions === undefined) {
		return (
			<QuestionsManagerContainer
				title='Questions Manager'
				bgColor='bg-brand-25'>
				<div className='my-8 w-full'>
					<div className='px-2 sm:px-4 lg:px-0'>
						<CurrentBankNotice
							examID={questionBankID}
							handleQBankChange={(v: QuestionBankID) => handleQBankChange(v)}
							showAddQuestionBtn={true}
						/>
						<p>Loading questions...</p>
					</div>
				</div>
			</QuestionsManagerContainer>
		);
	}

	return (
		<QuestionsManagerContainer title='Questions Manager' bgColor='bg-brand-25'>
			<div className='my-8 w-full'>
				<div className='px-2 sm:px-4 lg:px-0'>
					<CurrentBankNotice
						examID={questionBankID}
						handleQBankChange={(v: QuestionBankID) => handleQBankChange(v)}
						showAddQuestionBtn={true}
					/>
					<MainStatsDisplay examID={questionBankID} questions={bankQuestions} />
					<hr className='w-3/4 mx-auto my-8' />
					<SearchAndFilter
						questions={bankQuestions}
						resetQuestions={resetQuestions}
						setFilteredQuestions={setBankQuestions}
						setShowNoResultsMSG={setShowNoResultsMSG}
					/>
					{showNoResultsMSG ? (
						<div className='w-100'>
							<p className='text-red-500'>
								No results found. Please try a different search term.
							</p>
						</div>
					) : (
						<QuestionList questions={bankQuestions} />
					)}
				</div>
			</div>
		</QuestionsManagerContainer>
	);
};
export default QuestionsManagerDashboard;
