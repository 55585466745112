import { useEffect, useState } from 'react';
import { useBusinessContext } from '../../../api/BusinessContext';
import type { Especialidad } from '../../../models/interfaces/especialidad.interfaces';

interface MinMaxByYearProps {
	especialidadID: string;
}

const MinMaxByYear = ({ especialidadID }: MinMaxByYearProps) => {
	const { getHistoricGrades } = useBusinessContext();
	const [especialidad, setEspecialidad] = useState<Especialidad>();

	useEffect(() => {
		getHistoricGrades(especialidadID)
			.then((data) => {
				setEspecialidad(data);
			})
			.catch((e) => console.error('Error loading historic grades', e));
	}, [especialidadID]);

	const years = [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017];

	if (especialidad === undefined) {
		return (
			<div>
				{/* <p>
					Hubo un problema cargando la información. Por favor comunícate con
					nosotros.
				</p>
				<p className='text-brand-800'>
					<small>ERROR CODE: ESPECIALIDAD</small>
				</p> */}
				Cargando...
			</div>
		);
	}

	return (
		<div className='mt-10 mb-4'>
			<h6 className=''>
				Nota mínima y máxima de <b>{especialidad.name}</b>
			</h6>
			<div style={{ maxWidth: '50%' }}>
				<table className='min-w-full divide-y divide-gray-300'>
					<thead>
						<tr>
							<th
								scope='col'
								className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold uppercase text-gray-500 sm:pl-0'>
								AÑO
							</th>
							<th
								scope='col'
								className='px-3 py-3.5 text-left text-sm font-semibold uppercase text-gray-500'>
								MIN
							</th>
							<th
								scope='col'
								className='px-3 py-3.5 text-left text-sm font-semibold uppercase text-gray-500'>
								MAX
							</th>
						</tr>
					</thead>
					<tbody className='divide-y divide-gray-200 bg-white'>
						{especialidad !== undefined &&
							years.map((year) => {
								if (especialidad[`${year}`] === undefined) {
									return (
										<tr key={year}>
											<td>{year}</td>
											<td>---</td>
											<td>---</td>
										</tr>
									);
								}
								return (
									<tr key={year} className='even:bg-brand-50 px-2'>
										<td>{year}</td>
										<td>{especialidad[`${year}`].min}</td>
										<td>{especialidad[`${year}`].max}</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>
		</div>
	);
};
export default MinMaxByYear;
