import useQuestionBankContext from '../../../../../api/QuestionBankContext';
import Badge from '../../../../../components/ui/Badge';
import MaterialIcons from '../../../../../components/ui/icons/MaterialIcons';
import type { Question } from '../../../../../data/interface-question';

interface IQuestionContainer {
	question: Question;
}

const QuestionContainerHeader = ({ question }: IQuestionContainer) => {
	const { categories } = useQuestionBankContext();
	const getCategoryName = (id: string) => {
		const category = categories?.find((c) => c.id === id);
		if (category !== undefined) {
			return category.name;
		} else {
			return id;
		}
	};
	return (
		<div className='flex justify-between items-center px-4 sm:px-6 py-2 text-sm'>
			<div className='flex items-center'>
				{Object.prototype.hasOwnProperty.call(question, 'issues') && (
					<MaterialIcons
						icon='report'
						classes='text-red-500 mr-1'
						title='Has a reported issue'
					/>
				)}
				{Object.prototype.hasOwnProperty.call(question, 'imageFileName') &&
					question.imageFileName !== undefined && (
						<MaterialIcons
							icon='image'
							classes='text-purple-600 mr-1'
							title='Has an image'
						/>
					)}
				{Object.prototype.hasOwnProperty.call(question, 'audioFileName') &&
					question.audioFileName !== undefined && (
						<MaterialIcons
							icon='audio_file'
							classes='text-blue-600 mr-1'
							title='Has an audio explanation'
						/>
					)}

				{Object.prototype.hasOwnProperty.call(question, 'categoryID') ? (
					<span>
						{/* // TODO: Get category name */}
						<strong>Catogoría:</strong> {getCategoryName(question.categoryID)}
					</span>
				) : (
					<span className='red-text'>
						<MaterialIcons icon='warning' classes='text-red-500 mr-2' />
						No Category Selected
					</span>
				)}
			</div>
			<div>
				{Object.prototype.hasOwnProperty.call(question, 'source') &&
				typeof question.source === 'string' ? (
					<Badge
						backgroundColor={
							question.source === 'prepmed' ? 'bg-brand-500' : 'bg-alt2-500'
						}
						ringColor={
							question.source === 'prepmed'
								? 'ring-brand-500/10'
								: 'ring-alt2-500/10'
						}
						textColor='text-white'
						title={question.source.toUpperCase()}
					/>
				) : (
					<Badge
						title='No source selected'
						backgroundColor='bg-red-400'
						ringColor='ring-red-800/10'
						textColor='text-white'
					/>
				)}
			</div>
		</div>
	);
};
export default QuestionContainerHeader;
